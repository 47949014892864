.contact-infos {
  font-weight: $font-weight-medium;

  &__item:not(:last-child) {
    padding-bottom: 30px;

    @include media-breakpoint-up(md) {
      padding-bottom: 40px;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 50px;
    }

    @include media-breakpoint-up(xl) {
      padding-bottom: 60px;
    }
  }

  &__img {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    .picture--16-9 {
      background-color: $c_iron;
    }

    &__content {
      height: auto;
      padding: 15px;
      background-color: $c_concrete;

      @include media-breakpoint-up(md) {
        padding: 25px 30px;
      }

      @include media-breakpoint-up(lg) {
        padding: 30px 40px;
      }

      @include media-breakpoint-up(xl) {
        padding: 35px 50px;
      }

      // &--bgdark {
      //   background-color: $c_concrete;
      // }

      // &--bglight {
      //   background-color: $c_white;
      // }

      .hdl {
        margin-bottom: 0;
      }
    }
  }

  &__address {
    display: flex;

    @include media-breakpoint-down(xs) {
      border-bottom: 1px solid rgba($c_black, 0.125);
    }

    @include media-breakpoint-down(sm) {
      justify-content: space-between;
    }

    .intro {
      font-weight: $font-weight-medium;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }

    .icon {
      align-self: baseline;
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      margin: 10px;
      margin-top: 0;
      color: var(--primary);
    }
  }

  &__opentime {
    @include media-breakpoint-down(xs) {
      border-bottom: 1px solid rgba($c_black, 0.125);
    }

    .opentime__headline {
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }

  &__info {
    @include media-breakpoint-down(sm) {
      padding-top: 25px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .row {
      @include media-breakpoint-up(sm) {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  &__row--open {
    @include media-breakpoint-up(sm) {
      border-bottom: 1px solid rgba($c_black, 0.125);
    }
  }

  &__contacts {
    @include media-breakpoint-down(xs) {
      border-bottom: 1px solid rgba($c_black, 0.125);
    }
  }

  &__contact-links {
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    .iconlist {
      margin-bottom: 15px;
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }

      &__ico {
        align-self: center;

        .icon {
          color: var(--primary);
        }
      }

      &__content {
        align-self: center;
        margin-bottom: 0;
        font-weight: $font-weight-medium;
        color: $c_slate;

        &--regular {
          font-weight: $font-weight-normal;
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    .icon {
      width: 20px;
      height: 20px;

      @include media-breakpoint-up(sm) {
        width: 28px;
        height: 28px;
      }
    }
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;

    @include media-breakpoint-up(sm) {
      display: none;
    }

    &:hover {
      text-decoration: none;
      color: $c_slate;
    }

    &[aria-expanded='true'] .icon {
      transition: transform 0.3s ease-in-out;
      transform: rotate(180deg);
    }

    &[aria-expanded='false'] .icon {
      transition: transform 0.3s ease-in-out;
      transform: rotate(0deg);
    }

    .hdl {
      margin-bottom: 0;
    }

    .icon {
      flex-shrink: 0;
      align-self: center;
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }

  &__button {
    display: flex;
    align-self: center;

    @include media-breakpoint-down(xs) {
      justify-content: center;
      margin-top: 20px;
    }

    .btn {
      //to satisfy the design
      padding-right: 35px;
      padding-left: 35px;
    }
  }

  .collapse.dont-collapse-sm {
    @include media-breakpoint-up(sm) {
      display: block;
      height: auto !important;
      visibility: visible;
    }
  }
}
