$lg-offset: 44px;
$lg-overlap: 68px;
$xl-offset: 135px;
$xl-overlap: 85px;

.image-text {
  background-color: $c_white;

  &__row {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: -10px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .storer {
      width: fit-content;
      padding: 0 5px;
      background-color: var(--primary);
      border: 2px solid $c_white;

      @include media-breakpoint-down(xs) {
        margin: 0 auto;
      }

      .storer-text {
        color: $c_white;
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;

        @include media-breakpoint-down(xs) {
          font-size: 17px;
          line-height: 29px;
        }
      }
    }
  }

  &__mp4 {
    @include media-breakpoint-up(md) {
      margin: auto;
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      // 50% + overlap
      max-width: calc(50% + #{$lg-overlap});
    }

    @include media-breakpoint-up(xl) {
      // 50% + overlap
      max-width: calc(50% + #{$xl-overlap});
    }

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .play-pause-wrapper {
      display: flex;
      justify-content: flex-start;
      z-index: 1001 !important;
      position: absolute;
      bottom: 0;
      transform: translate(30px, -30px);

      @include media-breakpoint-down(md) {
        display: none;
      }

      .play-icon, .pause-icon {
        z-index: 1001;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        border: 2px solid rgba(0,0,0, 0.2);
        background-color: $c_white;
        height: 50px;
        width: 50px;
        margin-right: -1.25%; // cancel out the bootstrap padding on the parent element

        &:hover {
          border: 4px solid rgba(0,0,0, 0.2);
          cursor: pointer;
        }

        &:focus-visible {
          border: 4px solid rgba(0,0,0, 0.2);
          outline: none;
        }

        .icon {
          height: 12px;
          width: 9.17px;
        }
      }
    }
  }

  &__image {
    @include aspect-ratio(16, 9);
    width: 100vw;
    margin-left: -10px;
    margin-right: -10px;
    background: var(--iron);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include media-breakpoint-up(md) {
      margin: auto;
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      // 50% + overlap
      max-width: calc(50% + #{$lg-overlap});
    }

    @include media-breakpoint-up(xl) {
      // 50% + overlap
      max-width: calc(50% + #{$xl-overlap});
    }
  }

  &__info {
    flex: 1 1 auto; // override col-lg-6;
    background-color: $c_concrete;
    z-index: 10;
    padding: 20px;
    transform: translateY(-50px);
    text-align: center;
    color: $c_slate;

    @include media-breakpoint-only(xs) {
      margin: 0 14px;
    }

    @include media-breakpoint-between(sm, md) {
      margin: 0 33px;
    }

    @include media-breakpoint-up(sm) {
      text-align: left;
      padding: 30px 50px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 50px;
      margin-bottom: 50px;
      padding: 60px 80px;
      // 50% + overlap - offset
      max-width: calc(50% + #{$lg-overlap} - #{$lg-offset});
      transform: translateX(-$lg-offset);
    }

    @include media-breakpoint-up(xl) {
      padding: 80px 100px;
      transform: translateX(-$xl-offset);
      // 50% + overlap - offset
      max-width: calc(50% + #{$xl-overlap} - #{$xl-offset});
    }

    p {
      margin-bottom: 20px;
      @include media-breakpoint-up(md) {
        margin-bottom: 30px;
      }
    }

    .list-unstyled {
      text-align: left;
      font-weight: $font-weight-demi;
    }
  }

  //Styles for even rows, picture to the left
  .even {
    flex-direction: row;
    .image-text__image {
      @include media-breakpoint-up(lg) {
        left: auto;
        right: 0;
      }
    }

    .image-text__mp4 {
      @include media-breakpoint-up(lg) {
        left: auto;
        right: 0;
      }

      .play-pause-wrapper {
        justify-content: flex-end;
        transform: translate(-30px, -30px);
      }
    }

    .image-text__info {
      @include media-breakpoint-up(lg) {
        transform: translateX($lg-offset);
        // 50% + overlap - offset
        max-width: calc(50% + #{$lg-overlap} - #{$lg-offset});
      }

      @include media-breakpoint-up(xl) {
        transform: translateX($xl-offset);
        // 50% + overlap - offset
        max-width: calc(50% + #{$xl-overlap} - #{$xl-offset});
      }
    }
  }
}
