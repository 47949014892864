.direct-link {
  &__box {
    background-color: $c_iron;
    padding: 10px 20px;
    @include media-breakpoint-up(md) {
      padding: 15px 20px;
    }
    @include media-breakpoint-up(lg) {
      padding: 20px;
    }

    .link {
      align-items: baseline;
      @include media-breakpoint-up(md) {
        font-size: 18px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 19px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }

    .icon {
      position: relative;
      top: 2px;
      width: 15px;
      height: 15px;
      margin-right: 3px;
      @include media-breakpoint-up(md) {
        top: 1px;
      }
      @include media-breakpoint-up(lg) {
        top: 0;
      }
    }
  }
}
