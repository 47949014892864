.reference-object-filter {
  &__filters {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 20px;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 18px;
      row-gap: 20px;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 24px;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
    }
  }

  .form-group {
    margin: 0;
    position: relative;
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0;
    font-weight: $font-weight-normal;

    &:after {
      margin-left: auto;
    }

    &:focus {
      border: 2px solid var(--primary);
    }

    &.open {
      border: 2px solid var(--primary);

      &::after {
        transform: rotate(180deg);
      }
    }

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }

  .dd-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 3px;
    background-color: $c_concrete;
    border: 2px solid var(--primary);

    &.hidden {
      display: none;
    }
  }

  .dd-inner {
    position: static;
    padding: 15px;
  }

  .dd-inputs {
    display: flex;

    span {
      align-self: center;
      width: 40px;
      text-align: center;
    }

    .form-group {
      flex-basis: 50%;
    }

    input {
      background-color: $c_white;
      border-color: $c_white;
      padding: 10px;
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        border-color: var(--focus);
      }
    }
  }

  .slider-wrapper {
    margin-bottom: 20px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .slider-labels {
    display: flex;
    justify-content: space-between;
    margin-left: -8px;
    margin-right: -8px;
    color: var(--primary);
    font-size: 13px;
  }

  .btn-clean {
    &:hover {
      color: var(--hover_alt);
    }

    &:focus {
      color: var(--focus_alt);
    }

    .icon {
      color: inherit;
    }
  }
}
