.simple-teaser {
  &__content {
    margin-top: auto;
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }
  }
}
