$hotspotBorderMobile: 5px;
$hotspotBorderTablet: 6px;
$hotspotBorderDesktop: 10px;

$hotspotWidthMobile: 30px;
$hotspotWidthTablet: 40px;
$hotspotWidthDesktop: 60px;

$hotspotRadiusMobile: $hotspotWidthMobile / 2;
$hotspotRadiusTablet: $hotspotWidthTablet / 2;
$hotspotRadiusDesktop: $hotspotWidthDesktop / 2;
$hotspotLineHeight: 100px;

$hotspotBorderMobile: 5px;
$hotspotBorderTablet: 6px;
$hotspotBorderDesktop: 10px;

$hotspotWidthMobile: 30px;
$hotspotWidthTablet: 40px;
$hotspotWidthDesktop: 60px;

$hotspotRadiusMobile: $hotspotWidthMobile / 2;
$hotspotRadiusTablet: $hotspotWidthTablet / 2;
$hotspotRadiusDesktop: $hotspotWidthDesktop / 2;
$hotspotLineHeight: 100px;

$hotspotTipWidth: 170px;

@keyframes pulse-new {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}

@for $i from 1 through 10 {
  .hotspot:nth-child(#{$i}n) .hotspot__spot:not(.hotspot--opened):after {
    animation-delay: #{$i * 1.2}s;
  }
}

// This is just a single spot
.hotspot {
  &__spot {
    position: absolute;
    display: inline-flex;
    width: $hotspotWidthMobile;
    top: -200px;
    height: $hotspotWidthMobile;
    margin-top: -#{$hotspotRadiusMobile};
    margin-left: -#{$hotspotRadiusMobile};
    border-radius: 50%;
    background-clip: padding-box;
    padding: $hotspotBorderMobile;
    background-color: $c_white;
    border: $hotspotBorderMobile solid rgba($c_black, 0.12);
    transition: transform 0.3s ease-in-out, border 0.3s ease-in-out, background-color 0.3s ease-in-out;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      width: $hotspotWidthTablet;
      height: $hotspotWidthTablet;
      border-width: $hotspotBorderTablet;
      margin-top: -#{$hotspotRadiusTablet};
      margin-left: -#{$hotspotRadiusTablet};
      padding: $hotspotBorderTablet;
    }

    @include media-breakpoint-up(lg) {
      width: $hotspotWidthDesktop;
      height: $hotspotWidthDesktop;
      border-width: $hotspotBorderDesktop;
      margin-top: -#{$hotspotRadiusDesktop};
      margin-left: -#{$hotspotRadiusDesktop};
      padding: $hotspotBorderDesktop;
    }

    &:not(.hotspot--opened):after {
      content: '';
      position: absolute;
      display: inline-flex;
      border-radius: 50%;
      animation: pulse-new 1.5s infinite;
      padding: $hotspotBorderMobile;
      margin-top: -5px;
      margin-left: -5px;
      width: 100%;
      height: 100%;

      @include media-breakpoint-up(md) {
        margin-top: -6px;
        margin-left: -6px;
        padding: $hotspotBorderTablet;
      }

      @include media-breakpoint-up(lg) {
        margin-top: -10px;
        margin-left: -10px;
        padding: $hotspotBorderDesktop;
      }
    }

    &:not(.hotspot--opened):hover,
    &.hotspot--clicked {
      background-color: var(--primary);
      transform: scale(1.1);
      border: solid 3px $c_white;

      .icon-plus {
        color: var(--primary_contrast);
        transform: scale(1.6, 1.6);
      }

      &:after {
        animation: 0;
        transition: none;
      }
    }

    &.hotspot--wasClicked {
      background-color: $c_silversand;

      .icon-plus {
        color: var(--primary);
      }
    }

    .icon-plus {
      width: 10px;
      height: 10px;
      margin: auto;
      color: var(--primary);

      @include media-breakpoint-up(lg) {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__line {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
    }
  }

  &__tip {
    display: none;
    overflow: hidden;
    height: 40px;
    line-height: 1.3;

    @include media-breakpoint-up(lg) {
      position: absolute;
      z-index: 20;
      display: flex;
      // min-width: 150px;
      width: 215px;
      font-weight: $font-weight-demi;
    }

    > div {
      width: 100%;
    }
  }

  &--opened:not(.hotspot--hidden) {
    background-color: var(--primary);
    border: solid 3px $c_white;
    position: absolute;

    .icon-plus {
      color: var(--primary_contrast);
      transform: scale(1.6, 1.6);
    }
  }

  &--opened.hotspot--hidden {
    background-color: var(--primary);
    opacity: 1;

    .icon {
      color: var(--primary_contrast);
      transform: rotate(45deg);
    }
  }
}

.modal-open {
  .hotspot {
    &__spot {
      &:not(.hotspot--opened) {
        &:after {
          animation: 0;
        }
      }
    }
  }
}

// This is the real component base class!
.hotspots {
  position: relative;
  opacity: 0;
  transition: opacity 250ms ease-out;

  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 15px;
  }

  &__wrapper {
    position: relative;
    margin-top: 0;
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      margin-bottom: 20px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-bottom: 30px;
      padding-bottom: 0;
    }
  }

  &__content {
    display: none;
  }

  &--a {
    @include media-breakpoint-up(lg) {
      padding-top: 200px !important;
      padding-bottom: 200px !important;

      &.__noTxt {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
      }
    }

    .hotspot__line {
      border-left: 2px dashed $c_graychateau;
    }

    .hotspot--top {
      .hotspot__line {
        top: -150px;
      }

      .hotspot__tip {
        top: -200px;

        > div {
          margin-top: auto;
        }
      }
    }

    .hotspot--bottom {
      .hotspot__line {
        bottom: -150px;
      }

      .hotspot__tip {
        bottom: -200px;
      }
    }

    .hotspot--left {
      .hotspot__tip {
        left: 0;
        margin-left: -10px;
      }
    }

    .hotspot--middle {
      .hotspot__tip {
        text-align: center;
        transform: translateX(-50%);
      }
    }

    .hotspot--right {
      .hotspot__tip {
        text-align: right;
        transform: translateX(-100%);
        margin-left: 10px;
      }
    }
  }

  &--b {
    @include media-breakpoint-up(lg) {
      padding-left: 200px;
      padding-right: 200px;
    }

    .hotspot__tip {
      > div {
        width: 100%;
      }
    }

    .hotspot__line {
      border-top: 2px dashed $c_graychateau;
    }

    .hotspot--left {
      .hotspot__line {
        left: -30px;
      }

      .hotspot__tip {
        width: #{$hotspotTipWidth};
        left: -200px;
        margin-left: -10px;
        text-align: right;
      }
    }

    .hotspot--right {
      .hotspot__line {
        right: -20px;
      }

      .hotspot__tip {
        width: #{$hotspotTipWidth};
        right: -200px;
      }
    }
  }
}
