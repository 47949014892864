.stage-static {
  color: $c_white;

  .content {
    aspect-ratio: 16 / 9;
    position: relative;

    @include media-breakpoint-up(xl) {
      aspect-ratio: 21 / 9;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__mp4 {
    video {
      width: 100%;
      height: 100%;
      display: flex;
      aspect-ratio: 21/9;
      object-position: center top;
      object-fit: cover;
    }

    .play-pause-wrapper {
      display: flex;
      justify-content: flex-end;
      z-index: 1001 !important;
      position: absolute;
      right: 50%;
      bottom: 0;
      transform: translate(50%, -30px);

      @include media-breakpoint-down(md) {
        display: none;
      }

      .play-icon, .pause-icon {
        z-index: 1001;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        border: 2px solid rgba(0,0,0, 0.2);
        background-color: $c_white;
        height: 50px;
        width: 50px;
        margin-right: -1.25%; // cancel out the bootstrap padding on the parent element

        &:hover {
          border: 4px solid rgba(0,0,0, 0.2);
          cursor: pointer;
        }

        &:focus-visible {
          border: 4px solid rgba(0,0,0, 0.2);
          outline: none;
        }

        .icon {
          height: 12px;
          width: 9.17px;
        }
      }
    }
  }

  &__gradient {
    position: absolute;
    width: 100%;
    height: 114px;
    opacity: 0.5;

    @include media-breakpoint-up(md) {
      height: 133px;
    }

    @include media-breakpoint-up(lg) {
      height: 200px;
    }

    @include media-breakpoint-up(xl) {
      height: 233px;
    }
  }

  &__gradient--top {
    top: 0;
    background: linear-gradient(0deg, rgba($c_black, 0) 0%, rgba($c_black, 1) 100%);
  }

  &__wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .container {
      position: relative;
      height: 100%;

      .storer {
        width: fit-content;
        padding: 0 5px;
        background-color: var(--primary);
        border: 2px solid $c_white;
        margin-top: 15px;

        .storer-text {
          color: $c_white;
          font-weight: 700;
          font-size: 18px;
          line-height: 30px;

          @include media-breakpoint-down(xs) {
            font-size: 17px;
            line-height: 29px;
          }
        }
      }
    }
  }

  .hdl {
    padding-top: 25px;

    @include media-breakpoint-up(md) {
      padding-top: 50px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 75px;
    }

    // @include media-breakpoint-up(xl) {
    //   padding-top: 133px;
    // }
  }

  &__underline {
    height: 3px;
    width: 62px;
    background-color: $c_white;

    @include media-breakpoint-up(md) {
      height: 4px;
      width: 85px;
    }
  }

  &__video-container {
    position: relative;
    overflow: hidden;

    .storer {
      width: fit-content;
      padding: 0 5px;
      background-color: var(--primary);
      border: 2px solid $c_white;
      margin-top: 15px;

      .storer-text {
        color: $c_white;
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;

        @include media-breakpoint-down(xs) {
          font-size: 17px;
          line-height: 29px;
        }
      }
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;

      @include media-breakpoint-down(sm) {
        min-height: 100%;
      }
    }

    .stage-static__image {
      @include aspect-ratio(16, 9);

      @include media-breakpoint-up(xl) {
        @include aspect-ratio(21, 9);
      }
    }
  }
}
