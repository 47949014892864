.gallery {
  .teaser-gallery__wrapper {
    position: relative;
  }

  .text__container {
    @include media-breakpoint-up(lg) {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 74px;
      display: flex;
      justify-content: flex-start;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .content-body {
    z-index: 1000;
    background-color: $c_white;

    @include media-breakpoint-up(lg) {
      color: $c_white;
      background-color: transparent;
      padding: 20px 0 !important;
    }

    p {
      @include media-breakpoint-up(lg) {
        font-size: 20px;
        font-weight: $font-weight-demi;
      }
    }
  }

  .gallery__gradient {
    position: absolute;
    width: 100%;
    opacity: 0.5;
    height: 40%;
    // z-index: 10;
    display: none;
    pointer-events: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .gallery__gradient--bottom {
    background: linear-gradient(0deg, rgba($c_black, 1) 0%, rgba($c_black, 0) 100%);
    bottom: 0;
    height: 50%;
  }

  .teaser-gallery__thumbs-container {
    z-index: 10;

    @include media-breakpoint-down(md) {
      pointer-events: none;
      //display: flex;
      @include aspect-ratio(16, 9);
      position: absolute;
      top: 0;
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      position: relative;
      top: unset;
      min-height: 168px;
      transform: translateY(-50%);
      margin-bottom: -84px; /* half of the min-height */
    }
  }

  .teaser-gallery__thumbs {
    display: none;

    @include media-breakpoint-up(lg) {
      position: absolute;
      display: block;
      //margin-bottom: 50%;
      cursor: pointer;
      overflow: hidden;

      .swiper-slide {
        width: 300px;
        height: 100%;

        &-thumb-active {
          .hidden {
            visibility: visible;
            height: 8px;
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: var(--primary);
            z-index: 1000;
          }
        }
      }
    }
  }

  .swiper-counter {
    bottom: 0;
    right: 0;

    @include media-breakpoint-up(lg) {
      top: -70px;
      right: 0;
    }

    @include media-breakpoint-up(xl) {
      right: 0;
    }
  }

  .hidden {
    height: 0;
    visibility: hidden;
  }

  .swiper-pagination {
    bottom: 0;
    @include media-breakpoint-up(lg) {
      display: none;
    }

    &-bullet {
      @include media-breakpoint-up(lg) {
        border-color: $c_white;
      }

      &-active {
        @include media-breakpoint-up(lg) {
          background-color: $c_white;
        }
      }
    }
  }
}
