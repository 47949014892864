.bread-bar {
  @include media-breakpoint-up(lg) {
    &--below {
      transform: translateY(-50%);

      .bread-bar__bar {
        &:before,
        &:after {
          content: "";
          margin: 0 30px;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .container {
      padding-left: 2.5%;
      padding-right: 2.5%;
    }
  }

  &__bar {
    display: flex;
    flex-flow: column;
    background-color: $c_white;

    @include media-breakpoint-up(sm) {
      flex-flow: row;
    }

    > nav {
      flex: 1;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    border-bottom: 4px;
    border-bottom-color: var(--primary);
    border-bottom-style: solid;

    > img {
      min-height: 20px;
    }

    @include media-breakpoint-down(xs) {
      height: 60px;
      border: 0;
    }
  }

  &__logo--has-product-text {
    flex-direction: column;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: center;

    .product-text--text {
      span {
        @include media-breakpoint-down(sm) {
          font-size: 16px;
        }
      }
    }
  }

  .breadcrumb {
    border-radius: 0;
    line-height: 26px;
    background: transparent;
    border-bottom: 4px;
    border-bottom-color: var(--primary);
    border-bottom-style: solid;

    @include media-breakpoint-up(sm) {
      padding-right: 20px;
    }
  }

  .breadcrumb-item {
    display: flex;
    padding-right: 15px;

    // Show only last 2 Items in mobile navigation
    @include media-breakpoint-down(sm) {
      display: none;

      &:nth-last-child(2) {
        padding-left: 0;
      }

      &:nth-last-child(1),
      &:nth-last-child(2) {
        display: block;

        &:not(:last-child)::before {
          display: none;
        }
      }
    }

    &.active {
      color: var(--primary_alt);
    }

    + .breadcrumb-item {
      //override bootstrap styles
      padding-left: 0;
    }

    + .breadcrumb-item::before {
      width: 25px;
    }
  }
}
