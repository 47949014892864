@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 0 !default;
$hamburger-padding-y: 0 !default;
// desktop
$hamburger-layer-width: 28px !default;
$hamburger-layer-height: 4px !default;
$hamburger-layer-spacing: 6px !default;
// mobile
$hamburger-layer-width-down-md: 21px !default;
$hamburger-layer-height-down-md: 2px !default;
$hamburger-layer-spacing-down-md: 5px !default;
//
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: 0 !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (slider) !default;

// Base Hamburger (We need this)
// ==================================================
// @import "hamburgers/_sass/hamburgers/base";
// Hamburger
// ==================================================

.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    } @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      } @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width-down-md;
  height: calc($hamburger-layer-height-down-md * 3 + $hamburger-layer-spacing-down-md * 2);
  display: inline-block;
  position: relative;

  @include media-breakpoint-up(lg) {
    width: $hamburger-layer-width;
    height: calc($hamburger-layer-height * 3 + $hamburger-layer-spacing * 2);
  }
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: calc($hamburger-layer-height-down-md / -2);

  @include media-breakpoint-up(lg) {
    margin-top: calc($hamburger-layer-height / -2);
  }

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width-down-md;
    height: $hamburger-layer-height-down-md;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    @include media-breakpoint-up(lg) {
      width: $hamburger-layer-width;
      height: $hamburger-layer-height;
    }
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: calc(($hamburger-layer-spacing-down-md + $hamburger-layer-height-down-md) * -1);

    @include media-breakpoint-up(lg) {
      top: calc(($hamburger-layer-spacing + $hamburger-layer-height) * -1);
    }
  }

  &::after {
    bottom: calc(($hamburger-layer-spacing-down-md + $hamburger-layer-height-down-md) * -1);

    @include media-breakpoint-up(lg) {
      bottom: calc(($hamburger-layer-spacing + $hamburger-layer-height) * -1);
    }
  }
}

// Hamburger types
// ==================================================
// @import 'hamburgers/_sass/hamburgers/types/slider';

// ==================================================
// Cooking up additional types:
//
// The Sass for each hamburger type should be nested
// inside an @if directive to check whether or not
// it exists in $hamburger-types so only the CSS for
// included types are generated.
//
// e.g. hamburgers/types/_new-type.scss
//
// @if index($hamburger-types, new-type) {
//   .hamburger--new-type {
//     ...
//   }
// }
@if index($hamburger-types, slider) {
  /*
     * Slider
     */
  .hamburger--slider {
    .hamburger-inner {
      top: calc($hamburger-layer-height-down-md / 2);

      @include media-breakpoint-up(lg) {
        top: calc($hamburger-layer-height / 2);
      }

      &::before {
        top: calc($hamburger-layer-height-down-md + $hamburger-layer-spacing-down-md);
        transition-property: transform, opacity;
        transition-timing-function: ease;
        transition-duration: 0.15s;

        @include media-breakpoint-up(lg) {
          top: calc($hamburger-layer-height + $hamburger-layer-spacing);
        }
      }

      &::after {
        top: calc(($hamburger-layer-height-down-md * 2) + ($hamburger-layer-spacing-down-md * 2));

        @include media-breakpoint-up(lg) {
          top: calc(($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2));
        }
      }
    }

    &.is-active {
      .hamburger-inner {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;
        $y-offset-down-md: $hamburger-layer-spacing-down-md + $hamburger-layer-height-down-md;

        transform: translate3d(0, $y-offset-down-md, 0) rotate(45deg);

        @include media-breakpoint-up(lg) {
          transform: translate3d(0, $y-offset, 0) rotate(45deg);
        }

        &::before {
          transform: rotate(-45deg)
            translate3d(calc($hamburger-layer-width-down-md / -7), calc($hamburger-layer-spacing-down-md * -1), 0);
          opacity: 0;

          @include media-breakpoint-up(lg) {
            transform: rotate(-45deg)
              translate3d(calc($hamburger-layer-width / -7), calc($hamburger-layer-spacing * -1), 0);
          }
        }

        &::after {
          transform: translate3d(0, $y-offset-down-md * -2, 0) rotate(-90deg);

          @include media-breakpoint-up(lg) {
            transform: translate3d(0, $y-offset * -2, 0) rotate(-90deg);
          }
        }
      }
    }
  }
}
