.modal--slider {
  @media (max-width: 767px), (max-width: 1024px) and (orientation: landscape) {
    top: 0;
    bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 10vh 0;
  }

  .hotspot__spot {
    position: static;
    margin-top: -10px;
    margin-right: -10px;
  }

  .modal-title {
    color: $c_slate;
    font-size: 20px;
    font-weight: 600;
    margin-right: 20px;
  }

  .modal-dialog {
    box-shadow: 0 0 15px 0 rgba($c_black, 0.2);

    @include media-breakpoint-up(lg) {
      display: flex;
      max-height: 100%;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .modal-content {
    background-color: $c_white;
  }

  .modal-header {
    padding: 30px 40px;
  }

  .modal-body {
    padding: 0 40px;
    overflow-y: auto;
  }

  .modal-body-inner {
    margin-bottom: 30px;

    &__icon {
      margin-right: 10px;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    &__description {
      display: flex;
    }
  }

  .modal-bottom {
    padding: 20px 40px;

    & button {
      width: 100%;
    }
  }

  .modal-slider {
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      right: 0;
      top: -50px;
      height: 50px;
      content: '';
      pointer-events: none;
      background-image: linear-gradient(to bottom, rgba($c_white, 0) 0%, rgba($c_white, 0.4) 60%, $c_white 100%);
    }
  }

  .pagination-wrapper {
    background-color: transparent;
  }

  // remove bootsrap: padding-right: 17px
  &-open,
  &.show {
    padding-right: 0 !important;
  }
}
