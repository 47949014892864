.tag,
.tag:hover {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12.5px;
  padding: 0 10px;
  margin: 0 15px 15px 0;
  min-height: 25px;
  width: auto;
  min-width: 50px;
  overflow: hidden;
  cursor: pointer;
  background-color: $c_iron;
  line-height: 25px;
  color: inherit;
  text-decoration: none;
  font-size: 13px;
  font-weight: $font-weight-demi;
}

.tag-secondary,
.tag-secondary:hover {
  background: transparent;
  border: 1px solid var(--primary);
  cursor: default;
}

.tag__close {
  display: inline-block;
  margin-left: 7px;
  cursor: pointer;
  .icon {
    width: 15px;
    height: 15px;
    position: relative;
    top: -1px;
  }
}

.tag__input {
  display: none;

  &:checked ~ .tag {
    background-color: var(--primary);
    color: $c_white;
  }

  &[disabled] ~ .tag {
    opacity: 0.65;
    pointer-events: none;
  }
}
