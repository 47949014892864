.image-chip-tile {
  background-color: $c_white;
  height: auto;
  width: 100%;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;

  &__picture {
    position: relative;
    margin-bottom: 10px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 30px;
    }

    .gradient {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 27%,
        rgba(65, 83, 93, 0) 73%,
        rgba(65, 83, 93, 1) 100%
      );
    }

    .hdl {
      position: absolute;
      top: 20px;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      color: $c_white;

      @include media-breakpoint-up(sm) {
        top: 30px;
      }
    }
  }

  &__tags {
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 10px;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .btn {
    margin-left: 10px;
    margin-right: 10px;
    align-self: start;
    margin-top: auto;
    @include media-breakpoint-up(sm) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
