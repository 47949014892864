@import './chisel-filter-items.scss';

.chisel-filter {
  margin-top: 60px;
  margin-bottom: 32px;
  z-index: $zindex-fixed;
  position: relative;
  border-top: 1px solid $c_iron;
  padding-top: 50px;

  @include media-breakpoint-down(lg) {
    &:not(~.pam-filters__header .pam-filters__header-modals )  {
      z-index: $zindex-modal;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-left: -10px;
    margin-right: -10px;
  }

  &__bar {
    display: none;
    background-color: $c_white;
    border-top: 1px solid $c_iron;
    border-bottom: 1px solid $c_iron;
    box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.75);
    z-index: $zindex-fixed;

    @include media-breakpoint-down(sm) {
      padding-left: -10px;
      padding-right: -10px;
      position: sticky;
      top: -1px;
    }

    .btn {
      padding: 16px 0;
      width: 100%;
      text-align: start;
    }
  }

  &__opener {
    border-top: 0;
    border-bottom: 0;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.75);
    @include media-breakpoint-down(sm) {
      margin-left: -10px;
      margin-right: -10px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__wrapper {
    background-color: $c_white;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__content {
    background-color: transparent;
    transform: translateY(0);

    &.open {
      position: fixed;
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translateY(-100%);
      z-index: $zindex-modal;
    }

    &.open {
      transform: translateY(0);

      .pam-filter__bar {
        display: flex;
      }
    }
  }

  &__form {
    padding-bottom: 30px;
  }

  &__item {
    display: flex;
  }

  &__action {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    //margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      justify-content: center;
    }

    p {
      margin-bottom: 0;
      margin-right: 30px;
    }

    .link .icon {
      width: 20px;
      height: 20px;
    }
  }
}
