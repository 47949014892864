/**
* CUSTOMIZE VIDEOPLAYER PLYR VARIABLES
*/
$plyr-color-main: $c_white;
$plyr-video-control-bg-hover: rgba($c_white, 0.2);

@import 'node_modules/plyr/src/sass/plyr.scss';
@import './video-thumbnail';

.video,
.video__container {
  .plyr__menu__container {
    .plyr__control {
      color: var(--primary);

      &:hover {
        color: var(--hover);
      }
    }
  }

  .plyr__control[role='menuitemradio'] {
    &::after {
      background: var(--primary);
    }

    &[aria-checked='true'] {
      &::before {
        background: $plyr-color-main;
        border: 2px solid;
        border-color: var(--primary);
      }
    }

    .plyr__badge {
      background: var(--primary);
    }
  }
}

.video__container {
  @include media-breakpoint-only(xs) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $c_black;

    .plyr--video {
      position: absolute;
      width: 100%;
      overflow: initial;
    }
  }

  .plyr__video-wrapper {
    height: auto;
  }

  .plyr__controls {
    height: 60px;
    padding: 0 10px;
    align-items: center;
    background: rgba($c_black, 0.5);

    @include media-breakpoint-only(xs) {
      height: 50px;
      margin-bottom: -50px;
      transition: none;
    }
  }

  .plyr--video.plyr--hide-controls .plyr__controls {
    opacity: 1;
    pointer-events: auto;
    @include media-breakpoint-only(xs) {
      transform: none;
    }
  }

  .plyr__control[data-plyr='fullscreen'] {
    @include media-breakpoint-only(xs) {
      position: fixed;
      right: 0;
      bottom: 0;
      margin: 10px;
    }
  }

  .plyr__menu {
    @include media-breakpoint-only(xs) {
      position: fixed;
      top: 0;
      left: 0;
      margin: 10px;

      .plyr__menu__container {
        right: unset;
        left: -3px;
        bottom: unset;
        top: 120%;

        &::after {
          top: unset;
          bottom: 100%;
          right: unset;
          left: 15px;
          border-top-color: transparent;
          border-bottom-color: rgba($c_white, 0.9);
        }
      }
    }
  }

  .plyr__volume {
    input {
      overflow: hidden;
    }

    @include media-breakpoint-only(xs) {
      position: fixed;
      left: 0;
      bottom: 0;
      margin: 10px;

      input {
        display: none;
      }

      &:hover input {
        display: inline-flex;
      }
    }
  }
}
