.job-site-details {
  .hdl--2 {
    margin-bottom: 57px;

    @include media-breakpoint-only(xs) {
      margin-top: 53px;
      margin-bottom: 36px;
    }

    @include media-breakpoint-only(md) {
      margin-bottom: 38px;
    }
  }

  article {
    overflow: hidden;

    .hdl--3 {
      margin-bottom: 40px;

      @include media-breakpoint-only(xs) {
        margin-bottom: 29px;
      }

      @include media-breakpoint-only(md) {
        margin-bottom: 26px;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__label {
    margin-bottom: 20px;
    width: 75%;

    @include media-breakpoint-only(xs) {
      width: 100%;
      margin-bottom: 3px;
    }

    span {
      position: relative;
      background-color: #fff;
      box-decoration-break: clone;
      padding-right: 20px;
      z-index: 1;

      &::after {
        content: '';
        display: block;
        width: 2000px;
        border-bottom: 1px dashed #bfbfc1;
        position: absolute;
        left: 100%;
        top: 10px;
        z-index: -1;

        @include media-breakpoint-only(xs) {
          display: none;
        }
      }
    }
  }

  &__value {
    text-align: right;
    width: 25%;

    @include media-breakpoint-only(xs) {
      width: 100%;
      text-align: left;
      margin-bottom: 9px;

      &:last-child {
        margin-bottom: 34px;
      }
    }

    @include media-breakpoint-only(md) {
      &:last-child {
        margin-bottom: 48px;
      }
    }

    span {
      position: relative;
      background-color: #fff;
      padding-left: 15px;
      z-index: 5;

      @include media-breakpoint-only(xs) {
        padding-left: 0;
      }
    }
  }
}
