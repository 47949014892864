.product-tile {
  &__col {
    display: flex;

    &--hidden {
      display: none;
    }
  }

  &__card {
    margin-bottom: 50px;
    border: 0;
    border-radius: 0;
    width: 100%;
    background-color: $c_white;

    &__img {
      padding-top: 20px;
      padding-left: 25px;
      padding-right: 25px;
    }

    &__body {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      margin-left: 25px;
      margin-right: 25px;

      .card-title .hdl {
        margin-bottom: 2px;
        color: var(--primary_alt);
        font-size: 20px;
        padding-top: 20px;
        border-top: 1px solid $c_iron;

        @include media-breakpoint-up(sm) {
          font-size: 22px;
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      margin-left: 25px;
      margin-right: 25px;
      background-color: $c_white;

      .download-link {
        width: 100%;
      }

      .btn {
        align-self: flex-start;
        width: 230px;
        margin-top: 5px;
        margin-bottom: 30px;

        @include media-breakpoint-up(sm) {
          align-self: center;
          margin-bottom: 40px;
        }
      }
    }

    &__control {
      padding: 20px 25px 0;

      .form-group {
        margin: 0;

        label {
          margin: 0;
        }

        .form-check {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .form-check-label {
            align-items: start;
          }
        }
      }
    }
  }

  &__table {
    td {
      padding: 5px 0;
    }
  }

  &__column {
    white-space: nowrap;
    .link {
      display: block;
    }
    .info-popup {
      display: inline-block;

      .icon {
        margin-left: 2px;
      }

      .dropdown-toggle {
        position: static !important;
      }

      .dropdown-menu.show {
        min-width: 250px !important;
        max-height: 100vw;
      }
    }
  }

  &__label {
    white-space: break-spaces;
  }

  &__column--right {
    text-align: right;

    &__text {
      font-weight: $font-weight-demi;
    }

    .info-popup {
      display: inline-block;

      .dropdown-toggle {
        position: static !important;
      }

      .dropdown-menu.show {
        min-width: 250px !important;
        max-height: 100vw;
      }
    }
  }
}
