.product-comparison {
  &__headline {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 45px;

    @include media-breakpoint-down(xs) {
      .headline-text__intro {
        text-align: left;
      }
    }

    section {
      padding: 80px 0 13px;

      @include media-breakpoint-down(xs) {
        padding: 30px 0 13px;
      }
    }
  }

  &__close {
    padding-top: 50px;

    @include media-breakpoint-down(xs) {
      padding-top: 5px;
    }

    svg {
      width: 32px;
      height: 32px;

      @include media-breakpoint-down(xs) {
        width: 26px;
        height: 26px;
      }
    }
  }

  &__config {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    @include media-breakpoint-down(xs) {
      flex-flow: column;
      margin: 0 20px;
      padding-top: 25px;
    }

    button {
      .icon {
        margin-right: 8px;
        color: $c_white;
      }
    }

    a {
      margin-left: 20px;
      @include media-breakpoint-down(xs) {
        justify-content: center;
        margin-left: 0;
        margin-top: 17px;
      }
    }
  }
}
