.add-to-cart {
  display: flex;
  flex-direction: column;

  &__container {
    width: 100%;
  }

  &__amount {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__button {
    button {
      width: 100%;

      .icon {
        width: 32px;
        height: 32px;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.25;
        height: 50px;

        span {
          label { font-size: 15px; }
          span { display: none; }
        }
      }
    }
  }

  &__counter {
    display: flex;
    align-items: center;

    //remove default input[type='number'] arrows
    input[type='number'] {
      appearance: textfield;
    }
    //remove default input[type='number'] arrows
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }

    .counter {
      &__label {
        font-weight: $font-weight-demi;
        word-break: keep-all;
      }

      &__number {
        width: 100%;
        flex-grow: 1;
        height: 36px;
        min-width: 100px;
        border: 2px solid $c_white;
        border-radius: 0;
        background-color: $c_white;
        text-align: center;
        font-weight: $font-weight-medium;
        font-size: 16px;
        color: $c_slate;
        margin-left: 25px;
        margin-right: 25px;

        &:focus {
          border-color: var(--primary);
        }
      }

      &__button {
        display: inline-flex;
        align-items: center;
        padding: 5px;
        background-color: transparent;
        border: 0;
        align-self: start;

        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }

        &:focus {
          outline: none;
          .icon {
            color: $c_outerspace;
          }
        }

        .icon {
          width: 22px;
          height: 22px;
        }

        & + .counter__button {
          margin-left: 6px;
        }
      }
    }
  }
}
