$max-slide-height: 700px;
$big-play-button-size: 80px;

.tabs-small-top {
  display: flex;
  text-align: center;

  .row {
    @include media-breakpoint-up(sm) {
      flex-wrap: nowrap;
      //scrollbar settings
      height: 100%;
      max-height: 100%;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow-x: hidden;
    overflow-y: visible;
  }

  &__content-container {
    width: 100%;
    min-height: 180px;
  }

  &__swiper-wrapper {
    height: auto;
  }

  &__panel {
    background-color: $c_concrete;

    @include media-breakpoint-up(sm) {
      @include aspect-ratio(16, 9);
      max-height: $max-slide-height;
      overflow: hidden;
    }
  }

  .tabs-small-top__image-text_container {
    height: 100%;
  }

  &__container {
    width: 100%;
    height: 50px;
    margin: auto;
    padding: 0;
    z-index: 100;

    .tabs-small-top__label-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      overflow-x: visible;
      overflow-y: visible;

      .nav.nav-tabs {
        border: 0;
        flex-grow: 0;
        display: flex;
        flex-wrap: nowrap;

        // remove margin between content and tabs
        .nav-item {
          margin-bottom: 0;
        }
      }

      .tabs-small-top__label {
        display: block;
        flex-grow: 0;
        flex-shrink: 1;
        width: auto;
        min-width: auto;
        height: 50px;
        padding-top: 10px;
        padding-right: 30px;
        padding-bottom: 0;
        padding-left: 30px;
        border: 0;
        border-radius: 0;
        color: inherit;
        background-color: $c_iron;
        text-decoration: none;
        white-space: nowrap;
        cursor: pointer;

        &.swiper-slide-thumb-active {
          flex-grow: 0;
          // to make tab overlap content
          height: 60px;
          padding-top: 10px;
          padding-right: 30px;
          padding-bottom: 0;
          padding-left: 30px;
          color: var(--primary_contrast);
          background-color: var(--primary);
        }
      }
    }
  }

  .swiper-pagination {
    bottom: -25px;
    height: 50px;
  }

  .swiper-button-next,
  .swiper-button-prev,
  .swiper-button-disabled {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;

    .icon {
      color: $c_white;
    }
  }

  .gradient--right,
  .gradient--left {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
    pointer-events: none;
  }

  &:hover {
    .gradient {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 220px;
      }
    }

    .gradient--left {
      left: 0;
      background: linear-gradient(90deg, rgba($c_black, 0.5) 0%, rgba($c_black, 0) 100%);
      visibility: visible;
      opacity: 1;
      z-index: 100;
    }

    .gradient--right {
      right: 0;
      background: linear-gradient(270deg, rgba($c_black, 0.5) 0%, rgba($c_black, 0) 100%);
      visibility: visible;
      opacity: 1;
      z-index: 100;
    }

    .swiper-button-next:not(.swiper-button-disabled),
    .swiper-button-prev:not(.swiper-button-disabled) {
      @include media-breakpoint-up(lg) {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .tabs-small-top--a-image {
    .tabs-small-top__image--full__container {
      @include aspect-ratio(16, 9);

      .tabs-small-top__image--full {
        // background image settings
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }
  }

  .tabs-small-top__content {
    display: flex;
    flex-direction: column;
    text-align: left;

    @include media-breakpoint-up(sm) {
      //scrollbar settings
      height: 100%;
      max-height: 100%;
    }

    .tabs-small-top__text-container {
      @include media-breakpoint-up(sm) {
        position: relative;
        flex-grow: 0;
        margin-bottom: 30px;
        padding-right: 10px;
        //scrollbar settings
        overflow-y: auto;
      }
    }

    .intro {
      padding-bottom: 15px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 10px;
      }

      @include media-breakpoint-up(xl) {
        padding-bottom: 30px;
      }
    }

    p {
      padding-bottom: 25px;
    }

    .btn {
      align-self: flex-start;
      min-height: 47px;
    }
  }

  .tabs-small-top__panel {
    background-color: $c_concrete;
  }

  // C & D
  .tabs-small-top--c-text,
  .tabs-small-top--d-text-2-columns {
    .tabs-small-top__content {
      padding: 30px 14px;

      @include media-breakpoint-up(sm) {
        padding: 30px 33px;
      }

      @include media-breakpoint-up(md) {
        padding: 40px 124px 60px 124px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 770px;
        padding: 50px 0 60px 0;
        margin-left: auto;
        margin-right: auto;
      }

      @include media-breakpoint-up(xl) {
        padding: 60px 0 60px 0;
      }
    }
  }

  // E
  &__row--no-margin {
    margin: 0;
    height: 100%;
  }

  &__content--2-column {
    padding: 20px 14px 40px 14px;

    @include media-breakpoint-up(md) {
      padding: 60px;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  &__bgimage-2-column__container {
    @include aspect-ratio(16, 9);

    .tabs-small-top__bgimage-2-column {
      //scrollbar settings
      height: 100%;
      max-height: 100%;
      // background image settings
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  // F
  &--f-image-text {
    .tabs-small-top__content--2-column {
      padding-right: 14px;

      @include media-breakpoint-up(md) {
        padding-right: 0;
      }
    }
  }

  .tabs-small-top__image-2-column {
    padding: 0;

    @include media-breakpoint-up(md) {
      padding: 60px 0;
    }
  }

  // to make play button vertically centered on larger screens
  .video__big-play-button {
    .icon-videoicon {
      height: $big-play-button-size;
      width: $big-play-button-size;
    }

    $tabs-breakpoint: ($max-slide-height * 16) / 9;

    @media (min-width: $tabs-breakpoint) {
      /* unset bottom property*/
      bottom: inherit !important;
      /* vertically center button*/
      top: ($max-slide-height - $big-play-button-size) / 2;
    }
  }
}
