.event-calendar {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 20px;

    // Desktop 30px (20px border-spacing + 10px padding)
    @include media-breakpoint-up(lg) {
      width: calc(100% - 20px);
      margin: 10px;
    }
  }

  th {
    width: 30%;
  }
}
