.spare-part-snippet {
  &.section--bglight &__container {
    background-color: $c_concrete;
  }

  &__row {
    margin: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 20px;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      padding: 30px;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  &__picture-toggle {
    margin-right: 24px;
    position: relative;
    @include media-breakpoint-down(xs) {
      margin-bottom: 16px;
    }
  }

  &__icon {
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: var(--primary_contrast);
    background-color: var(--primary);

    .icon {
      color: inherit;
      width: 17px;
      height: 17px;
    }
  }

  &__details {
    border-top: 1px solid $c_iron;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;

    @include media-breakpoint-up(sm) {
      padding: 20px 30px;
    }

    @include media-breakpoint-up(md) {
      align-items: flex-start;
      padding: 22px 40px 22px 15px;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      align-items: flex-start;
      border-top: 0;
      border-left: 1px solid $c_iron;
      padding: 27px 40px 27px 15px;
    }

    &-variants {
      display: flex;
      margin-bottom: 6px;
      width: 100%;

      &-title {
        flex: 0 0 80px;
        font-weight: 600;
      }

      &-list {
        &-tag {
          justify-content: center;
          color: $c_slate;
          margin-right: 5px;
          opacity: .5;

          &.active,
          &:hover {
            opacity: 1;
            background-color: $c_slate;
            color: $c_white;
            margin-right: 5px;
          }
        }
      }
    }

    &-hint {
      margin-top: 20px;
      font-weight: 500;
    }

    .btn {
      display: flex;
      align-items: center;
      height: fit-content;
      padding-top: 0px;
      padding-bottom: 0px;
      margin-bottom: 16px;

      @include media-breakpoint-up(md) {
        margin-right: 16px;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
        margin-right: 0;
      }

      > span {
        margin-right: 15px;
        text-align: left;
      }

      label {
        display: block;
        text-align: left;
        font-weight: $font-weight-demi;
        opacity: 0.75;
        font-size: 13px;
        margin-bottom: 0;
      }

      .icon {
        margin-right: 15px;
        color: inherit;
      }
    }
  }

  &__details-link {
    @include media-breakpoint-up(sm) {
      flex-basis: 50%;
    }
    @include media-breakpoint-up(lg) {
      flex-basis: 0;
    }
    .icon {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
  }

  .download-link {
    @include media-breakpoint-up(sm) {
      flex-basis: 50%;
    }
    @include media-breakpoint-up(lg) {
      flex-basis: 0;
    }
  }

  .downloads__link {
    color: $c_slate;
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 10px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 10px;
    }

    .icon-wrapper > .icon {
      color: var(--primary_alt);
    }
  }
  .modal {
    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--primary);
      color: var(--primary_contrast);
      border: 5px solid $c_iron;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 0;
      margin-top: -5px;
      margin-right: 0;

      .icon {
        color: inherit;
        flex-shrink: 0;
        width: 10px;
        height: 10px;
      }
    }
  }

  .modal-dialog {
    max-width: 650px;
  }

  .modal-content {
    box-shadow: 0 0 15px 0 rgba($c_black, 0.2);
  }
}
