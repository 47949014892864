.stage-search {
  &__stage {
    position: relative;

    .hdl {
      color: $c_white;
      text-align: center;
    }
  }

  &__image {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    position: relative;
    padding-top: 50px;
    padding-bottom: 75px;
    background: rgba($color: #000000, $alpha: 0.4);

    @include media-breakpoint-up(sm) {
      padding-top: 60px;
      padding-bottom: 85px;
    }
    @include media-breakpoint-up(md) {
      padding-top: 70px;
      padding-bottom: 105px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 80px;
      padding-bottom: 115px;
    }
  }

  .form-group {
    position: relative;
    width: 100%;
    margin: auto;
    text-align: center;
    z-index: 100;

    input {
      padding-right: 64px;
      @include media-breakpoint-up(lg) {
        padding-right: 92px;
      }
    }
  }

  &__searcharea {
    position: relative;
  }

  &__btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 100%;
    padding: 0;
    background: transparent;
    border: 0;

    &:active,
    &:focus {
      outline: 0;
      .icon {
        color: var(--focus);
      }
    }

    @include media-breakpoint-up(sm) {
      width: 62px;
    }

    .icon {
      width: 24px;
      height: 24px;

      @include media-breakpoint-up(sm) {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__clear {
    right: 44px;
    display: none;
    width: 36px;

    @include media-breakpoint-up(sm) {
      right: 62px;
    }

    .icon {
      width: 16px;
    }
  }

  .bread-bar {
    position: relative;
    z-index: 11;
  }

  &__flyout {
    position: absolute;
    width: calc(100% - 30px);
    display: none;
    margin-top: 2px;
    padding: 0;
    background-color: $c_white;
    border: 2px solid var(--primary);
    list-style-type: none;
    text-align: start;

    li {
      padding: 4px 20px;
      cursor: pointer;

      em {
        font-weight: $font-weight-bold;
      }

      &:hover {
        color: var(--hover);
        background-color: $c_iron;
      }
    }
  }

  &--b {
    input {
      border-color: $c_slate;
      &:focus {
        border-color: var(--focus);
      }
    }
  }
}
