.function-list {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: flex-start;

  &__item {
    padding: 0 15px;
    max-width: 140px;

    @include media-breakpoint-up(md) {
      padding-right: 30px;
    }

    .icon {
      fill: $c_slate;
      color: var(--primary);
      margin-bottom: 5px;
      height: 30px;
      width: 30px;
    }

    .hdl {
      margin-bottom: 0;
      max-height: 40px;
      font-size: 15px;
      line-height: 20px;
    }

    p {
      font-weight: $font-weight-demi;
    }
  }
}
