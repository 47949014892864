.anchor-nav {
  color: $c_white;
  position: relative;
  margin-top: -25px;
  z-index: $zindex-sticky;

  .container {
    transition: max-width 0.25s ease-in-out;
  }

  .navbar-nav {
    flex-wrap: nowrap;
    align-items: baseline;
  }

  .anchor-nav__navbar {
    justify-content: start;
    background-color: var(--primary);
    color: var(--primary_contrast);
    border-radius: 25px;
    padding: 0 10px;
    transition: all 0.25s linear;

    @include media-breakpoint-up(md) {
      padding: 0 50px;
    }

    // product brand is visible only on larger screens and when stuck
    &-brand {
      display: none;
      font-weight: $font-weight-demi;
      font-size: 18px;
      padding: 0;
    }

    &--collapse {
      padding: 0 10px 10px 35px;

      @include media-breakpoint-up(md) {
        height: 50px;
        padding: 0;
      }
    }

    &--right {
      flex-direction: row;

      @include media-breakpoint-down(sm) {
        position: absolute;
        right: 10px;
        top: 0;
        height: 50px;
      }
    }

    .icon {
      color: var(--primary_contrast);
    }

    .anchor-nav__toggler {
      color: inherit;

      .icon {
        width: 12px;
        height: 12px;
      }
    }

    .nav-link {
      color: inherit;

      &:hover {
        color: inherit;
      }

      &:active,
      &:focus {
        color: inherit;
      }

      .icon {
        width: 30px;
        height: 30px;
      }
    }

    // .anchor-nav__link {
    //   max-width: 150px;
    // }

    .anchor-nav__icon-text {
      display: none;

      @include media-breakpoint-up(md) {
        display: inline-block;
      }
    }
  }

  &:not(.is_stuck) {
    .anchor-nav__navbar {
      .anchor-nav__link {
        @include media-breakpoint-up(lg) {
          margin-right: 10px;
        }
      }
    }
  }

  &.is_stuck {
    .anchor-nav__navbar {
      border-radius: 0;

      .anchor-nav__navbar-brand {
        @include media-breakpoint-up(lg) {
          display: inline-block;
        }
      }

      .anchor-nav__navbar--collapse {
        justify-content: center;

        .anchor-nav__link {
          @include media-breakpoint-up(lg) {
            padding: 0 20px;
          }
        }
      }
    }

    .container {
      max-width: 100%;
      padding: 0;
    }
  }

  .anchor-nav__navbar.fullscreen {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
  }

  &--active {
    @include media-breakpoint-between(xs, sm) {
      display: none;
    }
  }

  &__toggler {
    height: 50px;
    text-align: left;

    &__label {
      font-size: 14px;
      font-weight: $font-weight-demi;
      margin-left: 5px;
      color: var(--primary_contrast);

      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }

      @include media-breakpoint-up(md) {
        display: none;
      }

      &:hover {
        color: var(--primary_contrast);
        text-decoration: none;
      }
    }
  }
}
