@import './map';
@import './dealer__results';

.dealer {
  &__form {
    background-color: $c_concrete;

    .select {
      @include media-breakpoint-down(sm) {
        margin: 10px 0;
      }
    }
  }

  &__item {
    margin-bottom: 30px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 60px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__brands {
    @include media-breakpoint-up(sm) {
      border-left: 1px solid $c_concrete;
      padding: 10px 20px;
    }
  }

  &__logo {
    position: relative;
    padding: 10px 0;

    &.john_deere {
      border-top: 1px solid $c_iron;
      padding-top: 20px;
      margin-top: 10px;
    }

    .icon {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
    }

    &--on .icon {
      display: block;
    }
  }
}
