.clamped-text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamped-text + .btn {
  display: none;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 5px;
  text-align: start;

  .icon {
    min-width: 10px;
    width: 10px;
    height: 10px;
    color: inherit;
    margin-right: 4px;
    margin-left: -2px;
    margin-top: 2px;
  }
}
