.component-selector {
  text-align: left;
  background-color: $c_concrete;
  padding-top: 32px;
  padding-bottom: 32px;

  @include media-breakpoint-down(sm) {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  img {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .link {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    &__deactive {
      opacity: 0.6;
    }
  }

  &__hotspots-wrapper {
    position: relative;

    .hotspot {
      position: absolute;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      min-height: 20px;
      transform: translate(-50%, -50%);
      //
      border: 1px solid $c_white;
      background-color: var(--primary);
      color: $c_white;
      font-size: 10px;
      font-weight: $font-weight-demi;
      user-select: none;

      &[data-type='circle'] {
        border-radius: 50%;
      }

      @include media-breakpoint-up(sm) {
        min-width: 30px;
        min-height: 30px;
        font-size: 15px;
      }
    }
  }
}
