.sticky-service-menu {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  z-index: 2000;
  position: sticky;
  max-width: 100%;
  width: fit-content;
  margin: 0;
  bottom: 48px;
  left: 100%;
  pointer-events: none;

  .modal-open & {
    display: none;
  }

  &.at-footer {
    position: fixed;
    right: 0;
    left: auto;
    bottom: 48px;
  }

  .icon {
    margin-left: 12px;
    margin-right: 12px;
    width: 40px;
    height: 40px;
  }

  .moveOut {
    transform: translateX(0) !important;
    transition: transform 300ms ease-in-out;

    .sticky-service-link-text {
      margin-right: 12px;
    }

    .icon {
      margin-right: 8px;
    }
  }

  .moveIn {
    transform: translateX(calc(100% - 64px));
    transition: transform 300ms ease-in;
  }

  .visible {
    transform: translateX(calc(100% - 64px));
    transition: transform 300ms ease-out;
  }

  .hide {
    transform: translateX(calc(100% + 1px));
    transition: transform 300ms ease-in;
  }

  &_list {
    padding-left: 0;
  }

  &_hiddenHeadline {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
  }

  .sticky-service-link {
    display: flex;
    align-items: center;
    list-style: none;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: -1px;
    color: $c_white;
    background-color: $c_slate;
    width: 100%;
    text-decoration: none;
    pointer-events: auto;

    &.-tinted {
      color: $c_slate;
      background-color: $c_iron;
    }

    .sticky-service-link-text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
  }

  &_list-item {
    list-style-type: none;

    &:not(:last-child) {
      border-bottom: 2px solid $c_white;
    }

    @media (min-width: 768px) {
      &:hover {
        transform: translateX(0);
        transition: transform 300ms ease-in-out;
        cursor: pointer;

        .sticky-service-link-text {
          margin-right: 12px;
        }

        .icon {
          margin-right: 8px;
        }
      }
    }
  }
}
