$c_white: #ffffff;
$c_black: #000000;
$c_slate: #41535d;
$c_danger: #ff0000;
$c_yellow: #ffba5f;

// abstufungen
$c_concrete: #f3f3f3;
$c_iron: #d9dddf;
$c_graychateau: #a0a9ae;
$c_oxfordblue: #2f434d;

// markenfarben
$c_internationalorange: #fb5a02;
$c_deepsea: #008656;
$c_christine: #ec6608;
$c_funblue: #1755a2;
$c_endeavour: #0062a7;
$c_schoolbusyellow: #fdda01;

// wirtgen-group
$c_bluebayoux: #516a78;
$c_outerspace: #2c3439;
$c_silversand: #bdc3c6;

// wirtgen
$c_crusta: #ff823e;
$c_trinidad: #ee5603;
$c_waxflower: #fec5a7;

// voegele
$c_jade: #00a66a;
$c_watercourse: #057e53;
$c_sinbad: #a5d4c3;

// hamm
$c_blazeorange: #ff7000;
$c_pomegranate: #f1510f;
$c_corvette: #f8c9a8;

// kleemann
$c_mariner: #256ac0;
$c_chathamsblue: #14488a;
$c_pigeonpost: #aec4df;

// benninghoven
$c_curiousblue: #2682c3;
$c_toryblue: #116097;
$c_regentstblue: #a6c8e0;

// john-deere
$c_brightsun: #ffe440;
$c_corn: #e0c101;
$c_visvis: #fff2a6;

$brand-colors: (
  wirtgen-group: (
    primary: $c_slate,
    primary_alt: $c_slate,
    primary_contrast: $c_white,
    hover: $c_bluebayoux,
    hover_alt: $c_bluebayoux,
    focus: $c_outerspace,
    focus_alt: $c_outerspace,
    disabled: $c_silversand,
    error: $c_yellow,
  ),
  wirtgen: (
    primary: $c_internationalorange,
    primary_alt: $c_internationalorange,
    primary_contrast: $c_white,
    hover: $c_crusta,
    hover_alt: $c_crusta,
    focus: $c_trinidad,
    focus_alt: $c_trinidad,
    disabled: $c_waxflower,
    error: $c_yellow,
  ),
  hamm: (
    primary: $c_christine,
    primary_alt: $c_christine,
    primary_contrast: $c_white,
    hover: $c_blazeorange,
    hover_alt: $c_blazeorange,
    focus: $c_pomegranate,
    focus_alt: $c_pomegranate,
    disabled: $c_corvette,
    error: $c_yellow,
  ),
  benninghoven: (
    primary: $c_endeavour,
    primary_alt: $c_endeavour,
    primary_contrast: $c_white,
    hover: $c_curiousblue,
    hover_alt: $c_curiousblue,
    focus: $c_toryblue,
    focus_alt: $c_toryblue,
    disabled: $c_regentstblue,
    error: $c_yellow,
  ),
  voegele: (
    primary: $c_deepsea,
    primary_alt: $c_deepsea,
    primary_contrast: $c_white,
    hover: $c_jade,
    hover_alt: $c_jade,
    focus: $c_watercourse,
    focus_alt: $c_watercourse,
    disabled: $c_sinbad,
    error: $c_yellow,
  ),
  kleemann: (
    primary: $c_funblue,
    primary_alt: $c_funblue,
    primary_contrast: $c_white,
    hover: $c_mariner,
    hover_alt: $c_mariner,
    focus: $c_chathamsblue,
    focus_alt: $c_chathamsblue,
    disabled: $c_pigeonpost,
    error: $c_yellow,
  ),
  ciber: (
    primary: $c_internationalorange,
    primary_alt: $c_internationalorange,
    primary_contrast: $c_white,
    hover: $c_crusta,
    hover_alt: $c_crusta,
    focus: $c_trinidad,
    focus_alt: $c_trinidad,
    disabled: $c_waxflower,
    error: $c_yellow,
  ),
  john-deere: (
    primary: $c_schoolbusyellow,
    primary_alt: $c_outerspace,
    primary_contrast: $c_outerspace,
    hover: $c_brightsun,
    hover_alt: $c_outerspace,
    focus: $c_corn,
    focus_alt: $c_outerspace,
    disabled: $c_visvis,
    error: $c_yellow,
  ),
);

$navbarheight: (
  md: 80px,
  xs: 50px,
);

/**
 * CUSTOMIZE BOOTSTRAP
 */

// font variables
$font-family-sans-serif: 'AvenirNext', -apple-system, 'Segoe UI', 'Helvetica Neue', sans-serif;

$font-size-base: 0.875rem;
$font-size-sm: $font-size-base * 1.071428571428571; // 0.9375rem

$line-height-base: 1.666666666666667;

$font-weight-light: 300; // Avenir-Light
$font-weight-normal: 400; // Avenir-Regular
$font-weight-medium: 500; // Avenir-Medium
$font-weight-demi: 600; // Avenir-DemiBold
$font-weight-bold: 700; // Avenir-Bold

$popover-border-radius: 0;
$body-color: $c_slate;
$body-bg: $c_iron;

// $spacer: 10px;

$theme-colors: (
  'primary': $c_slate,
  'danger': $c_danger,
  'success': $c_deepsea,
);

$enable-shadows: false;
$btn-focus-box-shadow: false;
$input-focus-box-shadow: false;
$btn-focus-width: 0;

// grid
$grid-columns: 12;
$grid-gutter-width: 2.5%;
$grid-offgrid-size: 1920px;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  lg: 960px,
  xl: 1170px,
);

$xxl-breakpoint: 1920px;

$breadcrumb-bg: $c_white;
$breadcrumb-divider: url('symbols.svg#icon-arrow');
// $breadcrumb-divider: url('symbols.svg#breadcrumb-mobile');
$breadcrumb-item-padding: 15px;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 20px;
$breadcrumb-active-color: var(--primary);
$breadcrumb-margin-bottom: 0;

$modal-md: 520px;
$modal-dialog-margin: 0;
$modal-content-border-radius: 0;
$modal-content-border-width: 0;
$modal-backdrop-bg: $c_black;
$modal-backdrop-opacity: 0.5;

//forms
$form-group-margin-bottom: 10px;

//tooltips
$tooltip-color: #fff;
$tooltip-bg: #41535d;
