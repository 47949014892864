.product-flags {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 6px;
  padding-bottom: 18px;

  &--inline {
    flex-direction: row;
    text-align: left;
    justify-content: left;
    gap: 12px;
  }

  .product-flag {
    &--svg {
      .inline-svg {
        display: inline-block !important;

        svg {
          width: 100%;
          height: 32px;
          position: initial;
          background: transparent;

          @include media-breakpoint-down(md) {
            width: 100%;
            height: 24px;
          }
        }
      }
    }

    &--gpx {
      .inline-svg {
        display: inline-block !important;

        svg {
          width: 76px;
          height: 32px;

          @include media-breakpoint-down(md) {
            width: 57px;
            height: 24px;
          }
        }
      }
    }
  }
}

.product-text  {
  display: flex;
  flex-direction: column;
  text-align: end;
  gap: 6px;

  .product-tile__card & {
    transform: translateY(-1px);
  }

  &--inline {
    flex-direction: row;
    text-align: center;
    justify-content: center;
    gap: 12px;
    padding: 20px 0;

    @include media-breakpoint-up(md) {
      padding: 0;
      text-align: left;
      justify-content: left;
    }
  }

  &--text {
    span {
      width: fit-content;
      background: var(--primary);
      color: var(--primary_contrast);
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      padding: 3px 4px;
      letter-spacing: 1px;
    }
  }
}
