@import './animation';

$transitionSpeed: 500ms;
$transitionEasing: ease-in-out;

/**
 * Defines the red dot shown at the cart-icon, to
 * determine that some items are on the wishlist
 * of the user
 */
@mixin cart-not-empty-marker($radius) {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  width: $radius;
  height: $radius;
  background: $c_danger;
  border-radius: 50%;
}

.header {
  position: relative;
  background-color: $c_slate;

  @import './navbar.scss';
  @import './flyout/flyout.scss';
  @import './nav-item/nav-item.scss';
}

.header--pam {
  .nav-item--spareparts,
  .nav-item--parts-guide {
    @include media-breakpoint-up(lg) {
      position: relative;
    }
  }

  .navbar .flyout--spareparts,
  .navbar .flyout--parts-guide {
    top: 54px;
    left: -30px;
    right: auto;
    width: auto;

    @include media-breakpoint-up(lg) {
      position: absolute;
      padding: 30px;
    }
  }

  .navbar .flyout--sub .icon {
    display: none;
  }

  .navbar .flyout.flyout--spareparts .nav-item,
  .navbar .flyout.flyout--parts-guide .nav-item {
    &:last-child .nav-link {
      padding-bottom: 0;
    }

    .nav-link {
      width: auto;
      font-weight: $font-weight-demi;
    }

    .flyout--sub {
      display: none;
      border-left: 5px solid $c_slate;
      @include media-breakpoint-up(lg) {
        position: absolute;
        left: 100%;
        height: auto;
        width: 100%;
        padding: 30px;
        background-color: $c_oxfordblue;
        min-width: 250px;
      }

      @include media-breakpoint-down(md) {
        border: none;
      }

      .nav-link {
        display: flex;
        align-items: baseline;
        width: auto;
        padding-bottom: 20px;

        @include media-breakpoint-down(md) {
          padding-bottom: 0;
        }
      }

      .navbar-nav .nav-item {
        &:last-child .nav-link {
          padding-bottom: 0;
        }
      }

      .icon {
        position: relative;
        top: 2px;
        display: flex;
        flex-shrink: 0;
        margin-right: 10px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    &.show {
      .flyout--sub {
        display: flex;
      }
    }
  }

  .navbar-cart {
    &__headline {
      font-weight: $font-weight-demi;

      em {
        font-style: normal;
      }
    }

    &__text {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn--order {
      width: 100%;
      margin-top: 30px;
    }

    .btn--continue {
      width: 100%;
      text-align: center;
      margin-top: 15px;
    }
  }

  .navbar {
    /* ODSUPPORT-4994 - Hide arrow right icon
     * if no flyout/subnavigation are available
     */
    .navigation .nav-item > .nav-link {
      &:not([data-toggle-level]) {
        .icon {
          display: none;
        }
      }
    }

    .nav-link--cart {
      margin: 10px 15px 10px auto;
      position: relative;

      &.not-empty:before {
        @include media-breakpoint-down(md) {
          @include cart-not-empty-marker(10px);
        }
      }

      @include media-breakpoint-up(lg) {
        margin-left: 30px;
        margin-right: 30px;
      }
    }

    .nav-item--cart:not(.show) .nav-link--cart.not-empty:before {
      @include cart-not-empty-marker(14px);
    }

    .nav-link--service {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
}

.header--mag {
  position: absolute;
  width: 100%;
  background: transparent;
  border-bottom: 1px solid #fff;

  &--no-overlap {
    position: relative !important;
    background: var(--primary) !important;
  }

  .header--overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(65, 83, 93, 0.4);
    z-index: 10;
  }

  .navbar {
    .navigation {
      @include media-breakpoint-up(lg) {
        background: transparent;
      }
    }

    .navbar-bar {
      background: transparent;
    }
  }

  .navbar-nav {
    .nav-item--language {
      background-color: transparent !important;

      .icon-close {
        width: 54.7188px !important;
      }

      &.show {
        margin-top: 0;
      }
    }

    .curr-language {
      font-size: 13px;
      font-weight: 500;
      margin-left: 3px;
    }

    .nav-item .nav-link .icon-arrow {
      display: none;
    }

    @include media-breakpoint-down(md) {
      .nav-item:has(> .flyout) > .nav-link .icon-arrow {
        display: flex;
      }
    }
  }

  + .stage-static .hdl {
    padding-top: calc(25px + #{map-get($navbarheight, xs)});

    @include media-breakpoint-up(md) {
      padding-top: calc(50px + #{map-get($navbarheight, xs)});
    }

    @include media-breakpoint-up(lg) {
      padding-top: calc(75px + #{map-get($navbarheight, md)});
    }
  }
}

/* Hide language label on other
 header variants */
.header:not(.header--mag) {
  .curr-language {
    display: none;
  }
}
