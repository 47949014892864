.back-top {
  position: fixed;
  // same z-index then modal, keep it below modal
  z-index: $zindex-fixed;
  display: flex;
  right: 10px;
  bottom: 10px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  background-color: rgba($c_black, 0.5);
  border-radius: 50%;
  transform: scale(0);
  transition: transform 250ms ease-in-out;

  @include media-breakpoint-up(md) {
    right: 35px;
    bottom: 35px;
    width: 50px;
    height: 50px;
  }

  .icon {
    color: $c_white;
  }

  &--enabled {
    transform: scale(1);
  }
}
