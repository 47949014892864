.flyout {
  display: flex;
  background-color: $c_oxfordblue;
  color: $c_white;

  @include media-breakpoint-up(lg) {
    display: none;
    padding: 50px 0;

    position: fixed;
    width: 100vw;
    max-width: 1920px;
    top: 100%;
    left: 0;
    right: 0;
    bottom: auto;

    color: $c_white;
  }

  .row.flex-nowrap {
    @include media-breakpoint-up(lg) {
      display: block;

      .nav-teaser {
        float: left;
      }
    }
  }

  &--bg-light {
    @include media-breakpoint-down(md) {
      background-color: $c_white;
      color: $c_slate;
    }

    @include media-breakpoint-down(md) {
      .nav-link--external {
        color: $c_slate;

        &.active {
          color: $c_slate;
        }
      }
    }
  }

  &--sub {
    position: absolute;
    top: 0;
    width: 100%;
    padding-bottom: 15px;

    @include media-breakpoint-up(lg) {
      position: relative;
      top: 0;
      width: auto;
      padding: 0;
    }
  }

  &--teaser {
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      padding-bottom: 50px;
    }

    .nav-link {
      text-transform: none;
    }

    .navbar-nav {
      @include media-breakpoint-up(lg) {
        padding-left: 30px;
      }
    }
  }

  &--small {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    width: 100%;
    padding: 0;

    @include media-breakpoint-up(lg) {
      // transform: translateX(-50%);
      max-width: 400px;
      width: auto;
      right: auto;
    }

    .navbar-nav {
      padding: 20px 20px 30px 20px;
      color: inherit;
    }
  }

  &.hidden {
    display: none !important;
  }

  &.show {
    display: flex;
    opacity: 1;
    animation: fadeIn ease-in-out 250ms;
  }

  .nav-item {
    width: 100%;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  .d-flex > .navbar-nav {
    width: 100%;
  }

  .link,
  .navlink {
    color: inherit;
  }

  .btn-secondary {
    color: $c_white;
    border-color: $c_white;
    display: block;
  }

  .btn-clean {
    color: $c_white;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

// [data-show-level='0'] [data-nav-level='0'],
// [data-show-level='0'] [data-nav-level='0'] .nav-link,
// [data-show-level='1'] [data-nav-level='1'],
// [data-show-level='1'] [data-nav-level='1'] .nav-link,
// [data-show-level='2'] [data-nav-level='2'],
// [data-show-level='2'] [data-nav-level='2'] .nav-link,
// [data-show-level='3'] [data-nav-level='3'],
// [data-show-level='3'] [data-nav-level='3'] .nav-link {
//   display: flex;
//   opacity: 1;
// }

// [data-show-group='1'] [data-nav-group='1'],
// [data-show-group='2'] [data-nav-group='2'] {
//   display: flex;
// }

// [data-show-level='2'] .active + .flyout [data-nav-level='1'],
// [data-show-level='2'] [data-nav-level='1'] [data-nav-level='2'],
// [data-show-level='2'] [data-nav-level='2'] [data-nav-level='2'] .nav-link {
//   display: flex;
// }

// // [data-show-group='1'][data-show-level='1'] [data-nav-level='1'],
// // [data-show-group='1'][data-show-level='1'] [data-nav-level='1'] .nav-link {
// //   display: flex;
// // }
