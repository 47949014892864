.pagination-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba($c_black, 0.8);
  position: absolute;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  border-radius: 10px 10px 0 0;
  width: fit-content !important;
  height: 50px;

  @include media-breakpoint-down(xs) {
    width: 100% !important;
    border-radius: 0;
  }
}

.swiper-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;

  @include media-breakpoint-down(xs) {
    margin: 0;
  }

  &-bullet {
    width: 15px;
    height: 15px;
    opacity: 0.1;
    margin: 0 12px !important;
    display: inline-block;
    // background: black;
    border-radius: 50%;
    opacity: 1;
    background-color: transparent;
    border-color: var(--primary);
    border-style: solid;
    border-width: 1px;
    margin: 0 17.5px !important;

    &-active {
      background-color: var(--primary);
      opacity: 1;
    }

    &:hover {
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        top: -18px;
        left: -18px;
        right: -18px;
        bottom: -18px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        z-index: -1;
      }
    }

    &:focus-visible {
      // disable the custom focus blue border
      outline: none;

      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -18px;
        left: -18px;
        right: -18px;
        bottom: -18px;
        border: 2px solid var(--hover);
        border-radius: 10px;
        z-index: -1;
      }
    }
  }
}
