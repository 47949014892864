.iconlist {
  display: table;
  margin-bottom: 10px;

  &__ico {
    display: table-cell;
    padding-right: 10px;
  }

  &__content {
    width: 100%;
    display: table-cell;
  }

  &__link {
    margin-left: 35px;
  }
}
