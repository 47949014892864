$hotspotBorderMobile: 5px;
$hotspotBorderTablet: 6px;
$hotspotBorderDesktop: 10px;

$hotspotWidthMobile: 30px;
$hotspotWidthTablet: 40px;
$hotspotWidthDesktop: 60px;

$hotspotRadiusMobile: $hotspotWidthMobile / 2;
$hotspotRadiusTablet: $hotspotWidthTablet / 2;
$hotspotRadiusDesktop: $hotspotWidthDesktop / 2;
$hotspotLineHeight: 100px;

$hotspotBorderMobile: 5px;
$hotspotBorderTablet: 6px;
$hotspotBorderDesktop: 10px;

$hotspotWidthMobile: 30px;
$hotspotWidthTablet: 40px;
$hotspotWidthDesktop: 60px;

$hotspotRadiusMobile: $hotspotWidthMobile / 2;
$hotspotRadiusTablet: $hotspotWidthTablet / 2;
$hotspotRadiusDesktop: $hotspotWidthDesktop / 2;
$hotspotLineHeight: 100px;

$hotspotTipWidth: 170px;

@keyframes pulse-white {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

// This is just a single spot
.background-image-hotspots .hotspot {
  &__spot {
    position: absolute;
    display: inline-flex;
    width: $hotspotWidthMobile;
    height: $hotspotWidthMobile;
    top: 0;
    margin-top: -#{$hotspotRadiusMobile};
    margin-left: -#{$hotspotRadiusMobile};
    border: $hotspotBorderMobile solid rgba($c_black, 0.2);
    border-radius: 50%;
    background-clip: padding-box;
    padding: $hotspotBorderMobile;
    background-color: $c_white;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      width: $hotspotWidthTablet;
      height: $hotspotWidthTablet;
      border-width: $hotspotBorderTablet;
      margin-top: -#{$hotspotRadiusTablet};
      margin-left: -#{$hotspotRadiusTablet};
      padding: $hotspotBorderTablet;
    }

    @include media-breakpoint-up(lg) {
      width: $hotspotWidthDesktop;
      height: $hotspotWidthDesktop;
      border-width: $hotspotBorderDesktop;
      margin-top: -#{$hotspotRadiusDesktop};
      margin-left: -#{$hotspotRadiusDesktop};
      padding: $hotspotBorderDesktop;
    }

    &.hotspot--wasClicked {
      background-color: $c_silversand;

      .icon-plus {
        color: var(--primary);
      }
    }

    .icon-plus {
      width: 10px;
      height: 10px;
      margin: auto;
      // transition: transform 300ms ease-in-out;
      color: var(--primary);

      @include media-breakpoint-up(lg) {
        width: 15px;
        height: 15px;
      }
    }

    &:hover {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
      animation: pulse-white 1.5s infinite;
    }
  }

  &__line {
    display: none;
    border-color: #fff !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);

    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
    }
  }

  &__tip {
    display: none;
    width: auto !important;
    height: auto !important;
    max-width: 230px;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #fff;
    padding: 8px 10px 3px;
    transform: translateX(calc(50% * -1)) !important;

    @include media-breakpoint-up(lg) {
      position: absolute;
      z-index: 20;
      display: flex;
      font-weight: $font-weight-demi;
    }

    > div {
      width: 100%;
    }
  }

  &--opened {
    background-color: var(--primary);

    .icon {
      color: var(--primary_contrast);
      transform: rotate(45deg);
    }
  }

  &--hidden {
    opacity: 0;
  }

  &.hotspot--left .hotspot__tip {
    margin-left: auto;
  }

  &.hotspot--top {
    .hotspot__line {
      top: 195px !important;
    }
  }
}

// This is the real component base class!
.background-image-hotspots {
  position: relative;
  opacity: 0;
  transition: opacity 250ms ease-out;

  .hotspot__wrapper {
    position: relative;
    margin: 0;
  }

  .hotspot__content {
    display: none;
  }

  .hotspot__line {
    border-left: 2px dashed $c_graychateau;
  }

  .hotspot--top {
    .hotspot__line {
      top: -150px;
    }

    .hotspot__tip {
      top: -200px;

      > div {
        margin-top: auto;
      }
    }
  }

  .hotspot--bottom {
    .hotspot__line {
      bottom: -150px;
    }

    .hotspot__tip {
      bottom: -200px;
    }
  }
}
