.stickytable {
  position: relative;

  .table {
    display: block;
    margin: 0;

    @include media-breakpoint-up(md) {
      display: table;
      table-layout: fixed;
      word-wrap: break-word;
    }
  }

  .table-row {
    display: block;

    @include media-breakpoint-up(md) {
      display: table-row;
    }
  }

  .table-cell {
    display: block;
    min-width: fit-content;

    @include media-breakpoint-up(md) {
      display: table-cell;
      border-bottom: $table-border-width solid $table-border-color;
    }
  }

  .table-body {
    display: block;

    @include media-breakpoint-up(md) {
      display: table-row-group;
    }
  }

  .table-heading {
    display: none;

    .table-cell {
      position: sticky;
      top: 0;
      background-color: $c_white;
      padding: 20px 0;
      z-index: 1;
    }

    @include media-breakpoint-up(md) {
      display: table-header-group;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &__row {
    padding: 20px 0 10px;

    &:last-of-type {
      .table-cell {
        border: none;
      }
    }
  }

  &__headline {
    font-weight: $font-weight-bold;
    border-bottom: $table-border-width solid $table-border-color;
    padding: 12px 0;
    width: auto;

    span {
      color: var(--primary);
    }

    @include media-breakpoint-up(md) {
      border-bottom: none;
      width: max-content;
    }

    &--noBorder {
      border: none;
    }

    &--attribute {
      span {
        color: $c_slate;
      }
    }

    &--info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__inner {
      display: flex;
      flex: 100%;
      align-items: center;
    }
  }

  &__elements {
    @include media-breakpoint-up(md) {
      display: flex;
    }

    .stickytable__element {
      &:first-of-type {
        .stickytable__text {
          display: none;
        }
        .stickytable__product {
          display: none;
        }
      }
    }
  }

  &__element {
    margin: 15px 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__product {
    @include media-breakpoint-up(md) {
      display: none;
    }
    font-weight: $font-weight-bold;
  }

  &__text {
    @include media-breakpoint-up(md) {
      padding: 12px 27px 33px 0;
    }

    .icon-check {
      color: var(--primary);
    }
  }

  &__header {
    &__text {
      margin-right: 27px;
      font-weight: $font-weight-bold;

      &:first-of-type {
        span {
          visibility: hidden;
        }
      }
    }
  }

  .tagcontainer {
    padding-top: 7px;
    max-width: 1080px;

    @include media-breakpoint-down(lg) {
      max-width: 880px;
    }

    @include media-breakpoint-down(md) {
      max-width: 750px;
    }

    .active {
      color: var(--white);
      background-color: var(--primary);
    }
  }

  .filter-tags {
    display: contents;
  }
}
