@import "billboard.js/src/scss/billboard";

.bb-chart-radars .bb-axis text {
  font-family: "AvenirNext", -apple-system, "Segoe UI", "Helvetica Neue", sans-serif;
  font-size: 13px !important;

  @include media-breakpoint-only(xs) {
    font-size: 1em !important;
  }
}
