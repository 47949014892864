.notepad {
  &__table {
    height: auto;

    @include media-breakpoint-down(sm) {
      order: 2;
    }

    table {
      width: 100%;
    }

    thead {
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    tbody {
      @include media-breakpoint-down(xs) {
        display: unset;
      }
    }

    tr {
      border-bottom: 1px solid $c_iron;

      @include media-breakpoint-down(xs) {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
      }
    }

    th {
      font-size: 13px;
      padding-bottom: 20px;
    }

    td {
      padding-top: 24px;
      padding-bottom: 24px;
      font-size: 16px;
      font-weight: $font-weight-bold;

      @include media-breakpoint-down(xs) {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      label {
        display: block;
        font-size: 13px;
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
          display: none;
        }
      }
    }

    .close {
      opacity: 1;
      width: 22px;
      height: 22px;

      @include media-breakpoint-down(xs) {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .incrementer {
      &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 96px;
        height: 32px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: $c_iron;
        border-radius: 16px;
      }

      &__number {
        height: inherit;
        width: 32px;
        border: inherit;
        background-color: transparent;
        text-align: center;
        font-weight: $font-weight-medium;
        font-size: 16px;
        color: $c_slate;
      }

      //remove default input[type='number'] arrows
      input[type='number'] {
        appearance: textfield;
      }
      //remove default input[type='number'] arrows
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
      }

      &__button {
        display: flex;
        align-items: center;
        padding: 0;
        background-color: transparent;
        border: 0;

        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }

        &:focus {
          outline: none;
          .icon {
            color: $c_outerspace;
          }
        }

        .icon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &__functions {
    width: 100%;
    z-index: 10;

    @include media-breakpoint-down(sm) {
      position: sticky;
      order: 1;
      margin-bottom: 30px;
      padding: 0;
    }

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: row;
    }

    @include media-breakpoint-up(md) {
      flex-direction: column;
    }
  }

  &__links {
    padding: 20px;
    background-color: $c_iron;

    @include media-breakpoint-up(sm) {
      flex-basis: 50%;
    }

    @include media-breakpoint-up(md) {
      flex-basis: 0;
    }

    a + .notepad__download {
      margin-top: 20px;
    }

    .notepad__print,
    .notepad__download {
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }

  &__parts-shop {
    background-color: $c_iron;
    margin-top: 20px;
    padding: 25px 20px 30px;

    @include media-breakpoint-up(sm) {
      flex-basis: 50%;
      margin-top: 0;
      margin-left: $grid-gutter-width;
    }

    @include media-breakpoint-up(md) {
      flex-basis: 0;
      margin-left: 0;
      margin-top: 20px;
    }

    .btn {
      width: 100%;
    }
  }

  &__details-link {
    margin-top: 20px;
    align-items: baseline;
  }

  .modal-dialog {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 24px;
    text-align: center;
  }
}

/* ODSUPPORT-4779 - Notepad print CSS */
@media print {
  /* Force printing backgrounds */
  * {
    -webkit-print-color-adjust: exact !important; // Chrome, Safari
    color-adjust: exact !important; // Firefox
  }

  html,
  body {
    background: #fff;
  }

  /* Nav/nav-items */
  .navbar {
    display: flex;

    .navbar-nav {
      display: none !important;
    }

    .nav-link,
    .navbar-toggler {
      display: none !important;
    }
  }

  .notepad {
    .notepad__table {
      flex: 0 0 100%;
      max-width: 100%;

      td {
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: normal;
      }

      /* Orderamount */
      td:nth-child(3) {
        .incrementer__container {
          background: none;
          padding-left: 0;

          .incrementer__button {
            display: none;
          }
          .incrementer__number {
            text-align: left;
            font-weight: 300;
            color: var(--primary);
          }
        }
      }

      /* Remove button */
      td button.close {
        display: none;
      }
    }

    /* Sidebar */
    .notepad__functions {
      display: none;
    }
  }

  footer {
    display: none !important;
  }
}

