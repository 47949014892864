.icon-text {
  color: $c_slate;
  font-weight: $font-weight-demi;
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    color: var(--primary_alt);
    display: block;
    height: 33px;
    width: 33px;
    margin: 10px 0;

    @include media-breakpoint-up(sm) {
      height: 42px;
      width: 42px;
    }

    @include media-breakpoint-up(xl) {
      height: 56px;
      width: 56px;
    }
  }
}
