@charset 'utf-8';

/*
    This CSS resource incorporates links to font software which is the valuable copyrighted
    property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
    redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
    Imaging with any questions regarding Web Fonts:  http://www.monotype.com/
*/

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNextW05-Light.woff2') format('woff2');
  font-weight: $font-weight-light;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNextW05-Regular.woff2') format('woff2');
  font-weight: $font-weight-normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNextW05-Medium.woff2') format('woff2');
  font-weight: $font-weight-medium;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNextW05-Demi.woff2') format('woff2');
  font-weight: $font-weight-demi;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNextW05-Bold.woff2') format('woff2');
  font-weight: $font-weight-bold;
  font-display: swap;
}
