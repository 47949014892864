.headline-text {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;

  &__intro {
    text-align: center;

    &--left {
      text-align: left;
    }

    h2.intro {
      font-size: 17px;
      line-height: 29px;
      font-weight: 400;
      margin-bottom: 1rem;

      @include media-breakpoint-up(sm) {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  &__text {
    text-align: left;

    &--bold {
      font-weight: $font-weight-demi;
      font-size: 17px;
      line-height: 29px;

      @include media-breakpoint-up(sm) {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}
