$lg-offset: 44px;
$lg-overlap: 68px;
$xl-offset: 135px;
$xl-overlap: 85px;

.top-topics {
  background-color: $c_white;

  @include media-breakpoint-down(sm) {
    padding: 30px 0;
  }

  &__row {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: -10px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image {
    @include aspect-ratio(16, 9);
    background: var(--iron);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @include media-breakpoint-only(xs) {
      height: 180px;
    }

    @include media-breakpoint-between(sm, md) {
      height: 430px;
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      // 50% + overlap
      max-width: calc(50% + #{$lg-overlap});
    }

    @include media-breakpoint-up(xl) {
      // 50% + overlap
      max-width: calc(50% + #{$xl-overlap});
    }
  }

  &__info {
    flex: 1 1 auto; // override col-lg-6;
    z-index: 10;
    padding: 20px;
    color: $c_slate;

    @include media-breakpoint-only(xs) {
      margin: 0;
      padding: 20px 15px 40px;
      flex: none;
    }

    @include media-breakpoint-between(sm, md) {
      margin: 0 33px;
    }

    @include media-breakpoint-only(sm) {
      text-align: left;
      padding: 30px 0 31px;
    }

    @include media-breakpoint-only(md) {
      text-align: left;
      padding: 30px 0 50px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 50px;
      margin-bottom: 50px;
      padding: 56px 70px 58px 90px;
    }

    @include media-breakpoint-up(xl) {
      padding: 75px 145px 83px 115px;
    }

    .hdl {
      margin-bottom: 20px;

      @include media-breakpoint-between(xs, sm) {
        margin-bottom: 10px;
      }

      @include media-breakpoint-only(md) {
        margin-bottom: 9px;
      }
    }

    p {
      margin-bottom: 29px;

      @include media-breakpoint-only(xs) {
        margin-bottom: 14px;
      }

      @include media-breakpoint-between(sm, md) {
        margin-bottom: 22px;
      }

      @include media-breakpoint-only(lg) {
        margin-bottom: 28px;
      }
    }

    .btn {
      @include media-breakpoint-only(xs) {
        display: block;
        margin: 0 auto;
        max-width: 175px;
      }

      @include media-breakpoint-only(sm) {
        margin-bottom: 20px;
      }
    }
  }

  //Styles for even rows, picture to the left
  .even {
    flex-direction: row;
    .top-topics__image {
      @include media-breakpoint-up(lg) {
        left: auto;
        right: 0;
      }
    }

    .top-topics__info {
      @include media-breakpoint-up(lg) {
        padding: 52px 42px 61px 42px;
        max-width: 45%;
      }

      @include media-breakpoint-up(xl) {
        padding: 65px 55px 91px 135px;
        transform: translateY(10px);
      }
    }
  }
}
