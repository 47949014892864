.teaser-slider {
  @include media-breakpoint-up(lg) {
    overflow: hidden;
  }

  &__image__container {
    @include aspect-ratio(16, 9);
  }

  &__image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .headline {
    padding-bottom: 25px;

    @include media-breakpoint-up(sm) {
      padding-bottom: 40px;
    }

    @include media-breakpoint-up(xl) {
      padding-bottom: 50px;
    }
  }

  .container,
  .teaser-swiper-container {
    position: relative;
  }

  .teaser-swiper-container {
    overflow: hidden;
  }

  .swiper-slide {
    height: auto;
    margin-bottom: 30px;
  }

  .swiper-button-disabled {
    opacity: 0;
  }

  .swiper-button-prev {
    left: -50px;
  }

  .swiper-button-next {
    right: -50px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;
    @include media-breakpoint-down(lg) {
      display: none;
    }

    @media (min-width: $xxl-breakpoint) {
      margin: -100px 0;
    }
  }

  .pagination-wrapper {
    background-color: transparent;
    position: initial;
    transform: none;
    width: auto !important;

    @include media-breakpoint-down(xs) {
      height: auto;
    }
  }

  .swiper-pagination {
    padding-bottom: 25px;

    &-bullet:hover::before {
      background-color: rgba(0, 0, 0, 0.1);
    }

    @include media-breakpoint-up(sm) {
      padding-bottom: 35px;
    }

    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
      row-gap: 15px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: transparent;
    border-radius: 0;
    border: 0;

    &-img-top {
      border-radius: 0;
    }

    &-body {
      background: $c_white;
      margin: 0 20px;
      padding: 20px 30px;
      transform: translateY(-40px);
      flex: 1 0 auto;
      text-decoration: none;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: $c_bluebayoux;
      }
    }

    .link,
    .btn {
      margin-top: 20px;
      align-items: baseline;
      justify-content: center;
    }
  }

  &.section--bglight .content-body {
    background: $c_concrete;
  }
}
