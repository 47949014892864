.swiper-button {
  &-next,
  &-prev {
    width: 50px;
    height: 50px;
    background: none;
    margin: 0;
    transform: translateY(-50%);

    .icon {
      color: var(--primary);
      width: 50px;
      height: 50px;
    }
  }

  &-prev {
    .icon {
      transform: rotate(180deg);
    }
  }

  &-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
}
