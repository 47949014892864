.teaser-gallery-imagestripe {
  position: relative;
  overflow: hidden;

  // TEXT + THUMBS
  .teaser-gallery__thumbs-container {
    position: relative;
    top: -35px;
    display: flex;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      top: -154px;
      margin-bottom: -154px;
    }
  }

  // TEXT
  .teaser-gallery__text {
    z-index: 100;

    @include media-breakpoint-up(lg) {
      position: relative;
      width: 376px;
      margin: 0;
    }

    @include media-breakpoint-up(xl) {
      width: 470px;
    }

    .swiper-counter {
      top: 0;
      right: 33px;

      @include media-breakpoint-up(lg) {
        right: 0;
      }
    }

    .content-body--thumbs {
      transform: translateY(34px);
      margin-bottom: 35px;
      background-color: $c_concrete;

      @include media-breakpoint-up(lg) {
        width: 100%;
        transform: translateY(35px);
      }
    }
  }

  // THUMBS
  .teaser-gallery__thumbs {
    display: none;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 70px;
      left: calc(#{$grid-gutter-width / 2} + 376px);
      display: block;
      overflow: hidden;
    }

    @include media-breakpoint-up(xl) {
      left: calc(#{$grid-gutter-width / 2} + 470px);
    }

    .swiper-slide {
      width: 300px;
    }
  }

  .hidden {
    height: 0;
    visibility: hidden;
  }

  // PAGINATION
  .swiper-pagination {
    position: absolute;
    bottom: -40px;
    width: 100%;
    text-align: center;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &-bullet {
      @include media-breakpoint-up(lg) {
        border-color: $c_white;
      }

      &-active {
        @include media-breakpoint-up(lg) {
          background-color: $c_white;
        }
      }
    }
  }
}
