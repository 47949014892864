.disclaimer-text {
  // padding-top: 0;

  p {
    margin-bottom: 0;

    a {
      display: inline;
    }
  }
}
