.link {
  display: flex;
  align-items: center;
  color: var(--primary_alt);
  font-weight: $font-weight-demi;
  background: transparent;
  border: 0;

  > .icon {
    color: inherit;
    width: 10px;
    height: 10px;
    margin-right: 6px;
    flex-shrink: 0;

    &--lg {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    color: var(--hover_alt);
    text-decoration: none;
  }

  &:focus {
    color: var(--focus_alt);
    outline: none;
  }

  &--inline {
    display: inline;
    font-family: inherit;
    font-size: inherit;
    text-decoration: underline;
  }

  &--white {
    color: $c_white;

    &:hover,
    &:focus {
      color: $c_white;
    }
  }
}
