.contact-teaser {
  &__wrapper-link {
    text-decoration: none !important;
  }
  &__row {
    background-color: $c_concrete;
    padding: 30px 25px;
    margin: 0;

    @include media-breakpoint-up(md) {
      padding: 60px 80px;
    }

    @include media-breakpoint-up(lg) {
      padding: 60px 80px;
    }

    @include media-breakpoint-up(xl) {
      padding: 60px 80px;
    }
  }

  .hdl {
    text-align: center;
    color: $c_slate;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  .contact-teaser__link {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 313px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
    }

    .icon-text {
      display: block;
      padding-top: 20px;
      padding-bottom: 10px;

      &:hover {
        .icon {
          color: var(--hover_alt);
        }
      }

      &:active,
      &:focus {
        .icon {
          color: var(--focus_alt);
        }
      }
    }
  }
}
