.background-image {
  text-align: center;

  &__container {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__content {
    position: relative;
    padding: 40px 14px;

    @include media-breakpoint-up(sm) {
      padding: 50px 33px;
    }

    @include media-breakpoint-up(lg) {
      padding: 60px 0;
    }

    @include media-breakpoint-up(xl) {
      padding: 80px 0;
    }

    .hdl {
      padding-bottom: 40px;

      @include media-breakpoint-up(md) {
        padding-bottom: 50px;
      }

      @include media-breakpoint-up(lg) {
        padding-bottom: 60px;
      }

      @include media-breakpoint-up(xl) {
        padding-bottom: 80px;
      }
    }

    .intro {
      font-weight: $font-weight-medium;
      padding-bottom: 10px;

      @include media-breakpoint-up(xl) {
        padding-bottom: 15px;
      }
    }

    p {
      padding-bottom: 30px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 40px;
      }

      @include media-breakpoint-up(xl) {
        padding-bottom: 50px;
      }
    }
  }

  &.theme--light {
    .background-image__overlay {
      background: $c_white;
      opacity: 0.5;
    }
  }

  &.theme--dark {
    color: $c_white;

    .background-image__overlay {
      background: $c_black;
      opacity: 0.5;
    }

    .btn {
      background-color: $c_white;
      border-color: $c_white;
      color: $c_slate;

      &:active,
      &:focus {
        background-color: $c_white;
        border-color: $c_white;
        color: $c_slate;
      }
    }
  }
}
