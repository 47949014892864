.intro {
  &--center {
    text-align: center;
  }

  .col-xs-12 :last-child {
    margin-bottom: 0;
  }

  &:has(+ .section.__noTxt) {
    padding-bottom: 0px !important;
  }
}
