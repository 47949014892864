.image-slider-text {
  background-color: #f3f3f3;

  .swiper-slide {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  .swipper-wrapper {
    position: relative;
  }

  &__gradient {
    position: absolute;
    height: 100%;
    width: 220px;
    top: 0;
    z-index: 5;
    opacity: 0.5;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &--left {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      left: 0;
    }
    &--right {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      right: 0;
    }
  }

  &__image,
  &__content {
    width: 50%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__image {
    background-position: 50%;
    background-repeat: no-repeat;

    @include media-breakpoint-down(sm) {
      position: relative;
    }

    @include media-breakpoint-only(xs) {
      height: 250px;
    }

    @include media-breakpoint-only(sm) {
      height: 432px;
    }
  }

  &__content {
    background-color: #f3f3f3;
    height: 100%;

    .image-slider-text__content-wrap {
      padding: 71px 140px 69px 115px;

      @include media-breakpoint-only(xs) {
        padding: 30px 10px 35px 14px
      }

      @include media-breakpoint-only(sm) {
        padding: 29px 30px 41px 33px;
      }

      @include media-breakpoint-only(md) {
        padding: 50px 30px 53px 93px;
      }
    }

    h2 {
      @include media-breakpoint-only(sm) {
        margin-bottom: 16px;
      }
    }

    p {
      margin-bottom: 17px;

      @include media-breakpoint-only(xs) {
        margin-bottom: 15px;
      }

      @include media-breakpoint-only(sm) {
        margin-bottom: 16px;
      }

      strong {
        text-transform: uppercase;
        font-weight: 700;
      }
    }

    a.link {
      margin-bottom: 40px;

      @include media-breakpoint-only(xs) {
        margin-bottom: 31px;
      }

      @include media-breakpoint-only(sm) {
        margin-bottom: 43px;
      }
    }

    .btn {
      width: 200px;
      min-height: 47px;

      @include media-breakpoint-down(sm) {
        margin: 0 auto;
        display: block;
      }
    }
  }

  .swiper-button-next svg,
  .swiper-button-prev svg {
    display: none;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 220px;
    height: 100%;
    z-index: 99999;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .swiper-button-next {
    right: 0;
    cursor: url('../images/arrow-right-white.svg'), auto;
  }
  .swiper-button-prev {
    left: 0;
    cursor: url('../images/arrow-left-white.svg'), auto;
  }

  .pagination-wrapper {
    left: 25%;
    @include media-breakpoint-down(sm) {
      left: 50%;
      top: 382px;
    }

    @include media-breakpoint-down(xs) {
      width: 100% !important;
      border-radius: 0;
      left: 0;
      top: 209px;
      transform: none;
    }
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      border-color: $c_white;
      background-color: transparent;

    }

    .swiper-pagination-bullet-active {
      background-color: $c_white;
      border-color: $c_white;
    }
  }
}
