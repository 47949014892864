.image-banner, .video-banner {
  text-align: center;

  &__image, &__video {
    width: 100%;
    line-height: 0;

    @include media-breakpoint-up(lg) {
      min-height: 79px;
    }

    @include media-breakpoint-up(xl) {
      min-height: 100px;
    }
  }

  &__video {
    video {
      width: 100%;
    }

    .play-pause-wrapper {
      transform: translateY(-155px);
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        display: none;
      }

      .play-icon, .pause-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        border: 2px solid rgba(0,0,0, 0.2);
        height: 50px;
        width: 50px;
        margin-right: -1.25%; // cancel out the bootstrap padding on the parent element

        &:hover {
          border: 4px solid rgba(0,0,0, 0.2);
          cursor: pointer;
        }

        &:focus-visible {
          border: 4px solid rgba(0,0,0, 0.2);
          outline: none;
        }

        .icon {
          height: 12px;
          width: 9.17px;
        }
        }
    }
  }

  &__info .container,
  &__info.container {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    color: $c_slate;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $c_concrete;

    @include media-breakpoint-up(md) {
      padding: 35px 30px;
    }

    @include media-breakpoint-up(lg) {
      transform: translateY(-79px);
      padding-top: 40px;
      padding-bottom: 40px;
      margin-bottom: -79px;
    }

    @include media-breakpoint-up(xl) {
      transform: translateY(-100px);
      margin-bottom: -100px;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    p {
      margin-bottom: 30px;

      @include media-breakpoint-up(md) {
        margin-bottom: 26px;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }

  &__info &__info__button {
    height: 100%;
    margin: auto;

    @include media-breakpoint-up(lg) {
      text-align: center;
    }
  }
}
