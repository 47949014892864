.product-overview {
  &__item {
    position: relative;

    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
  }

  &__icon {
    position: absolute;
    top: 5px;
    right: 20px;
    background-color: $c_slate;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(xs) {
      right: 10px;
    }

    svg {
      width: 7.5px;
      height: 7.5px;
      color: $c_white;
    }
  }

  &__image {
    border: 1px solid rgba($c_slate, 0.25);
    padding: 5px;
    margin: 0 15px;
    border-radius: 5px;
    max-width: 170px;

    @include media-breakpoint-down(xs) {
      margin: 0 4px;
      max-width: 142px;
    }
  }

  &__title {
    text-align: center;
    font-weight: $font-weight-demi;
    font-size: 13px;
  }
}
