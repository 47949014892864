.image-accordion-vertical {
  padding: 0;
  overflow: hidden;

  .js-accordion {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }

  &__figure {
    // width: 20%;
    // overflow: hidden;
    margin: 0;
    height: 810px;
    flex-grow: 0.2;
    cursor: pointer;
    transition: all 300ms;

    @include media-breakpoint-only(md) {
      height: 590px;
    }

    &.active {
      flex-grow: 0.81;

      .image-accordion-vertical__content-body {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        position: absolute;
        top: 512px;
        left: 60px;
        max-width: 50%;

        @include media-breakpoint-only(md) {
          top: 314px;
          left: 28px;
          max-width: 70%;
        }
      }
    }

    &:not(:last-child) {
      border-right: 3px solid #fff;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 0;
  }

  &__caption {
    position: relative;
  }

  &__content-body {
    display: none;

    .hdl,
    .link {
      color: #fff;
    }

    .btn {
      background: rgba(255, 255, 255, 0.7);
      border: none;
      color: var(--primary);
      margin: 39px 0 0 0;
      width: 240px;

      @include media-breakpoint-only(md) {
        margin: 43px 0 0;
      }
    }
  }

  /* SLIDER FOR XS/SM */
  .image-accordion-vertical__slider-wrapper {
    .image-accordion-vertical__slider-image {
      min-height: 461px;

      @include media-breakpoint-only(xs) {
        min-height: 350px;
      }
    }

    .container {
      position: absolute;
      bottom: 65px;
      left: 24px;
      max-width: 320px;

      @include media-breakpoint-only(xs) {
        bottom: 58px;
        left: 17px;
        width: calc(100% - 70px);
      }

      .content-body {
        background: transparent;

        .hdl {
          color: #fff;
        }

        a.link {
          color: #fff;
          margin-bottom: 32px;

          @include media-breakpoint-only(xs) {
            margin-bottom: 22px;
          }
        }

        .btn {
          background-color: rgba(255, 255, 255, 0.7);
          color: var(--primary);
          border: none;
          padding: 14px 20px;

          @include media-breakpoint-only(xs) {
            padding: 9px 20px;
          }
        }
      }
    }

    .swiper-pagination {
      bottom: 43px;

      @include media-breakpoint-only(xs) {
        bottom: 41px;
      }

      .swiper-pagination-bullet {
        &-active {
          background-color: #fff;
        }
      }
    }
  }
}
