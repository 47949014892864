.table--sortable {
  th {
    position: relative;
    cursor: pointer;
    padding-right: 25px;

    .icon-sorting {
      position: absolute;
      top: 14px;
      right: 5px;
      width: 20px;
      height: 20px;
      color: rgba($c_slate, 0.5);
      fill: rgba($c_slate, 0.5);
    }

    &.asc .icon-sorting {
      color: rgba($c_slate, 0.5);
      fill: $c_slate;
    }

    &.desc .icon-sorting {
      color: $c_slate;
      fill: rgba($c_slate, 0.5);
    }
  }
}
