.btabs {
  // > .container {
  //   padding-right: 0;
  // }

  /* +++ Tab Navigation +++ */
  &__nav {
    height: 60px;
    flex-wrap: nowrap;
    overflow-x: auto; // scrollable!
    -webkit-overflow-scrolling: touch; /* For WebKit implementations, provide inertia scrolling */

    /* REMOVE SCROLLBAR */
    scrollbar-width: none; /* Firefox 64+ */
    /* Remove the default scrollbar for WebKit implementations */
    &::-webkit-scrollbar {
      display: none;
    }

    .nav-item {
      white-space: nowrap;
      background-color: $c_iron;
      height: 50px;
    }

    .nav-link {
      padding: 10px 30px;
      height: 50px;
      line-height: 30px;
      cursor: pointer;
    }

    .active {
      position: relative;
      z-index: 10;
      color: var(--primary_contrast);
      background-color: var(--primary);
      height: 60px;
      cursor: default;

      &:hover {
        color: var(--primary_contrast);
      }
    }
  }

  /* +++ Tab Content +++ */
  &__content {
    position: relative;
    margin-top: -10px;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .tab-pane {
    display: block; // Overwrite Bootstrap s***
    width: 100%;
    flex-shrink: 0;
    transform: translateX(0);
    transition: all 500ms ease-in-out;
    background: #f3f3f3;

    &::before {
      content: '';
      z-index: 10;
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 220px;
      transition: opacity 1s;
      background: linear-gradient(to right, rgba($c_black, 0.5) 0, rgba(255, 255, 255, 0) 100%);
      pointer-events: none;
    }

    &::after {
      content: '';
      z-index: 10;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 220px;
      transition: opacity 1s;
      background: linear-gradient(to left, rgba($c_black, 0.5) 0, rgba(255, 255, 255, 0) 100%);
      pointer-events: none;
    }
  }

  .tab-content:hover {
    .tab-pane::before,
    .tab-pane::after {
      @include media-breakpoint-up(lg) {
        opacity: 1;
      }
    }

    .swiper-button {
      opacity: 1;
    }
  }

  .swiper-button {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      opacity: 0;
      outline: none;
      transition: opacity 0.5s;
    }

    .icon {
      color: #fff !important;
      vertical-align: initial;
    }
  }

  .swiper-disabled {
    visibility: hidden;
    pointer-events: none;
  }
}

/**
* Various Tab Content Styles.
* WHY NOT SEPERATE MOLECULES?!
* ============================
*/

.btabs {
  &__fullsizeimage {
    @include aspect-ratio(21, 9);
    background-size: cover;
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__text {
    margin: auto;
    max-width: 770px;
  }

  &__scrolltext {
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      max-height: 500px;
      overflow-y: auto;
    }
  }

  &__imagetextfullsize {
    > .row {
      @include media-breakpoint-up(sm) {
        min-height: 500px;
      }
    }

    .image {
      background-size: cover;

      @include media-breakpoint-down(md) {
        @include aspect-ratio(16, 9);
      }
    }

    .inner {
      padding: 20px;

      @include media-breakpoint-up(sm) {
        padding: 40px;
      }

      @include media-breakpoint-up(lg) {
        padding: 80px;
      }
    }
  }

  .tab-pane .container {
    text-align: center;
  }

  &__text {
    text-align: left;
  }
}
