.give-me-five {
  &__container {
    overflow: hidden;
  }
  &__item {
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;

    @include media-breakpoint-up(md) {
      padding-right: 30px;
      padding-left: 30px;
    }
    &:not(:last-child) {
      border-right: 2px solid $c_iron;
    }
  }

  &__link {
    &:hover {
      text-decoration: none;
    }
  }

  .icon {
    width: 37px;
    height: 37px;
    margin-bottom: 25px;
    color: var(--primary);
  }

  &__label {
    font-weight: $font-weight-demi;
  }
}
