.accordion {
  .section {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__label {
    margin-bottom: 0;
    margin-right: 15px;
  }

  .hdl-group {
    flex-direction: column;
  }

  .card,
  .card-header {
    border: 0;
  }

  .card {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  .card-header {
    background-color: transparent;
    padding: 0;

    &--dark {
      background-color: $c_concrete;
    }

    .btn-toggle {
      display: flex;
      align-items: center;
      max-width: none;
      width: 100%;
      padding: 20px 15px;

      @include media-breakpoint-up(md) {
        padding: 20px;
      }

      @include media-breakpoint-up(lg) {
        padding: 23px 30px;
      }

      .hdl-group {
        flex: 1 1 auto;
        text-align: left;
        padding-right: 15px;
      }

      .hdl {
        font-weight: $font-weight-demi;
        margin: 0;
        flex: 1 0 auto;
        align-items: flex-start;
        display: flex;
      }

      .icon {
        flex: 0 0 auto;
        margin-left: auto;
        margin-right: 0;

        &:not(.icon--transform) {
          color: var(--primary);
          fill: $c_slate;
          margin-right: 10px;

          @include media-breakpoint-up(sm) {
            margin-right: 30px;
            width: 35px;
            height: 35px;
          }
        }
      }

      &[aria-expanded='true'] .icon--transform {
        transform: rotate(180deg);
      }
    }
  }
}
