.products-tile-view {
  &__info {
    padding-top: 30px;
    @include media-breakpoint-up(md) {
      padding-top: 40px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 50px;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 60px;
    }
  }

  &__hdl {
    text-transform: uppercase;
  }
}
