.anmelde-formular-roadclub {
  .main_form {
    padding-bottom: 1.5rem;
  }

  .short-form {
    width: calc(50% - 10px);
    font-weight: 600;
  }

  /* Für das HoneyPot Schutz Bot */
  #f_honey-wrapper {
    display: none;
  }
  /* --- */

  .adress-checkbox_wrapper {
    margin: 10px;
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .adress_typ {
      font-weight: 600;
      padding-right: 1.5rem;
      display: inline-flex;
      gap: 10px;

      .formular_feedback {
        margin-top: 0;
        font-weight: 400;
      }
    }
  }

  .form-check-inline .form-check-input {
    top: 3px;
    ~ .form-check-label {
      @include media-breakpoint-down(sm) {
        display: block;
      }

      &::before,
      &::after {
        top: 3px;
      }
    }

    &:checked ~ .form-check-label::after {
      top: 9px;
    }
  }

  .double-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      gap: 0;
    }

    .form-group {
      width: 100%;

      &_land {
        font-weight: 600;
      }
    }
  }

  .formular_feedback {
    display: none;
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: $c_danger;

    &.--invalid {
      display: block;
    }
  }

  .pick_one {
    display: none;
    font-size: 0.875rem;
    font-weight: 400;
    color: $c_danger;

    &.--invalid {
      display: block;
    }
  }

  .wirtgen-products {
    font-weight: 600;

    > div {
      display: flex;
      flex-direction: column;

      .check-products {
        display: flex;
        flex-direction: column;
      }

      .form-check-inline {
        padding: 0.5rem 0;
      }
    }
  }

  .pflicht-text {
    display: flex;
    flex-flow: row wrap !important;
    gap: 10px;
    align-items: center;

    .formular_feedback {
      margin-top: 0;
      font-weight: 400;
    }
  }

  .ag_b {
    font-weight: 600;
    padding-top: 2rem;

    .formular_feedback {
      font-weight: 400;
    }

    .form-check-label div {
      display: flex;
    }
  }

  .footerText {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }

  .btn-group {
    padding-bottom: 1rem;
  }
}
