.slider {
  &-track {
    height: 3px !important;
    margin-top: -1.25px !important;
    background-color: var(--primary);

    > .slider-selection {
      background-image: none;
      background-color: var(--primary);

      &.tick-slider-selection {
        background-image: none;
        background-color: var(--primary);
      }
    }

    > .slider-track-high {
      background-image: none;
      background-color: #ecedee;
    }
  }

  &-tick {
    background-color: $c_iron;
    opacity: 1;

    &.round {
      background: none;
      border-style: solid;
      box-shadow: none;
    }
  }

  &-handle {
    box-shadow: none;
    background-image: none;
    border-color: var(--primary);
    border-width: 3px;
    background-color: $c_white;
  }

  .tooltip.in {
    opacity: 1 !important;
    transform: translateX(-50%);
    top: 15px !important;
  }

  .tooltip.top {
    bottom: 15px !important;
    top: auto !important;
    margin: 0 !important;
  }

  .tooltip-inner {
    color: var(--primary_alt);
    background-color: transparent;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-normal;
    font-size: 13px;
  }
}
