.info-popup {
  // dropdown toggle styles

  .link {
    // info icon
    .icon {
      width: 13px;
      height: 13px;
      margin-left: 10px;
      color: $c_graychateau;
    }
  }

  // dropdown styles:
  // &.dropup.show {
  // }

  .dropdown-menu.show {
    padding: 15px;
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 15px 0 rgba($c_black, 0.2);
    font-weight: $font-weight-normal;

    .dropdown-header {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      color: $c_slate;

      // icon close
      .icon {
        width: 15px;
        margin-left: 30px;
        fill: $c_slate;
        cursor: pointer;
      }
    }
  }

  // remove angle icon from dropdown-toggle-link
  .dropdown-toggle::after {
    display: none;
  }
}
