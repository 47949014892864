$max-slide-height: 700px;
$big-play-button-size: 80px;

.technologies {
  .tabs__panel {
    @include media-breakpoint-up(md) {
      @include aspect-ratio(16, 9);
    }

    @include media-breakpoint-up(md) {
      max-height: $max-slide-height;
    }
  }

  .tabs__content-container {
    @include media-breakpoint-up(md) {
      max-height: $max-slide-height;
      overflow: hidden;
    }
  }

  &__container {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(md) {
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1000;
    }

    .btn {
      min-width: 200px;
      @include media-breakpoint-up(md) {
        position: absolute;
        bottom: 100px;
        right: 20px;
      }
    }
  }

  // technologies E, F
  &__row--no-margin {
    margin: 0;
    height: 100%;
  }

  // technologies F
  &__row--image-text {
    height: 100%;
    padding: 30px 14px 40px 14px;

    @include media-breakpoint-up(md) {
      padding: 40px 33px 100px 33px;
    }

    @include media-breakpoint-up(lg) {
      padding: 50px 44px 140px 44px;
    }

    @include media-breakpoint-up(xl) {
      padding: 60px 135px 140px 135px;
    }

    // technologies F text
    .technologies__content--2-column {
      padding: 0;

      @include media-breakpoint-up(md) {
        padding-left: 48px;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 74px;
      }

      @include media-breakpoint-up(xl) {
        padding: 0 100px;
      }
    }
  }

  // technologies C, D, E, F
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;

    @include media-breakpoint-up(md) {
      max-height: $max-slide-height;
    }

    .hdl {
      height: auto;
    }

    .technologies__text-container {
      position: relative;
      flex-grow: 0;
      margin-bottom: 30px;
      padding-bottom: 0;
      padding-right: 10px;
      overflow-y: auto;

      // text truncation
      // &::after {
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 30px;
      //   background: linear-gradient(to top, rgba(243, 243, 243, 1), rgba(243, 243, 243, 0));
      //   content: '';
      //   pointer-events: none;
      // }
    }

    .intro {
      padding-bottom: 15px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 10px;
      }

      @include media-breakpoint-up(xl) {
        padding-bottom: 30px;
      }
    }

    p {
      //padding-bottom: 25px;
    }

    .btn {
      width: 200px;
      min-height: 47px;
    }
  }

  // technologies C, D
  &__content--1-column {
    padding: 30px 14px;

    @include media-breakpoint-up(sm) {
      padding: 30px 33px;
    }

    @include media-breakpoint-up(md) {
      padding: 40px 124px 110px 124px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 770px;
      padding: 50px 0 110px 0;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-up(xl) {
      padding: 60px 0 145px 0;
    }
  }

  // technologies E
  &__content--2-column {
    padding: 20px 14px 40px 14px;

    @include media-breakpoint-up(md) {
      padding: 20px 33px 100px 69px;
    }

    @include media-breakpoint-up(lg) {
      padding: 78px 44px 133px 92px;
    }

    @include media-breakpoint-up(xl) {
      padding: 128px 235px 133px 115px;
    }
  }

  // technologies A
  &__image--full__container {
    @include aspect-ratio(16, 9);

    .technologies__image--full {
      // background image settings
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  // technologies E
  &__bgimage-2-column__container {
    @include aspect-ratio(16, 9);

    .technologies__bgimage-2-column {
      // background image settings
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  // technologies F
  .technologies__image-2-column {
    padding: 0;
  }

  // technologies B video
  // as technologies content area has max-height 700px and it overrides 16:9 aspect-ratio:

  // to make play button vertically centered on larger screens
  .video__big-play-button {
    .icon-videoicon {
      height: $big-play-button-size;
      width: $big-play-button-size;
    }

    $tabs-breakpoint: ($max-slide-height * 16) / 9;

    @media (min-width: $tabs-breakpoint) {
      /* unset bottom property*/
      bottom: inherit !important;
      /* vertically center button*/
      top: ($max-slide-height - $big-play-button-size) / 2;
    }
  }

  .technologies__overlay {
    display: none;
  }

  .technologies__theme--dark {
    .technologies__overlay {
      display: block;
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, rgba($c_black, 0.75) 0%, rgba($c_black, 0) 100%);
    }
    .btn {
      background-color: $c_white;
      border-color: $c_white;
      color: $c_slate;

      &:not(:disabled):not(.disabled):focus,
      &:not(:disabled):not(.disabled):active {
        background-color: $c_white;
        border-color: $c_white;
        color: $c_slate;
      }
    }
  }
}
