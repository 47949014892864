.closeico {
  display: inline-flex;
  width: 40px;
  height: 40px;
  border: 6px solid $c_iron;
  background-color: var(--primary);
  border-radius: 50%;

  .icon {
    width: 10px;
    height: 10px;
    color: $c_white;
    transform: rotate(45deg);
    margin: auto;
  }

  // &:hover {
  //   background-color: $c_oxfordblue;
  // }
}
