.image-underlaid-text-overlay {
  .image-underlaid-text__image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 810px;

    @include media-breakpoint-only(xs) {
      height: 250px;
    }

    @include media-breakpoint-only(sm) {
      height: 432px;
    }

    @include media-breakpoint-only(md) {
      height: 700px;
    }
  }

  .content-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;

    @include media-breakpoint-down(sm) {
      position: initial;
      display: block;
    }

    .content-body {
      position: relative;
      width: 660px !important;
      padding: 40px 50px 41px 0 !important;

      @include media-breakpoint-only(xs) {
        width: 100% !important;
        padding: 20px 14px 0 !important;
      }

      @include media-breakpoint-only(sm) {
        width: 100% !important;
        padding: 30px 33px 0 !important;
      }

      @include media-breakpoint-only(md) {
        width: 660px !important;
        padding: 40px 40px 41px 215px !important;
      }

      &:before {
        content: '';
        position: absolute;
        background: rgba(255, 255, 255, 0.7);
        width: 50vw;
        max-width: 960px;
        height: 100%;
        top: 0;
        right: 0;
        z-index: -1;
        /*
        @include media-breakpoint-only(lg) {
          left: calc((50vw - (935px / 2)) * -1);
        }*/

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .hdl {
        /*
        @include media-breakpoint-only(xs) { color: red; }
        @include media-breakpoint-only(sm) { color: green; }
        @include media-breakpoint-only(md) { color: blue; }
        @include media-breakpoint-only(lg) { color: yellow; }
        @include media-breakpoint-only(xl) { color: purple; }
        */

        @include media-breakpoint-only(sm) {
          margin-bottom: 15px;
        }
      }

      p {
        strong {
          text-transform: uppercase;
        }
      }
    }

    .button-body {
      @include media-breakpoint-up(md) {
        min-width: 220px;
      }
    }

    .btn {
      @include media-breakpoint-only(xs) {
        display: block;
        margin: 0 auto;
        width: 186px;
        margin-top: 32px;
      }

      @include media-breakpoint-only(sm) {
        display: block;
        margin: 0 auto;
        width: 186px;
        margin-top: 42px;
      }

      @include media-breakpoint-up(md) {
        position: relative;
        background-color: rgba(255, 255, 255, 0.7);
        border: none;
        color: #41535d;
        margin-left: 133px;
        top: -5px;
        width: 240px;
      }

      @include media-breakpoint-only(md) {
        margin-left: 27px;
        top: -3px;
      }
    }

    &.image-underlaid-text-overlay__textbox--right {
      flex-direction: row;

      .content-body {
        padding: 40px 0 41px 60px !important;

        @include media-breakpoint-only(xs) {
          padding: 21px 0 0 14px !important;
        }

        @include media-breakpoint-only(sm) {
          padding: 30px 33px 11px !important;
        }

        @include media-breakpoint-only(md) {
          padding: 39px 153px 38px 51px !important;
        }

        &:before {
          left: 0;
        }
      }

      .button-body {
        @include media-breakpoint-up(md) {
          min-width: 285px;
        }
      }

      .btn {
        @include media-breakpoint-up(lg) {
          margin-right: 134px;
        }
      }
    }
  }

  .swiper-slide {
    overflow-x: hidden;
  }

  .pagination-wrapper {
    @include media-breakpoint-only(sm) {
      top: 382px;
    }

    @include media-breakpoint-down(xs) {
      width: 100% !important;
      border-radius: 0;
      top: 209px;
    }
  }

  .swiper-pagination {
    &-bullet {
      border-color: $c_white !important;

      &-active {
        background-color: $c_white !important;
      }
    }
  }
}
