$opacityOnHover: 1;
$opacityDisabled: 0.6;

%btn-defaults {
  border-radius: 50px;
  border: currentColor 2px solid;
  white-space: normal;
}

.btn {
  transition: 0.5s;
  height: auto;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;

  font-size: 14px;
  font-weight: $font-weight-demi;
  padding: 9px 20px;

  @include media-breakpoint-up(sm) {
    min-width: 200px;
    font-size: 15px;
  }

  @include media-breakpoint-up(lg) {
    padding: 12px 20px;
  }

  &:focus {
    box-shadow: none;
  }

  &-primary {
    @extend %btn-defaults;
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--primary_contrast);
    opacity: 1;

    &:hover {
      background-color: var(--hover);
      border-color: var(--hover);
      color: var(--primary_contrast);
      opacity: $opacityOnHover;
    }

    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active {
      background-color: var(--focus);
      border-color: var(--focus);
      color: var(--primary_contrast);
      opacity: $opacityOnHover;
    }

    &[disabled] {
      background-color: var(--disabled);
      border-color: var(--disabled);
      color: var(--primary_contrast);
      opacity: $opacityDisabled;
      cursor: default;
    }
  }

  &-secondary {
    @extend %btn-defaults;
    background-color: transparent;
    border-color: var(--primary);
    color: var(--primary_alt);
    opacity: 1;

    &:hover {
      border-color: var(--primary);
      background-color: transparent;
      color: var(--hover);
      opacity: $opacityOnHover;
    }

    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active {
      background-color: transparent;
      border-color: var(--focus);
      color: var(--focus);
      opacity: $opacityOnHover;
    }

    &[disabled] {
      background-color: transparent;
      border-color: var(--disabled);
      color: var(--primary_alt);
      opacity: $opacityDisabled;
    }

    .icon {
      color: var(--primary_alt);
    }
  }

  &-clean {
    min-width: 0;
    padding: 0;
    color: var(--primary_alt);

    &:hover {
      color: var(--primary_alt);
      opacity: $opacityOnHover;
    }

    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active {
      color: var(--focus);
      opacity: $opacityOnHover;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
      color: var(--primary_contrast);
      opacity: $opacityDisabled;
    }
  }

  &-icon {
    &--md {
      @include media-breakpoint-down(md) {
        padding: 8px;
      }
    }
  }

  &-select {
    background-color: $c_white;
    // margin: 10px 0 10px 0;
  }

  &-toggle {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
