.image-chip-tiles {
  &__container {
    display: grid;
    row-gap: 20px;
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 18px;
      row-gap: 30px;
    }
    @include media-breakpoint-up(md) {
      column-gap: 24px;
    }
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 30px;
    }
  }
}
