.changeOrder {
  &__drag-elements {
    margin-top: 30px;

    &__item {
      border: 1px solid rgba($c_slate, 0.25);
      border-radius: 5px;
      padding: 5px 16px;
      margin: 10px 0;
      color: var(--primary);
      font-weight: $font-weight-bold;

      &:hover {
        cursor: move;
      }

      svg {
        width: 16px;
        height: 16px;
      }

      svg,
      img {
        margin-right: 10px;
      }
    }
  }

  .over {
    border: 1px dashed var(--primary);
  }
}
