.component-info {
  .row {
    border-top: 2px solid $c_graychateau;
    padding-top: 15px;
    padding-bottom: 15px;

    @include media-breakpoint-up(sm) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  [class^='col'] + [class^='col'] {
    @include media-breakpoint-down(xs) {
      margin-top: 24px;
    }
  }

  &__link .icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
}
