.specs {
  .spec__col {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      margin-bottom: 18px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 30px;
    }
  }

  .spec {
    position: relative;
    height: 100%;
    border: 0;
    border-radius: 0;
    background-color: $c_concrete;

    @include media-breakpoint-only(xs) {
      flex-direction: row;
      padding-right: 40px;
    }

    @include media-breakpoint-up(sm) {
      padding: 30px;
      text-align: center;
    }
  }

  &__icon-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 10%;
    width: 60px;
    margin: auto 0;
    padding: 10px;

    @include media-breakpoint-up(sm) {
      min-height: 56px;
      margin: 14px auto;
      padding: 0;
    }

    // specs icon
    .specs__img {
      width: 40px;
      height: 40px;

      @include media-breakpoint-up(sm) {
        width: 56px;
        height: 56px;
      }

      .inline-svg {
        height: 100%;

        .icon {
          width: 100%;
          height: 100%;
          fill: $c_slate;
          color: var(--primary);
        }
      }
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    //height: 100%;
    padding: 0 10px;

    @include media-breakpoint-only(xs) {
      margin: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .hdl {
      @include media-breakpoint-only(xs) {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    @include media-breakpoint-up(sm) {
      position: relative;
      flex-grow: 1;
      max-height: 135px;
      margin: 15px auto;
      overflow: hidden;
    }

    @include media-breakpoint-up(md) {
      max-height: 155px;
    }
  }

  &__subtitle {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-up(sm) {
      justify-content: center;
      height: 25px;
    }

    p:empty {
      display: none;

      +.dropup {
        display: none;
      }
    }
  }

  .info-popup {
    @include media-breakpoint-only(xs) {
      position: absolute;
      top: 11px;
      right: 11px;
    }

    .link {
      display: block;
    }

    &.show {
      position: initial;

      .link {
        @include media-breakpoint-only(xs) {
          visibility: hidden;
        }
      }
    }
  }
}
