.assembly-info {
  &__headline-wrapper {
    display: flex;
    align-items: top;
  }

  &__icon-wrapper {
    margin-right: 10px;
  }

  &__icon {
    width: 32px;
    height: 32px;
    @include media-breakpoint-up(sm) {
      width: 40px;
      height: 40px;
    }
    @include media-breakpoint-up(md) {
      width: 44px;
      height: 44px;
    }
    @include media-breakpoint-up(lg) {
      width: 52px;
      height: 52px;
    }
  }
}
