// Add branding colors to bootstrap dropdown component

.dropdown-item.active,
.dropdown-item:active {
  background-color: $c_iron;
  color: $c_slate;
}

.dropdown-menu {
  z-index: $zindex-popover;
  border: none;
  font-size: $font-size-base;
}

.dropdown-toggle::after {
  border: none !important;
  width: 10px;
  color: var(--primary);
  content: url('symbols.svg#icon-down') !important;
  transition: transform 200ms ease;
}

.dropdown.show {
  .dropdown-toggle::after {
    transform: rotate(180deg);
  }
}
