// variables
$popupWidth: 776px;

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.geodetection-popup {
  width: $popupWidth;
  padding: 30px;
  border: none;
  animation: fadeIn 200ms;

  @include media-breakpoint-only(xs) {
    padding: 14px;
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__header {
    text-align: center;
    margin-bottom: 20px;

    @include media-breakpoint-only(xs) {
      text-align: left;
    }
  }

  &__body {
    display: flex;
    gap: 24px;

    @include media-breakpoint-only(xs) {
      flex-direction: column;
      gap: 20px;
    }

    @include media-breakpoint-only(sm) {
      gap: 18px;
    }
  }

  &__body-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    text-align: center;
    border: 1px solid #D9DDDF;
    padding: 30px;

    p {
      margin-bottom: 30px;
    }
  }

  &__switch-to-homepage {
    display: none;
    margin-bottom: 30px;
    text-align: center;
  }

  &__btn-continue,
  &__btn-switch {
    display: block;
    margin: 0 auto 0;
  }

  &__footer {
    margin-top: 20px;
    font-size: 15px;


    .geodetection-popup__content-not-available {
      display: none;
      color: #41535D;
      margin: 0 0 20px;
      text-align: center;

      @include media-breakpoint-only(xs) {
        text-align: left;
      }
    }

    button {
      margin: 0 auto;
      align-items: flex-start;

      @include media-breakpoint-only(xs) {
        margin: 0;
      }

      &::before {
        color: #6c757d;
        width: 10px;
        height: 10px;
        content: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='currentColor' d='M10.056 6.268L14.932 1.5l23.011 22.499-23.011 22.499-4.876-4.768L28.19 23.999z'/%3E%3C/svg%3E");
        margin-right: 6px;
      }
    }
  }

  &.geodetection-popup--content-not-available {
    .geodetection-popup__switch-to-homepage {
      display: block;
    }

    .geodetection-popup__body-column p {
      margin-bottom: 15px;
    }

    .geodetection-popup__content-not-available {
      display: block;
    }

  }
}
