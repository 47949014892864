.products-list-view {
  &__img {
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__hdl {
    text-transform: uppercase;
  }

  .table-left {
    padding-top: 40px;
    padding-bottom: 10px;
  }

  .downloads {
    padding-bottom: 40px;
  }
}
