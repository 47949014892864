@import './pam-filter-items.scss';

.pam-filter {
  margin-top: 32px;
  margin-bottom: 32px;
  z-index: $zindex-fixed;
  position: relative;

  @include media-breakpoint-down(lg) {
    z-index: $zindex-modal;
  }

  @include media-breakpoint-down(sm) {
    margin-left: -10px;
    margin-right: -10px;
  }

  &__resets {
    background-color: $c_white;
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 0;

    &-container {
      display: flex;
      flex-direction: row;
      align-items: self-start;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        padding: 0 10px;
        margin-bottom: 20px;
      }
    }

    &-label {
      flex: 1 0 20%;
      padding: 17px 0;
      text-align: right;
      color: var(--primary);
      font-size: 14px;
      width: 100%;
      font-weight: 600;

      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }

    &-chips {
      flex: 1 0 54%;
      padding: 15px 15px 10px;

      @include media-breakpoint-down(sm) {
        padding-top: 0;
        padding-left: 0;
      }
    }

    &-reset-all {
      flex: 1 0 26%;
      margin-top: 10px;

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__bar {
    display: none;
    background-color: $c_white;
    border-top: 1px solid $c_iron;
    border-bottom: 1px solid $c_iron;
    box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.75);
    z-index: $zindex-sticky;
    margin-top: 0 !important;

    @include media-breakpoint-down(sm) {
      padding-left: -10px;
      padding-right: -10px;
      position: sticky;
      top: -1px;
      z-index: 9000;
    }

    .btn {
      padding: 16px 0;
      width: 100%;
      text-align: start;
    }
  }

  &__opener {
    border-top: 0;
    border-bottom: 0;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.75);
    @include media-breakpoint-down(sm) {
      margin-left: -10px;
      margin-right: -10px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__wrapper {
    background-color: $c_white;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
  }

  &__content {
    background-color: transparent;
    transform: translateY(0);

    &.open {
      position: fixed;
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translateY(-100%);
      z-index: $zindex-modal;
    }

    &.open {
      transform: translateY(0);

      .pam-filter__bar {
        display: flex;
        background-color: $c_white;
        border-top: $c_iron;
        border-bottom: $c_iron;
      }

      .pam-filter__resets {
        margin-top: 0; // fix the bug in sticky-kit for chrome
      }
    }
  }

  &__form {
    padding-bottom: 0;

    @include media-breakpoint-down(sm) {
      padding-bottom: 30px;
    }
  }

  &__item {
    display: flex;
  }

  &__action {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    //margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      justify-content: center;
    }

    p {
      margin-bottom: 0;
      margin-right: 30px;
    }

    .link .icon {
      width: 20px;
      height: 20px;
    }
  }

  .card__body--slider {
    .tooltip-inner {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.show .pam-filter__bar {
  display: none;
}

.section--bglight + .pam-filter__opener + .pam-filter {
  margin-top: 0;
}

.noscroll {
  overflow: hidden;
}

.is_stuck {
  transform: translate3d(0, 0, 0); //fix sticky-kit bug
}
