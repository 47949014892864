
/**
 * Styles for both the select-picker and checkbox-set field
 */
@mixin collapsible-list {
  position: relative;
  width: 100%;
  margin-bottom: 15px;

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
    border: 2px solid #fff;
    cursor: pointer;
    padding: 9px 20px;
    transition: 0.5s;

    &-label {
      color: #41535d;
      text-align: left;
    }

    &.show {
      border: 2px solid var(--primary);
      box-shadow: none;
    }

    &:after {
      border: none !important;
      width: 10px;
      color: var(--primary);
      content: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='currentColor' d='M40.962 10.958l4.561 4.561-21.524 21.524L2.475 15.519l4.561-4.561L23.998 27.92z'/%3E%3C/svg%3E") !important;
      transition: transform 200ms ease;
    }

    &.show:after {
      transform: rotate(180deg);
    }
  }

  &__dropdown-menu {
    position: absolute;
    width: 100%;
    background-color: #fff;
    border: 2px solid var(--primary);
    padding: 0.5rem 0;
    margin-top: 2px;
    z-index: 1000;

    &-items {
      list-style-type: none;
      margin: 0;
      padding: 0;

      &-item {
        cursor: pointer;
        font-size: 0.875rem;
        padding: 0.25rem 1.5rem;

        &.selected,
        &:hover {
          color: #16181b;
          text-decoration: none;
          background-color: #e9ecef;
        }

        &.disabled {
          opacity: .55;
          pointer-events: none;
        }
      }
    }
  }
}

/**
 *
 */
@mixin checkbox-field() {
  display: flex;
  align-content: stretch;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: 15px;

  input[type="checkbox"] {
    position: relative;
    left: 15px;
    top: -4px;
    z-index: 0;
    -webkit-appearance: none;
    cursor: pointer;

    &:before {
      width: 18px;
      height: 18px;
      background-color: transparent;
      border: 2px solid var(--primary);
      display: block;
      content: "";
      margin-right: 10px;
      z-index: 5;
      position: relative;
      left: -15px;
      top: 7px;
    }

    &:checked:after {
      content: "";
      position: absolute;
      opacity: 1;
      transition: opacity .3s;
      transform: rotate(-45deg);
      top: 13px;
      left: -9px;
      width: 7px;
      height: 4px;
      border-width: 2px;
      border-color: var(--primary_alt);
      border-style: none none solid solid;
    }
  }
}



/**
 * Component styles
 */
.jobfind {
  padding-bottom: 60px;

  .checkbox-field {
    @include checkbox-field();
  }

  &__header {
    text-align: center;
    margin-bottom: 40px;

    &-description {
      padding: 0 27%;

      @include media-breakpoint-only(lg) {
        padding: 0 23%;
      }

      @include media-breakpoint-only(md) {
        padding: 0 18%;
      }

      @include media-breakpoint-only(sm) {
        padding: 0 15%;
      }

      @include media-breakpoint-only(xs) {
        padding: 0;
      }
    }
  }

  &__filters {
    background-color: #f3f3f3;
    padding: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    .selectpicker {
      @include collapsible-list();
    }

    &-brands {
      .checkbox-set-field {
        @include collapsible-list();

        &.collapsible {
          .checkbox-set-field__dropdown-menu-items {
            &-item {
              &:hover { background: transparent}

              label {
                margin-bottom: 3px;
              }
            }
          }
        }

        &:not(.collapsible) {
          .checkbox-set-field__dropdown-menu-items {
            display: flex;
            flex-wrap: wrap;
            margin-top: 3px;
            border-bottom: 1px solid #D9DDDF;
            padding-bottom: 5px;

            &-item {
              padding: 0;
              width: 50%;

              &:hover { background: transparent}

              @include media-breakpoint-down(lg) {
                width: 33.3333333%
              }
            }
          }
        }
      }
    }

    &--results-text {
      margin: 20px 0 0;
      font-weight: 800;
      color: var(--primary);
    }

    &--reset {
      border: none;
      margin-top: 10px;
      background: transparent;
      color: var(--primary);

      .icon-reset {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }

  &__results {

    &-result {
      border-bottom: 1px solid #D9DDDF;
      padding: 20px 0;

      &:first-child {
        border-top: 1px solid #D9DDDF;
      }

      h3 {
        font-size: 17px;
        line-height: 27px;
        font-weight: 700;

        a {
          color: var(--primary);
        }
      }

      p {
        margin: 0;
      }

      a {
        text-decoration: none;
      }
    }
  }

  &__loadmore {
    margin: 40px 0 0;
  }
}
