.table-left {
  .table {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(lg) {
      display: table;
    }

    .icon {
      color: var(--primary);
      fill: $c_slate;
    }
  }

  .table-row {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 0;

    @include media-breakpoint-up(lg) {
      display: table-row;
    }

    &--middle {
      .table-cell {
        @include media-breakpoint-up(md) {
          vertical-align: middle;
        }
      }
    }
/*
    .checkbox {
      display: none;
    }*/
  }

  .table-cell {
    width: auto;
    padding: 12px 15px;
    // overflow: hidden;
    list-style: none;
    border-top: solid 1px $c_iron;

    @include media-breakpoint-up(lg) {
      display: table-cell;
      vertical-align: top;
      text-align: center;
      padding: 30px 10px;
      margin-left: 0;
      margin-right: 0;
    }

    .link {
      display: inline-flex;
    }

    .form-group {
      margin-bottom: 0;
      margin-left: 15px;
    }

    .col {
      font-weight: $font-weight-normal;
      font-size: 15px;

      @include media-breakpoint-up(lg) {
        align-items: center;
      }

      .d-lg-none {
        opacity: 0.5;
        font-size: 13px;
      }

      .icon {
        width: 24px;
        height: 24px;

        @include media-breakpoint-up(lg) {
          width: 32px;
          height: 32px;
          margin-bottom: 8px;
        }
      }
    }

    .btn,
    .btn:hover,
    .btn:focus {
      @include media-breakpoint-up(lg) {
        background: transparent;
        color: var(--primary_alt);
        min-width: 0;
        padding: 0;
        border: 0;
        border-radius: 0;
        margin-top: 0;
        transition: none;
      }
    }

    &--head {
      @include media-breakpoint-down(md) {
        display: none;
      }

      @include media-breakpoint-up(lg) {
        text-align: left;
        color: var(--primary_alt);
        font-weight: $font-weight-demi;
        font-size: 17px;
        min-width: 70px;
      }
    }

    &--button {
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        width: auto;
        order: 1;
        margin: 0 auto 15px auto;
        border-top: 0;
      }
    }

    &--foot {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @include media-breakpoint-down(md) {
        justify-content: flex-end;
        order: 0;
      }
    }
  }

  .table__head {
    display: none;

    @include media-breakpoint-up(lg) {
      display: table-row;
    }

    .table-cell {
      padding-top: 0;
      border-top: 0;

      &--foot {
        vertical-align: bottom;
      }

      .col {
        @include media-breakpoint-up(lg) {
          flex-grow: 1;
          flex-basis: auto;
        }
      }
    }
  }

  .table__body {
    .double-col {
      flex-flow: column-reverse;

      .col:not(.new-label) {
        span {
          padding-left: 10px;

          a {
            padding: 6px 0;
          }
        }
      }

      .new-label {
        width: fit-content;
        height: auto;
        background: var(--primary);
        color: var(--primary_contrast);
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        transform: rotate(-5deg);

        span {
          padding: 0 5px;
          letter-spacing: 1px;
        }
      }
    }

    .label-mobile {

      @include media-breakpoint-down(md) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;

        &-wrapper {
          display: flex;
          flex-direction: row;
          gap: 6px;
          grid-column: 1;
          grid-row: 1;

          &_text {
            height: 16px;

            &-text {
              width: fit-content;
              background: var(--primary);
              color: var(--primary_contrast);
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 700;
              padding: 3px 4px;
              letter-spacing: 1px;
            }
          }

          .inline-svg {
            display: inline-block !important;
            justify-self: start;
            grid-column: 1;
            grid-row: 1;

            svg {
              width: 51px;
              height: 16px;
              position: initial;
              background: $c_white;
            }
          }
        }

        &_headline {
          grid-column: 1;
          grid-row: 2;
          padding-top: 2px;
        }
      }
    }

    .col.d-lg-none.e-power  {
      display: inline-block !important;
      flex-basis: auto;

      svg {
        width: 62px;
        height: 20px;
        background: $c_concrete;
      }

      @include media-breakpoint-down(md) {
        display: none !important;
        transform: none;
      }
    }
  }

  .table__body .table-cell--head + .table-cell {
    @include media-breakpoint-down(md) {
      border-top: 0;
    }
  }

  .table-cell--foot {
    @include media-breakpoint-up(lg) {
      border: 0;
    }
  }

  .table--collapse {
    min-width: 240px;

    @include media-breakpoint-down(md) {
      display: block;

      > .table-cell {
        width: auto !important;
      }

      > .table-cell--foot {
        margin-bottom: 1em;
      }
    }
  }

  .hiddenSmall {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .table-row .toggle {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    text-align: left;
    padding: 23px 15px;
    background-color: $c_concrete;
    border: 0;
    font-weight: $font-weight-demi;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary);
    text-decoration: none;
    transition: background-color 0.1s;
    cursor: pointer;
    margin-bottom: 10px;

    &:first-of-type {
      @include media-breakpoint-up(lg) {
        display: none !important;
      }
    }

    span:not(.label-mobile-wrapper_text-text) {
      padding-right: 25px;
    }

    .icon {
      position: absolute;
      right: 15px;
      color: $c_slate;
      width: 16px;
      height: 16px;
      transform: rotate(180deg);
    }

    &[aria-expanded='true'] .icon {
      transform: rotate(0);
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .dropup {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;

    @include media-breakpoint-up(lg) {
      position: relative;
      right: auto;
      top: auto;
      transform: none;
    }

    .dropdown-toggle {
      .icon {
        width: 13px;
        height: 13px;
        color: $c_graychateau;
        margin: 0;

        @include media-breakpoint-up(lg) {
          margin: 0 0 0 10px;
        }
      }
    }

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu.show {
      min-width: 250px;
      max-height: 100vw;
      padding: 15px;
      border: 0;
      border-radius: 0;
      overflow: auto;
      box-shadow: 0 0 15px 0 rgba($c_black, 0.2);

      .dropdown__container {
        width: auto;
      }
    }

    .dropdown-header {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      color: $c_slate;

      .icon {
        cursor: pointer;
        width: 15px;
        margin-left: 30px;
        color: $c_slate;
      }
    }
  }

  &__info-popup {
    display: inline;
  }

  &__product-comparison {
    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }

    .form-group {
      margin: 0 !important;
    }
  }

  @include media-breakpoint-up(md) {
    .checkbox {
      .form-group {
        .form-check {
          display: flex;
          justify-content: center;
          padding-top: 30px;
          min-width: min-content;
          max-width: 110px;
        }
      }

      .form-check-label {
        max-width: fit-content;
        padding: 0;
        &::before {
          top: -28px !important;
          left: calc(50% - 10px) !important;
        }

        &::after {
          top: -21px !important;
          left: calc(50% - 4px) !important;
        }
      }
    }
  }
}
