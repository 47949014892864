$lineWidth: 5000px;

.line-headline {
  overflow-x: hidden;

  &__heading {
    position: relative;
    text-transform: uppercase;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: $lineWidth;
      border-bottom: 2px solid var(--primary);
      left: -2500px;
    }

    /* H1 */
    &.hdl--1::before {
      top: 26px;

      @include media-breakpoint-only(xs) {
        top: 15px;
      }

      @include media-breakpoint-only(sm) {
        top: 20px;
      }

      @include media-breakpoint-only(md) {
        top: 23px;
      }
    }

    /* H2 */
    &.hdl--2::before {
      top: 21px;

      @include media-breakpoint-only(xs) {
        top: 13px;
      }

      @include media-breakpoint-only(sm) {
        top: 19px;
      }

      @include media-breakpoint-only(md) {
        top: 19px;
      }
    }

    /* H3 */
    &.hdl--3::before {
      top: 18px;

      @include media-breakpoint-only(xs) {
        top: 12px;
      }

      @include media-breakpoint-only(sm) {
        top: 16px;
      }

      @include media-breakpoint-only(md) {
        top: 16px;
      }
    }

    /* H4 */
    &.hdl--4::before {
      top: 16px;

      @include media-breakpoint-only(xs) {
        top: 11px;
      }

      @include media-breakpoint-only(sm) {
        top: 14px;
      }

      @include media-breakpoint-only(md) {
        top: 16px;
      }
    }

    /* H5 */
    &.hdl--5::before {
      top: 13px;

      @include media-breakpoint-only(xs) {
        top: 10px;
      }

      @include media-breakpoint-only(sm) {
        top: 11px;
      }

      @include media-breakpoint-only(md) {
        top: 13px;
      }
    }

    /* H6 */
    &.hdl--6::before {
      top: 11px;

      @include media-breakpoint-only(xs) {
        top: 10px;
      }

      @include media-breakpoint-only(sm) {
        top: 11px;
      }

      @include media-breakpoint-only(md) {
        top: 11px;
      }
    }
  }

  &__content {
    background-color: #fff;
    position: relative;
    padding: 0 15px 0 10px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    display: inline;
    margin: 0;
  }
}
