p {
  /* stylelint-disable selector-no-qualifying-type  */
  &.intro {
    font-size: 17px;
    line-height: 29px;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
      line-height: 30px;
    }
  }

  &.small {
    font-size: 12px;
    line-height: 22px;

    @include media-breakpoint-up(sm) {
      font-size: 13px;
      line-height: 24px;
    }
  }
  /* stylelint-enable */
}
