.pam-filter__filters {
  .card {
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
    }

    &--filter {
      background: transparent;
      border-radius: 0;
      border: 0;
      min-height: 70px;
    }

    &--slider {
      align-items: center;

      @include media-breakpoint-down(sm) {
        align-items: flex-start;
      }

      @include media-breakpoint-only(md) {
        align-items: baseline;
      }
    }

    &__header {
      padding: 15px 0;

      @include media-breakpoint-up(md) {
        flex: 1 0 20%;
      }
    }

    &__body {
      padding-right: 15px;
      padding-left: 25px;

      @include media-breakpoint-up(md) {
        flex: 1 0 80%;
        padding: 15px;
      }

      &--center {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;

        @include media-breakpoint-up(md) {
          flex-direction: row;
          justify-content: center;
        }
      }

      &--slider {
        margin-bottom: 24px;

        @include media-breakpoint-down(md) {
          width: 100%;

          // li with minVal
          li:nth-child(1) {
            order: 2;

            input {
              margin-right: 5.5px;
            }
          }
          // li with slider
          li:nth-child(2) {
            order: 1;
            margin-bottom: 20px;
          }
          // li with maxVal
          li:nth-child(3) {
            order: 3;
            input {
              margin-left: 5.5px;
            }
          }
          // li with button
          li:nth-child(4) {
            order: 4;
          }

          li:nth-child(1),
          li:nth-child(3) {
            width: calc((100% / 2) - 25px);

            input {
              width: calc(100% - 11px);
            }
          }
        }

        @include media-breakpoint-only(md) {
          .list--filter {
            width: 60%;
            // li with slider
            li:nth-child(2) {
              width: 100%;
            }
          }
        }

        &--input {
          width: 90px;
          border: none;
          background: $c_iron;
          color: var(--primary);
          outline: none;
          padding: 5px 10px;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: textfield;
            margin: 0;
          }

          &:focus {
            border: 2px solid var(--primary);
          }

          &.invalid {
            border: 2px solid var(--error);
          }

          &[type='number'] {
            -moz-appearance: textfield;
          }

          &--min {
            margin-right: 20px;
          }

          &--max {
            margin-left: 20px;
          }
        }

        &--btn-apply {
          border: none;
          background-color: var(--primary);
          color: var(--white);
          padding: 4px 9px 6px;
          margin-left: 11px;
          outline: none;

          &.disabled {
            opacity: 0.1;
            pointer-events: none;
          }

          .icon {
            color: #fff;
            width: 17px;
            height: 17px;
          }
        }

        @include media-breakpoint-up(xl) {
          padding-bottom: 0;
        }

        &-disabled {
          opacity: 0.35;
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }

    &--select {
      @include media-breakpoint-up(md) {
        align-items: center;
      }
    }

    p {
      font-size: 15px;
    }
  }

  .tag {
    justify-content: center;

    &__input {
      &[disabled] ~ .tag {
        opacity: 0.35;
      }
    }
  }

  .list {
    &--filter {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .invalid-feedback {
    color: var(--error);
    position: absolute;
    font-size: 14px;
    font-weight: 600;
    &--visible {
      display: block;
    }
  }

  .link {
    padding: 10px;

    @include media-breakpoint-up(md) {
      &--filter {
        display: block;
        text-align: right;
        pointer-events: none;

        .icon {
          display: none;
        }
      }
    }

    &--filter {
      color: $c_slate;
      font-size: 14px;
      width: 100%;
      padding: 0;
      font-weight: $font-weight-demi;

      .icon {
        fill: $c_slate;
        width: 14px;
        height: 14px;
        margin-right: 11px;
        transform: rotate(0);
        transition: transform 250ms ease-in-out;
      }

      &.collapsed .icon {
        transform: rotate(-90deg);
      }
    }
  }

  .filter--slider {
    position: relative;
    padding-left: 7px;
    padding-right: 7px;
    width: 100%;

    @include media-breakpoint-up(md) {
      // width: 60%;
      width: 29.5%;
    }

    + .filter--slider {
      margin-top: 20px;
    }

    .tooltip {
      z-index: 1050;
      background-color: white;
    }

    .slider-handle {
      z-index: 2;
    }

    .filter-label {
      position: absolute;
      top: 0;
      font-size: 13px;
      z-index: 1;

      &::after {
        content: '';
        position: absolute;
        width: 13px;
        height: 13px;
        transform: translateY(50%);
        background-color: white;
        border: 2px solid #ecedee;
        border-radius: 50%;
      }

      &--left {
        left: 0px;
        transform: translateX(-100%);
      }

      &--right {
        right: 14px;
      }

      span {
        position: absolute;
        top: 26px;
        color: $c_graychateau;
        min-width: 60px;
      }
    }
  }

  .filter--select {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 60%;
    }
  }

  .collapse {
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
