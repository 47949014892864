.country-list {
  display: flex;
  flex-flow: wrap;
  padding: 10px;

  &__item {
    width: 100%;

    @include media-breakpoint-up(xl) {
      width: 25%;
    }

    img {
      border: 1px solid $c_iron;
    }

    a:hover {
      text-decoration: none;
    }
  }

  &__link {
    display: table;
    border-spacing: 10px;

    > * {
      display: table-cell;
    }
  }
}
