.hotspots-slider {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 30px;
    content: '';

    @include media-breakpoint-up(lg) {
      bottom: 45px;
    }
  }

  &__main {
    .swiper-pagination-fraction {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: 70px;
      margin: 0 auto;
      bottom: 0;
      font-size: 26px;
      color: var(--primary_contrast);
      background-color: var(--primary);
    }
  }

  &__thumbs {
    width: 100%;

    .swiper-wrapper {
      justify-content: center;
    }

    .swiper-slide {
      width: 170px;
      cursor: pointer;
      background-color: $c_white;

      @include media-breakpoint-up(md) {
        width: 240px;
      }

      @include media-breakpoint-up(lg) {
        width: 300px;
      }

      /* one thumb */
      &:first-child:nth-last-child(1) {
        max-width: 100%;
      }

      /* two thumbs */
      &:first-child:nth-last-child(2),
      &:first-child:nth-last-child(2) ~ .swiper-slide {
        max-width: calc((100% - (10px * 1)) / 2);
      }

      /* three thumbs */
      &:first-child:nth-last-child(3),
      &:first-child:nth-last-child(3) ~ .swiper-slide {
        max-width: calc((100% - (10px * 2)) / 3);
      }

      /* four thumbs */
      &:first-child:nth-last-child(4),
      &:first-child:nth-last-child(4) ~ .swiper-slide {
        max-width: calc((100% - (10px * 3)) / 4);
      }
    }

    .swiper-slide-thumb-active {
      border-bottom: 3px solid var(--primary);
    }
  }

  &--gray {
    background-color: $c_concrete;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;

    display: none;

    @media screen and (min-width: 1300px) {
      display: block;
    }
  }

  .swiper-button-prev {
    left: 3%;

    .icon {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .swiper-button-next {
    right: 3%;

    .icon {
      transform: translateY(-50%);
    }
  }
}
