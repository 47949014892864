.teaser-image-text {
  text-align: left;

  .hdl {
    font-weight: $font-weight-demi;
    color: $c_slate;
  }

  img {
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .storer {
    position: absolute;
    top: 0;
    padding: 0 5px;
    background-color: var(--primary);
    border: 2px solid $c_white;

    .storer-text {
      color: $c_white;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;

      @include media-breakpoint-down(xs) {
        font-size: 17px;
        line-height: 29px;
      }
    }
  }

  p {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }
  }

  &__tags-container {
    margin-bottom: 15px;
  }
}
