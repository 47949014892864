.teaser-image-date {
  background-color: $c_white;
  text-align: left;

  &__date-container {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 25px;
    font-weight: $font-weight-demi;
    color: $c_graychateau;

    @include media-breakpoint-up(lg) {
      margin-bottom: 10px;
    }
  }

  .hdl {
    font-weight: $font-weight-demi;
    color: $c_slate;
  }

  img {
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  p {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      margin-bottom: 20px;
    }
  }
}
