.chisel-filters {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 32px;

    .form-group {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .dropdown.select {
      min-width: 250px;
    }
  }

  /* WGWEB-1464 */
  &__categories {
    margin-bottom: 0;
  }

  > div + div {
    // margin-top: 30px;
  }
}
