.campaign-stripe {
  display: flex;
  background-color: #ffba5f;
  align-items: center;

  &__link-wrapper {
    width: 100%;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;


    @include media-breakpoint-down(sm) {
      text-align: left;
      padding: 8px 0;
    }

    &-icon {
      width: 28px;
      min-width: 28px;
      height: 28px;
      background-image: url("../images/sale.svg");
      background-repeat: no-repeat;
      background-size: 28px;
      margin: 0 8px 0 0;

      @include media-breakpoint-only(xs) {
        width: 56px;
        margin: -20px 8px 0 0;
      }

      @include media-breakpoint-only(sm) {
        margin: -20px 10px 0 0;
      }
    }

    &-label {
      position: relative;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      text-align: center;
      margin: 8px 0;
      top: 1px;

      @include media-breakpoint-down(sm) {
        text-align: left;
      }

      @include media-breakpoint-only(md) {
        margin: 7px 0 9px;
      }
    }
  }
}
