$hotspotBorderMobile: 5px;
$hotspotBorderTablet: 6px;
$hotspotBorderDesktop: 10px;

$hotspotWidthMobile: 30px;
$hotspotWidthTablet: 40px;
$hotspotWidthDesktop: 60px;

$hotspotRadiusMobile: $hotspotWidthMobile / 2;
$hotspotRadiusTablet: $hotspotWidthTablet / 2;
$hotspotRadiusDesktop: $hotspotWidthDesktop / 2;
$hotspotLineHeight: 100px;

$hotspotBorderMobile: 5px;
$hotspotBorderTablet: 6px;
$hotspotBorderDesktop: 10px;

$hotspotWidthMobile: 30px;
$hotspotWidthTablet: 40px;
$hotspotWidthDesktop: 60px;

$hotspotRadiusMobile: $hotspotWidthMobile / 2;
$hotspotRadiusTablet: $hotspotWidthTablet / 2;
$hotspotRadiusDesktop: $hotspotWidthDesktop / 2;
$hotspotLineHeight: 100px;

$hotspotTipWidth: 170px;

// This is just a single spot
.hotspot-xray {
  &__spot {
    position: absolute;
    display: inline-flex;
    width: $hotspotWidthMobile;
    top: -200px;
    height: $hotspotWidthMobile;
    margin-top: -#{$hotspotRadiusMobile};
    margin-left: -#{$hotspotRadiusMobile};
    border: $hotspotBorderMobile solid rgba($c_black, 0.2);
    border-radius: 50%;
    background-clip: padding-box;
    padding: $hotspotBorderMobile;
    background-color: $c_white;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      width: $hotspotWidthTablet;
      height: $hotspotWidthTablet;
      border-width: $hotspotBorderTablet;
      margin-top: -#{$hotspotRadiusTablet};
      margin-left: -#{$hotspotRadiusTablet};
      padding: $hotspotBorderTablet;
    }

    @include media-breakpoint-up(lg) {
      width: $hotspotWidthDesktop;
      height: $hotspotWidthDesktop;
      border-width: $hotspotBorderDesktop;
      margin-top: -#{$hotspotRadiusDesktop};
      margin-left: -#{$hotspotRadiusDesktop};
      padding: $hotspotBorderDesktop;
    }

    .icon-plus {
      width: 10px;
      height: 10px;
      margin: auto;
      transition: transform 300ms ease-in-out;
      color: var(--primary);

      @include media-breakpoint-up(lg) {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__line {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
    }
  }

  &__tip {
    display: none;
    overflow: hidden;
    height: 40px;
    line-height: 1.3;

    @include media-breakpoint-up(lg) {
      position: absolute;
      z-index: 20;
      display: flex;
      // min-width: 150px;
      width: 215px;
      font-weight: $font-weight-demi;
    }

    > div {
      width: 100%;
    }
  }

  &--opened {
    background-color: var(--primary);

    .icon {
      color: var(--primary_contrast);
      transform: rotate(45deg);
    }
  }

  &--hidden {
    opacity: 0;
  }
}

// This is the real component base class!
.hotspots-xray {
  position: relative;
  opacity: 0;
  transition: opacity 250ms ease-out;
  background: #050504;
  padding-top: 30px !important;
  padding-bottom: 30px;

  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 15px;
  }

  &__wrapper {
    position: relative;
    margin-top: 0;
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      margin-bottom: 20px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-bottom: 30px;
      padding-bottom: 0;
    }
  }

  &__content {
    display: none;
  }

  .hotspots__container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .hotspots__image-overlay {
    position: absolute;
    top: 0;
  }

  .clip-border {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .hotspots__image-mask {
    display: none;
    position: absolute;
    top: 0;
    mask-type: alpha;
  }

  .hotspot__line,
  .hotspot__tip {
    display: none !important;
  }

  &--a {
    @include media-breakpoint-up(lg) {
      padding-top: 200px !important;
      padding-bottom: 200px !important;
    }

    .hotspot__line {
      border-left: 2px dashed $c_graychateau;
    }

    .hotspot--top {
      .hotspot__line {
        top: -150px;
      }

      .hotspot__tip {
        top: -200px;

        > div {
          margin-top: auto;
        }
      }
    }

    .hotspot--bottom {
      .hotspot__line {
        bottom: -150px;
      }

      .hotspot__tip {
        bottom: -200px;
      }
    }

    .hotspot--left {
      .hotspot__tip {
        left: 0;
        margin-left: -10px;
      }
    }

    .hotspot--middle {
      .hotspot__tip {
        text-align: center;
        transform: translateX(-50%);
      }
    }

    .hotspot--right {
      .hotspot__tip {
        text-align: right;
        transform: translateX(-100%);
        margin-left: 10px;
      }
    }
  }

  &--b {
    @include media-breakpoint-up(lg) {
      padding-left: 200px;
      padding-right: 200px;
    }

    .hotspot__tip {
      > div {
        width: 100%;
      }
    }

    .hotspot__line {
      border-top: 2px dashed $c_graychateau;
    }

    .hotspot--left {
      .hotspot__line {
        left: -30px;
      }

      .hotspot__tip {
        width: #{$hotspotTipWidth};
        left: -200px;
        margin-left: -10px;
        text-align: right;
      }
    }

    .hotspot--right {
      .hotspot__line {
        right: -20px;
      }

      .hotspot__tip {
        width: #{$hotspotTipWidth};
        right: -200px;
      }
    }
  }
}
