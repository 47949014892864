.table-top {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  dl,
  dt,
  dd,
  ul {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: auto;
    list-style: none;
    padding: 0;

    @include media-breakpoint-up(lg) {
      align-items: center;
      width: 100%;
    }
  }
  ul.list {
    @include media-breakpoint-up(lg) {
      align-items: flex-start;
    }
  }

  li,
  dt {
    font-weight: $font-weight-demi;
  }

  dd {
    margin: 0;
  }

  &--single-col .table-head {
    display: flex !important;
  }

  &--single-col .table-body {
    display: none !important;
  }

  .table-head {
    display: none;
    padding: 0;

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    .icon {
      margin-left: 10px;
      width: 12px;
      height: 12px;
    }

    dd {
      font-weight: $font-weight-demi;
    }

    .table-row--intended {
      @include media-breakpoint-up(lg) {
        margin-left: 100px;
      }
    }
  }

  .table-body {
    display: flex;
    flex-direction: column;
    padding: 0;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      width: 80%;
      text-align: center;
    }
  }

  .table-col {
    margin: 0;
    padding: 0;
  }

  .table-row {
    position: relative;
    display: inline-block;
    padding: 11px 15px;
    border-bottom: solid $c_iron 1px;
    font-size: 15px;

    @include media-breakpoint-up(lg) {
      padding: 27px;
    }

    dt {
      font-weight: $font-weight-medium;
      color: $c_slate;
      font-size: 13px;
      line-height: 22px;
      opacity: 0.5;
    }

    dd {
      font-weight: $font-weight-demi;
      font-size: 15px;
      color: $c_slate;
      line-height: 25px;

      @include media-breakpoint-up(lg) {
        font-weight: $font-weight-medium;
      }
    }

    &--intend {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &--intended {
      font-weight: $font-weight-medium;
      margin-left: 50px;

      @include media-breakpoint-up(lg) {
        align-items: center;
        margin-left: 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    &--head {
      font-weight: $font-weight-demi;
      font-size: 16px;
      padding: 1px 0 0 0;
      background-color: $c_concrete;

      .card & {
        @include media-breakpoint-down(md) {
          background-color: transparent;
          border-color: transparent;
        }
      }

      @include media-breakpoint-up(lg) {
        background-color: transparent;
        font-size: 13px;
        font-weight: $font-weight-medium;
      }
    }

    &--body .dropup {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    > dl > dt {
      opacity: 1;
      font-size: 15px;
    }

    &.d-lg-none {
      border-bottom: 0;
      padding-bottom: 0;
    }

    .btn-toggle {
      color: $c_slate;
      background-color: transparent;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 23px 15px;
      font-size: inherit;
      font-weight: inherit;
      line-height: 24px;
      width: 100%;
      max-width: none;
      min-width: unset;

      @include media-breakpoint-up(lg) {
        padding: 27px;
        pointer-events: none;
      }

      &:hover {
        text-decoration: none;
      }

      .icon {
        display: flex;
        flex-shrink: 0;
        margin-left: auto;
        width: 16px;
        height: 16px;

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      &[aria-expanded='true'] .icon {
        transform: rotate(180deg);
      }
    }
  }

  .dropup {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;

    @include media-breakpoint-up(lg) {
      position: relative;
      right: auto;
      top: auto;
      transform: none;
    }

    .dropdown-toggle {
      .icon {
        width: 13px;
        height: 13px;
        color: $c_graychateau;
        margin: 0;

        @include media-breakpoint-up(lg) {
          margin: 0 0 0 10px;
        }
      }
    }

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu.show {
      min-width: 250px;
      max-height: 100vw;
      padding: 15px;
      border: 0;
      border-radius: 0;
      overflow: auto;
      box-shadow: 0 0 15px 0 rgba($c_black, 0.2);

      .dropdown__container {
        width: auto;
      }
    }

    .dropdown-header {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      color: $c_slate;

      .icon {
        cursor: pointer;
        width: 15px;
        margin-left: 30px;
        color: $c_slate;
      }
    }
  }

  &__info-popup {
    display: inline;
  }

  .toggle-inner {
    &__sup {
      font-weight: $font-weight-normal;
      display: block;
    }
  }

  // styles for inner m-027 table
  .scrolltable {
    th {
      border-top: 0;
      padding-top: 0;
    }
    div[class*='hdl'] {
      display: none;
    }

    .scrolltable__nav--right {
      @include media-breakpoint-down(sm) {
        right: -25px;
      }
    }

    .scrolltable__nav--left {
      @include media-breakpoint-down(sm) {
        left: -25px;
      }
    }
  }
}
