.bullets {
  text-align: left;

  &__container,
  &__btn-container--outside {
    color: var(--primary_alt);
  }

  /*hide the column if no content */
  .col:empty,
  [class^='col-']:empty {
    display: none;
  }

  .hdl,
  p {
    color: $c_slate;
  }

  .list {
    @include media-breakpoint-up(md) {
      margin-bottom: 25px;
    }

    .list__item {
      padding-bottom: 15px;

      &.li--overflow {
        display: none;
      }
    }

    &__item__wrapper {
      color: $c_slate;
    }
  }

  .btn-secondary .icon {
    width: 13px;
    height: 13px;
    margin-left: 10px;
  }

  .btn--show-less {
    display: none;
  }

  .bullets__btn-container--inside {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .bullets__btn-container--outside {
    margin-left: 15px;
    display: none;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }

  [class*='col-'] ~ [class*='col-'] {
    @include media-breakpoint-down(sm) {
      margin-top: 40px;
    }
  }

  &__mp4 {
    position: relative;

    video {
      width: 100%;
      height: 100%;
      display: flex;
    }

    .play-pause-wrapper {
      display: flex;
      justify-content: flex-end;
      z-index: 1001 !important;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(-20px, -20px);

      @include media-breakpoint-down(md) {
        display: none;
      }

      .play-icon, .pause-icon {
        z-index: 1001;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        border: 2px solid rgba(0,0,0, 0.2);
        background-color: $c_white;
        height: 50px;
        width: 50px;
        margin-right: -1.25%; // cancel out the bootstrap padding on the parent element

        &:hover {
          border: 4px solid rgba(0,0,0, 0.2);
          cursor: pointer;
        }

        &:focus-visible {
          border: 4px solid rgba(0,0,0, 0.2);
          outline: none;
        }

        .icon {
          height: 12px;
          width: 9.17px;
        }
      }
    }
  }
}
