.pager {
  display: flex;
  justify-content: center;

  &__item {
    color: var(--primary_alt);
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    height: 40px;
    min-width: 40px;
    padding: 0 5px;

    @include media-breakpoint-up(sm) {
      margin: 0 7.5px;
    }

    &[type] {
      background-color: $c_concrete;
      margin: 0 15px;

      @include media-breakpoint-up(sm) {
        margin: 0 7.5px;
      }
    }

    &:hover {
      text-decoration: none;
      color: initial;
    }

    &--active {
      background-color: var(--primary);
      color: var(--primary_contrast);

      &:hover {
        color: var(--primary_contrast);
      }
    }

    &--disabled {
      color: $c_graychateau;
      pointer-events: none;
    }

    &--sm {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .icon {
      height: 10px;
      color: inherit;
    }
  }
}
