.mteaser {
  display: flex;
  flex-flow: row wrap;

  &__image {
    width: 100%;
    background-position: center;
    background-size: cover;
    @include aspect-ratio(16, 9);

    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }

  &__content {
    width: 100%;
    padding: 50px;
    background-color: $c_concrete;

    @include media-breakpoint-up(lg) {
      width: 50%;
      padding: 80px;
    }
  }

  &--reverse {
    @include media-breakpoint-up(lg) {
      flex-flow: row-reverse wrap;
    }
  }
}
