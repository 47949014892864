$translate-y: 60px;

.double-teaser {
  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px - $translate-y;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    height: calc(100% - #{$translate-y});
    display: flex;
    flex-direction: column;
  }

  &__image, &__mp4 {
    padding: 0;
    width: auto;
    min-height: $translate-y + 15px;
    height: auto;
    margin-left: calc(-10px - 1.25%);
    margin-right: calc(-10px - 1.25%);

    @include media-breakpoint-up(md) {
      margin-left: -1.25%;
      margin-right: -1.25%;
    }

    @include media-breakpoint-up(lg) {
      min-height: $translate-y;
      margin: auto;
      width: 100%;
    }
  }

  &__mp4 {
    position: relative;

    video {
      width: 100%;
      height: 100%;
    }

    .play-pause-wrapper {
      display: flex;
      justify-content: flex-end;
      z-index: 1001 !important;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(-20px, -40px);

      @include media-breakpoint-down(md) {
        display: none;
      }

      .play-icon, .pause-icon {
        z-index: 1001;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        border: 2px solid rgba(0,0,0, 0.2);
        background-color: $c_white;
        height: 50px;
        width: 50px;
        margin-right: -1.25%; // cancel out the bootstrap padding on the parent element

        &:hover {
          border: 4px solid rgba(0,0,0, 0.2);
          cursor: pointer;
        }

        &:focus-visible {
          border: 4px solid rgba(0,0,0, 0.2);
          outline: none;
        }

        .icon {
          height: 12px;
          width: 9.17px;
        }
      }
    }
  }

  &__info {
    height: auto;
    text-align: center;
    color: $c_slate;
    background-color: $c_white;
    padding: 20px;
    margin: 0 14px;

    &--bgdark {
      background-color: $c_concrete;
    }

    @include media-breakpoint-up(md) {
      text-align: left;
      margin: 0 33px;
      padding: 30px 50px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 20px;
      padding: 40px 50px;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 20px;
      padding: 40px 60px;
    }

    p {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 30px;
      }
    }

    .list-unstyled {
      text-align: left;
      font-weight: $font-weight-demi;
    }
  }

  &--alt &__image {
    display: none;
  }

  &--alt &__container {
    margin-bottom: 0;
  }

  &--alt &__container:last-child {
    margin-top: 30px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
}
