@import 'table--scroll';
@import 'table--sortable';

.table {
  width: 100%;

  &--standard {
    thead th {
      border-bottom-color: var(--primary);
    }
  }
}
