.pam-filters {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 32px;

    .form-group {
      display: none;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .dropdown.select {
      min-width: 250px;
    }

    &-modals {
      display: none;
      align-items: center;
      gap: 30px;
      flex-direction: row;
      font-size: 15px;
      line-height: 20.5px;

      .info-modal {
        gap: 5px;

        &:focus-visible {
          transform: scale(1.05);
          transition: transform 0.2s ease-in-out;
        }
      }

      .comparison-modal {
        padding: 15px 30px;

        &:focus-visible {
          transform: scale(1.05);
          transition: transform 0.2s ease-in-out;
        }
      }
    }

    #modal--info-modal {
      z-index: 9000;

      .modal-dialog {
        max-width: none;
        width: fit-content;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(md) {
          width: 100%;
          height: 100vh;
          margin: auto;
        }

        .modal-content {
          height: 100%;
          width: 830px;

          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
      }

      .modal-header {
        position: absolute;
        right: 0;
        z-index: 1;
        padding-top: 20px;
        padding-right: 30px;

        @include media-breakpoint-down(md) {
          margin-top: 10px;
        }

        @include media-breakpoint-down(sm) {
          padding-right: 20px;
          margin-top: 5px;
        }

        .close {
          background-color: var(--primary);
          outline: 5px solid rgba(0, 0, 0, 0.12);
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          padding: 0;

          &:hover {
            transform: scale(1.05);
            outline: 7px solid rgba(0, 0, 0, 0.12);
          }

          &:focus-visible {
            transform: scale(1.05);
            outline: 7px solid rgba(0, 0, 0, 0.12);
          }

          transition: outline, transform 0.2s ease-in-out;

          .icon.icon-close {
            color: var(--primary_contrast);
            width: 14px;
            height: 14px;
          }
        }
      }

      .modal-body {
        padding: 20px 30px 38px 30px;

        .modal-title {
          @include media-breakpoint-down(md) {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .modal--info-modal-content {
          display: flex;
          flex-direction: row;
          gap: 30px;
          align-items: flex-start;

          @include media-breakpoint-down(md) {
            flex-direction: column;
            gap: 20px;
            padding-top: 30px;
            align-items: center;
          }

          .modal--info-modal-text {
            font-size: 15px;
            line-height: 25px;
            font-weight: 400;
            color: #41535d;
            max-width: 370px;

            @include media-breakpoint-down(md) {
              max-width: 100%;
            }
          }

          .modal--info-modal-image {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }

    #modal--comparison-modal {
      z-index: 9000;

      .modal-dialog {
        max-width: none;
        width: fit-content;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(md) {
          width: 100%;
          height: 100vh;
          margin: auto;
        }

        .modal-content {
          height: 100%;
        }
      }

      .modal-header {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        right: 0;
        z-index: 1;
        padding-top: 20px;
        padding-right: 30px;
        padding-left: 30px;

        .close {
          background-color: var(--primary);
          outline: 5px solid rgba(0, 0, 0, 0.12);
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          padding: 0;

          &:hover {
            transform: scale(1.05);
            outline: 7px solid rgba(0, 0, 0, 0.12);
          }

          &:focus-visible {
            transform: scale(1.05);
            outline: 7px solid rgba(0, 0, 0, 0.12);
          }

          transition: outline, transform 0.2s ease-in-out;

          .icon.icon-close {
            color: var(--primary_contrast);
            width: 14px;
            height: 14px;
          }
        }
      }

      .modal-body {
        padding: 0;
        padding-top: 20px;

        @include media-breakpoint-down(md) {
          width: 100vw;
        }

        .modal-title {
          padding-left: 30px;
        }

        .modal--comparison-modal-content {
          padding: 0 30px;

          @include media-breakpoint-down(md) {
            padding-top: 30px;
          }

          .chart-selection-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 20px;

            @include media-breakpoint-down(md) {
              flex-direction: column;
              gap: 10px;
            }

            .select-wrapper-one {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;

              @include media-breakpoint-down(md) {
                width: 100%;
              }

              .color-box-one {
                min-height: 48px;
                min-width: 48px;
                background-color: var(--default-color-one);
                border: 2px solid var(--default-color-border-one);
              }

              button {
                padding: 12px;
                background-color: $c_concrete;
                width: 290px;
                height: 50px;
                position: relative;
                cursor: pointer;
                appearance: none;
                /*  safari  */
                -webkit-appearance: none;
                /*  firefox  */
                -moz-appearance: none;

                @include media-breakpoint-down(md) {
                  width: 100%;
                }
              }
            }

            .comparison-text {
              margin-bottom: 0;
            }

            .select-wrapper-two {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;

              @include media-breakpoint-down(md) {
                flex-flow: row-reverse;
                width: 100%;
              }

              .color-box-two {
                min-height: 48px;
                min-width: 48px;
                background-color: var(--default-color-two);
                border: 2px solid var(--default-color-border-two);
              }

              button {
                padding: 12px;
                background-color: $c_concrete;
                width: 290px;
                height: 50px;
                position: relative;
                cursor: pointer;
                appearance: none;
                /*  safari  */
                -webkit-appearance: none;
                /*  firefox  */
                -moz-appearance: none;

                @include media-breakpoint-down(md) {
                  width: 100%;
                }
              }
            }
          }

          .chart-container {
            height: 425px;
            background-color: #f3f3f3;
            margin-top: 30px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  .pam-filter__bar--sticky {
    position: sticky;
    top: -1px; /* ➜ the trick */
    padding-top: 1px; /* ➜ compensate for the trick */
    transition: 0.1s;
  }

  /* WGWEB-1464 */
  &__categories {
    margin-bottom: 0;
  }

  > div + div {
    margin-top: 30px;
  }

  > div + .pam-filter__bar,
  > div + .pam-filter {
    margin-top: 0;
  }
}
