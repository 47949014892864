.dealer__results {
  padding-top: 30px;

  @include media-breakpoint-up(md) {
    padding-top: 40px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 50px;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 60px;
  }

  // Special Headlines ..... :(
  .hdl--2 {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 34px;
    }
  }

  .hdl--3 {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 34px;
    }
  }

  .iconlist__contacts {
    margin-bottom: 32px;
  }
}
