.stage-slider {
  color: $c_white;

  .swiper-slide {
    overflow: hidden;
  }

  &__container {
    z-index: 0;

    &:has(~ .bread-bar--below) {
      .pagination-wrapper {
        margin-bottom: 10px;
      }
    }

    ~.bread-bar--below {
      transform: translateY(-15%);
    }
  }

  .content {
    min-height: 300px;
    position: relative;

    @include media-breakpoint-up(md) {
      aspect-ratio: 21 / 9;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      bottom: 0;
    }

    &--video {
      .mp4-video {
        width: 100%;
        height: 100%;
        display: flex;
        object-fit: cover;
      }

      .play-pause-wrapper {
        display: flex;
        justify-content: flex-end;
        z-index: 1001 !important;
        position: absolute;
        right: 50%;
        bottom: 0;
        transform: translate(50%, -70px);

        @include media-breakpoint-down(md) {
          display: none;
        }

        .play-icon, .pause-icon {
          z-index: 1001;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 25px;
          border: 2px solid rgba(0,0,0, 0.2);
          background-color: $c_white;
          height: 50px;
          width: 50px;
          margin-right: -1.25%; // cancel out the bootstrap padding on the parent element

          &:hover {
            border: 4px solid rgba(0,0,0, 0.2);
            cursor: pointer;
          }

          &:focus-visible {
            border: 4px solid rgba(0,0,0, 0.2);
            outline: none;
          }

          .icon {
            height: 12px;
            width: 9.17px;
          }
        }
      }
    }
  }

  // gradient
  &__gradient {
    position: absolute;
    width: 100%;
    opacity: 0.5;
    height: 40%;
  }

  &__gradient--top {
    background: linear-gradient(0deg, rgba($c_black, 0) 0%, rgba($c_black, 1) 100%);
    top: 0;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__gradient--bottom {
    background: linear-gradient(0deg, rgba($c_black, 1) 0%, rgba($c_black, 0) 100%);
    bottom: 0;
    height: 50%;
  }

  // content
  &__wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .container {
      height: 100%;
      position: relative;

      .btn {
        z-index: 1002;
      }
    }
  }

  &__slide-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 56px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      justify-content: flex-end;
      padding-bottom: 70px;
      text-align: left;
    }
  }

  .hdl {
    color: inherit;
  }

  .btn-primary,
  .btn-primary:active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:hover {
    background-color: $c_white !important;
    border-color: $c_white !important;
    color: $c_slate !important;
    max-width: 200px;

    @include media-breakpoint-down(sm) {
      align-self: center;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-up(md) {
      max-width: 210px;
    }
  }

  // Swiper styles
  .pagination-wrapper {
    .pause-icon {
      margin: 0 15px;

      .icon-pause {
        width: 15px;
      }

      &:hover {
        &::before {
          content: '';
          max-width: 45px;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.2);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 10px;
          z-index: -1;
        }
      }

      &:focus-visible {
        // disable the custom focus blue border
        outline: none;

        &::before {
          content: '';
          max-width: 48px;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.2);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 2px solid var(--hover);
          border-radius: 10px;
          z-index: -1;
        }
      }
    }

    .play-icon {
      margin: 0 15px;

      .icon-play {
        width: 18px;
      }

      &:hover {
        &::before {
          content: '';
          max-width: 48px;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.2);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 10px;
          z-index: -1;
        }
      }

      &:focus-visible {
        // disable the custom focus blue border
        outline: none;

        &::before {
          content: '';
          max-width: 48px;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.2);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 2px solid var(--hover);
          border-radius: 10px;
          z-index: -1;
        }
      }
    }

    .swiper-pagination-bullet {
      border-color: $c_white;
      background-color: transparent;
    }

    .swiper-pagination-bullet-active {
      background-color: $c_white;
      border-color: $c_white;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;

    display: none;
  }

  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      display: block;

      .icon {
        position: absolute;
        fill: $c_white;
        color: $c_white;
        filter: drop-shadow(0 0 3px rgba($c_black, 0.7));

        @include media-breakpoint-up(md) {
          height: 50px;
          width: 50px;
        }
      }
    }

    .swiper-button-disabled {
      display: none;
    }
  }

  .swiper-button-next {
    right: 10px;
    left: auto;
  }

  .swiper-button-prev {
    left: 10px;
    right: auto;
  }

  .content--video {
    position: relative;
    background-color: $c_black;

    @include media-breakpoint-down(sm) {
      min-height: 300px;
    }

    @include media-breakpoint-up(md) {
      @include aspect-ratio(21, 9);
    }
  }

  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    margin: 0;

    @include media-breakpoint-only(xs) {
      height: 100%;
      width: 150%;
      left: calc((100vw - 150%) / 2) !important;
      right: auto !important;
    }

    @include media-breakpoint-down(sm) {
      min-height: 100%;
    }
  }
}
