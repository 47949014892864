.product-stage {
  &__container {
    background: linear-gradient(180deg, $c_white 50%, $c_concrete 100%);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 50px;

    @include media-breakpoint-up(md) {
      padding-top: 20px;
      padding-bottom: 65px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 50px;
      padding-bottom: 75px;
    }
  }

  &__image {
    flex-direction: column;

    .product-text--inline {
      padding-top: 0;
      justify-content: end;
    }

    > div {
      width: 100%;
    }

    .product-flags--inline {
      @include media-breakpoint-down(sm) {
        padding-bottom: 12px !important;
        border-bottom: 1px solid $c_iron;
      }
    }
  }

  &__content {
    margin: 25px 0;
    justify-content: center;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
      padding-left: 30px;
    }

    > p {
      margin-bottom: 20px;
    }
  }

  &__headline {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(md) {
      align-items: flex-start;
    }

    .hdl {
      color: var(--primary_alt);
      max-width: 100%;
      width: max-content;
      white-space: nowrap;
      overflow: hidden;

      /* ODSUPPORT-4948 - Prevent the headline
       from being cut off within safari browser
      */
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) and (stroke-color: transparent) {
          overflow: visible;
          margin-top: 10px;
        }
      }
    }
  }

  &__description {
    margin-bottom: 15px;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    letter-spacing: 0;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-sm;
    }
  }

  .function-list {
    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
  }

  .product-flags {
    margin-top: 12px;
    padding-bottom: 0;
  }

  .product-text--text {
    span {
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }
  }
}
