.masonry-teaser {
  padding-left: 0;
  padding-right: 0;

  &_container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;

    &_row {
      display: grid;
      gap: 6px;
      margin-bottom: 6px;

      &:nth-child(odd) {
        grid-template-columns: [col1] 100%;

        @include media-breakpoint-up(lg) {
          grid-template-columns: [col1] 70% [col2] 30%;
        }
      }

      &:nth-child(even) {
        grid-template-columns: [col1] 100%;

        @include media-breakpoint-up(lg) {
          grid-template-columns: [col1] 30% [col2] 70%;
        }
      }

      &.lone {
        @include media-breakpoint-up(md) {
          grid-template-columns: [col1] 100%;
        }
      }

      &-layout {
        position: relative;

        &-headline {
          position: absolute;
          top: 8%;
          left: 10%;
          right: 10%;
          word-wrap: break-word;
          z-index: 2;
          color: $c_white;
          transform: translateY(0);
          transition: transform .2s ease-in-out;

          h2 {
            font-weight: 700;
            line-height: 45px;
            font-size: 34px;


            @include media-breakpoint-down(lg) {
              font-size: 30px;
              line-height: 40px;
            }

            @include media-breakpoint-down(md) {
              font-size: 28px;
              line-height: 38px;
            }

            @include media-breakpoint-down(sm) {
              font-size: 20px;
              line-height: 29px;
            }
          }
        }


        &-button {
          position: absolute;
          left: 10%;
          bottom: 8%;
          z-index: 1;
          transform: translateY(0);
          transition: transform .2s ease-in-out;

          @include media-breakpoint-down(sm) {
            left: 8%;
            bottom: 8%;
          }

          a {
            background-color: $c_white;
            border-radius: 50px;
            color: $tooltip-bg;
            transition: .2s ease-in-out;

            &:hover {
              border: none;
              outline: 4px solid rgba(255, 255, 255, 0.5);
              -webkit-background-clip: padding-box; /* for Safari */
              background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
            }

            &:focus {
              border: none;
              outline: 4px solid rgba(255, 255, 255, 0.5);
              outline-offset: -1px;
              -webkit-background-clip: padding-box; /* for Safari */
              background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
            }

            @include media-breakpoint-down(sm) {
              min-width: 200px;
            }
          }
        }

        &_content {
          height: 100%;
          overflow: hidden;

          &-wrapper {
            height: 100%;
            transition: transform 0.2s ease-in-out;

            &:focus-within {
              transform: scale(1.02);
            }

            &_shadow {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              z-index: 1;
              height: 200px;
              background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
            }

            &_image {
              height: 100%;
              width: 100%;
              cursor: pointer;

              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }
          }
        }

        &_content:hover > &-headline,
        &_content:focus-within > &-headline{
          transform: translateY(3px);
          transition: transform .2s ease-in-out;
        }

        &_content:hover > .masonry-teaser_container_row-layout_content-wrapper,
        &_content:focus-within > .masonry-teaser_container_row-layout_content-wrapper {
          transform: scale(1.02);
        }

        &_content:hover > &-button,
        &_content:focus-within > &-button {
          transform: translateY(-3px);
          transition: transform .2s ease-in-out;
        }
      }
    }
  }
}
