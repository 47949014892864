// Atom Headlines
%hdl {
  font-weight: $font-weight-bold;
  letter-spacing: -0.02em;
  margin-top: 0;
  margin-bottom: 10px;

  @include media-breakpoint-up(md) {
    margin-bottom: 15px;
  }
}

.hdl-group {
  flex: 1 0 auto;

  > *:first-child {
    display: flex;
  }
}

.hdl {
  &--fwn {
    @extend %hdl;

    font-weight: $font-weight-normal !important;
  }

  &--fwl {
    @extend %hdl;

    font-weight: $font-weight-light !important;
  }

  &--1 {
    @extend %hdl;

    font-size: 24px;
    line-height: 34px;

    @include media-breakpoint-up(sm) {
      font-size: 32px;
      line-height: 42px;
    }

    @include media-breakpoint-up(md) {
      font-size: 35px;
      line-height: 46px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 42px;
      line-height: 54px;
    }
  }

  &--2 {
    @extend %hdl;

    font-size: 20px;
    line-height: 29px;

    @include media-breakpoint-up(sm) {
      font-size: 28px;
      line-height: 38px;
    }

    @include media-breakpoint-up(md) {
      font-size: 30px;
      line-height: 40px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 34px;
      line-height: 45px;
    }
  }

  &--3 {
    @extend %hdl;

    font-size: 18px;
    line-height: 27px;

    @include media-breakpoint-up(sm) {
      font-size: 24px;
      line-height: 34px;
    }

    @include media-breakpoint-up(md) {
      font-size: 25px;
      line-height: 35px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  &--4 {
    @extend %hdl;

    font-size: 16px;
    line-height: 24px;

    @include media-breakpoint-up(sm) {
      font-size: 20px;
      line-height: 29px;
    }

    @include media-breakpoint-up(md) {
      font-size: 22px;
      line-height: 32px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 24px;
      line-height: 34px;
    }
  }

  &--5 {
    @extend %hdl;
    font-weight: $font-weight-demi;

    font-size: 15px;
    line-height: 23px;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
      line-height: 27px;
    }

    @include media-breakpoint-up(md) {
      font-size: 19px;
      line-height: 28px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 20px;
      line-height: 29px;
    }
  }

  &--6 {
    @extend %hdl;
    font-weight: $font-weight-demi;

    font-size: 15px;
    line-height: 23px;

    @include media-breakpoint-up(sm) {
      font-size: 16px;
      line-height: 24px;
    }

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 24px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &--large {
    @extend %hdl;

    font-size: 32px;
    line-height: 42px;

    @include media-breakpoint-up(sm) {
      font-size: 46px;
      line-height: 58px;
    }

    @include media-breakpoint-up(md) {
      font-size: 50px;
      line-height: 64px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 60px;
      line-height: 74px;
    }
  }
}
