.category-image-text-tile {
  height: auto;
  width: 100%;

  a {
    text-decoration: none;
  }

  &__picture {
    position: relative;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      margin-bottom: 15px;
    }
  }

  .hdl {
    display: flex;
    align-items: baseline;
    font-weight: $font-weight-demi;
  }

  &__icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    @include media-breakpoint-up(sm) {
      width: 29px;
      height: 29px;
    }
    @include media-breakpoint-up(md) {
      width: 32px;
      height: 32px;
    }
    @include media-breakpoint-up(lg) {
      width: 34px;
      height: 34px;
    }
  }
}
