.footer {
  display: flex;
  flex-direction: column;
  background-color: $c_slate;
  padding: 0;

  .icon {
    color: $c_white;
  }

  .btn-toggle {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: $font-weight-demi;
    color: $c_white;
    padding: 10px 0 10px 0;
    min-width: 0;

    @include media-breakpoint-up(lg) {
      pointer-events: none;
    }

    .icon {
      margin-right: 15px;
      width: 11px;
      height: 11px;
    }

    &[aria-expanded='true'] .icon {
      transform: rotate(180deg);
    }
  }

  .link {
    display: inline;
    font-size: 14px;
    line-height: 32px;
    font-weight: $font-weight-medium;

    &.link--cookie {
      padding: 0;
    }
  }

  .collapse {
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .brand-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .list {
    padding-left: 28px;
    padding-right: 28px;

    &:only-child {
      padding-bottom: 0;

      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  .brand-list-item {
    margin: 0 0 20px 0;
    flex: 1 0 auto;

    @include media-breakpoint-up(lg) {
      padding-left: 25px;
      padding-right: 25px;
    }

    &:first-child {
      @include media-breakpoint-up(lg) {
        padding-left: 0;
      }
    }

    &:last-child {
      @include media-breakpoint-up(lg) {
        padding-right: 0;
      }
    }

    &:last-child .brand__name::after {
      content: '';
    }
  }

  .brand__name {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-weight: $font-weight-bold;
    font-size: 15px;
    line-height: 25px;

    &::after {
      content: '/';
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__blocks {
    padding: 30px 0;

    @include media-breakpoint-up(lg) {
      padding: 55px 0 48px;
    }
  }

  &__contactdata {
    padding-bottom: 25px;

    p {
      margin: 0;
      color: $c_white;
    }
  }

  &__brands {
    padding: 20px 0 0 0;
    background-color: $c_white;

    @include media-breakpoint-up(lg) {
      padding: 30px 0 10px 0;
    }
  }

  &__social-media {
    margin: 22px 0 0 -26px;
  }

  &__stamp {
    .icon {
      width: 100px;
      height: 100px;
    }
  }

  &__iconlink {
    display: block;
  }
}
