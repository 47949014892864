.download-link {
  &.dropup {
    display: block;

    @include media-breakpoint-up(sm) {
      display: inline-block;
    }
  }

  .downloads__link {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: normal;
    outline-color: transparent;
    outline-style: none;
    color: $c_slate !important;
    cursor: pointer;

    @include media-breakpoint-up(sm) {
      margin-right: 30px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
    }

    &:hover {
      color: $c_slate;
    }

    // influence downloads icons, not icons in dropdown menu
    .icon-wrapper > .icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      padding: 0;
      color: var(--primary);
      pointer-events: none;
    }
  }

  // remove angle icon from dropdown-toggle-link
  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    min-width: auto;
    padding: 20px;
    padding-bottom: 10px;
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 15px 0 rgba($c_black, 0.2);

    &[x-placement='top-start']::after {
      position: absolute;
      left: 86px;
      bottom: -7px;
      display: inline-block;
      border-top: 8px solid $c_white;
      border-right: 9px solid rgba($c_black, 0);
      border-left: 9px solid rgba($c_black, 0);
      content: '';
    }

    &[x-placement='bottom-start']::after {
      position: absolute;
      top: -7px;
      left: 86px;
      display: inline-block;
      border-right: 9px solid rgba($c_black, 0);
      border-bottom: 8px solid $c_white;
      border-left: 9px solid rgba($c_black, 0);
      content: '';
    }

    .link {
      padding-bottom: 10px;
      color: var(--primary_alt);
      align-items: baseline;
      white-space: nowrap;

      &:hover,
      &:hover .icon {
        color: var(--hover_alt);
        text-decoration: none;
      }

      .icon {
        color: var(--primary_alt);
      }
    }
  }
}
