.profile-tile {
  &__card {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    border: 0;
    border-radius: 0;
    background-color: $c_white;

    @include media-breakpoint-down(xs) {
      max-width: 300px;
      margin-right: 14px;
    }

    @include media-breakpoint-up(sm) {
      padding-right: 30px;
      padding-left: 30px;
      margin-bottom: 50px;
    }

    @include media-breakpoint-up(md) {
      padding-right: 20px;
      padding-left: 20px;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 50px;
      padding-left: 50px;
    }

    &__img--nomobile {
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    &__img {
      padding-top: 0;

      @include media-breakpoint-down(xs) {
        max-width: 142px;
        margin-top: 20px;
      }
    }

    &__body {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;

      .card-title .hdl {
        margin-bottom: 2px;
        color: $c_slate;
        font-size: 20px;

        @include media-breakpoint-up(sm) {
          font-size: 22px;
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      background-color: $c_white;

      @include media-breakpoint-down(xs) {
        border-top: 0;
        padding-bottom: 0;

        .iconlist:last-child {
          margin-bottom: 1rem;
        }
      }
    }

    .collapse.dont-collapse-sm {
      @include media-breakpoint-up(sm) {
        display: block;
        height: auto !important;
        visibility: visible;
      }
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  &__table {
    td {
      padding: 5px 0;
    }
  }

  &__column--right {
    text-align: right;

    &__text {
      font-weight: $font-weight-demi;
    }

    .info-popup {
      display: inline-block;

      .dropdown-toggle {
        position: static !important;
      }

      .dropdown-menu.show {
        min-width: 250px !important;
        max-height: 100vw;
      }
    }
  }

  &__card__footer {
    .icon {
      color: var(--primary);
    }

    .iconlist__content {
      color: $c_slate;
    }
  }
}

.toggle--footer {
  align-self: flex-end;
  padding-left: 10px;
  margin-bottom: 2rem;

  @include media-breakpoint-up(sm) {
    display: none;
  }

  &[aria-expanded='true'] .icon {
    transition: transform 0.2s ease-in-out;
    transform: rotate(180deg);
  }

  &[aria-expanded='false'] .icon {
    transition: transform 0.2s ease-in-out;
    transform: rotate(0deg);
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}
