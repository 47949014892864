.highlight-text {
  /*Fix for IE*/
  [class^='col'] {
    padding: 0;
  }

  &__row {
    position: relative;
    display: flex;

    .btn {
      margin-top: 10px;
    }
  }

  &__mp4 {
    &::before {
      padding: 0 !important;
    }

    @include aspect-ratio(16, 9);
    padding: 0;
    z-index: 100;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      max-height: calc(100% - 100px);
      margin-top: 50px;
      margin-bottom: 50px;
    }

    video {
      max-height: 100%;
      max-width: 100%;
      margin: 0;
      width: 100%;
      height: 100%;
    }

    .play-pause-wrapper {
      display: flex;
      justify-content: flex-end;
      z-index: 1001 !important;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(-20px, -40px);

      @include media-breakpoint-down(md) {
        display: none;
      }

      .play-icon, .pause-icon {
        z-index: 1001;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        border: 2px solid rgba(0,0,0, 0.2);
        background-color: $c_white;
        height: 50px;
        width: 50px;
        margin-right: -1.25%; // cancel out the bootstrap padding on the parent element

        &:hover {
          border: 4px solid rgba(0,0,0, 0.2);
          cursor: pointer;
        }

        &:focus-visible {
          border: 4px solid rgba(0,0,0, 0.2);
          outline: none;
        }

        .icon {
          height: 12px;
          width: 9.17px;
        }
      }
    }
  }

  &__image {
    @include aspect-ratio(16, 9);
    padding: 0;
    z-index: 100;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      max-height: calc(100% - 100px);
      margin-top: 50px;
      margin-bottom: 50px;
    }

    img {
      margin-top: 0 !important;
    }

    & ~ .highlight-text__info {
      //W = (container_size * 4) / 12 => col-4
      //H = W*9/16 => aspect-ratio 16:9
      //min-height =  H+margin_top+margin_bottom
      @include media-breakpoint-up(md) {
        min-height: 280px;
      }
      @include media-breakpoint-up(lg) {
        min-height: 320px;
      }
      @include media-breakpoint-up(xl) {
        min-height: 460px;
      }
    }
  }

  &__info {
    margin: 0;
    text-align: center;
    background-color: $c_concrete;
    color: $c_slate;

    @include media-breakpoint-down(sm) {
      position: static;
    }

    @include media-breakpoint-up(md) {
      text-align: left;
    }
    .hdl {
      margin-bottom: 20px;
      font-weight: $font-weight-medium;
    }
    &__wrapper {
      padding-top: 20px;
      padding-right: 14px;
      padding-left: 14px;

      @include media-breakpoint-up(sm) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
      @include media-breakpoint-up(md) {
        padding-top: 90px;
        padding-bottom: 50px;
      }
      @include media-breakpoint-up(xl) {
        padding-bottom: 90px;
      }
    }
  }

  &__icon-container {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    transform: translateY(-50%);
    z-index: 100;

    @include media-breakpoint-up(md) {
      justify-content: flex-start;
      padding-left: 1.25%;
      transform: none;
    }
  }

  &__icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 39px;
    width: 39px;
    background-color: var(--primary);

    @include media-breakpoint-up(sm) {
      width: 54px;
      height: 54px;
    }

    .icon {
      width: 20px;
      height: 20px;
      color: var(--primary_contrast);

      @include media-breakpoint-up(sm) {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__row--reverse {
    position: relative;
    display: flex;
    flex-direction: row-reverse;

    .highlight-text__image {
      left: 0;
    }

    .highlight-text__mp4 {
      right: auto;
      left: 0;

      .play-pause-wrapper {
        bottom: 0;
        transform: translate(-20px, -40px);
      }
    }

    .highlight-text__icon-container {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      transform: translateY(-50%);
      z-index: 100;

      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
        justify-content: flex-start;
        padding-left: 1.25%;
        transform: none;
      }
    }

    .btn {
      margin-top: 10px;
    }
  }

  &__row--full {
    position: relative;
    display: flex;

    .highlight-text__info {
      @include media-breakpoint-up(sm) {
        text-align: left;
      }
    }

    .highlight-text__icon-container {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-right: 14px;
      transform: translateY(-50%);
      z-index: 100;

      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
        justify-content: flex-start;
        transform: none;
      }
    }

    .highlight-text__info__wrapper {
      padding-top: 50px;
      padding-bottom: 50px;

      @include media-breakpoint-up(sm) {
        padding-top: 90px;
      }
    }

    .btn {
      margin-top: 10px;
    }
  }
}
