.media {
  flex-direction: column;

  .container {
    height: 100%;
  }

  .media-subtitle__row {
    transform: translateY(-40px);
    margin-bottom: -40px;
  }

  .media-subtitle {
    padding: 15px 20px;
    background-color: $c_concrete;
  }
}
