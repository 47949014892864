.image-set {
  .container {
    @include media-breakpoint-between(xs, sm) {
      max-width: 100%;
    }
  }

  .swiper {
    @include media-breakpoint-between(xs, sm) {
      width: 100%;
    }
  }

  .swiper-slide {
    @include media-breakpoint-only(xs) {
      width: 90% !important;
    }
  }

  &__image {
    @include aspect-ratio(16, 9);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .zoomable {
    cursor: zoom-in;
  }

  .image-set__toggle:not(.zoomable) {
    cursor: default;
  }

  &__container--modal .swiper-pagination {
    @include media-breakpoint-up(md) {
      margin-top: 20px;
    }
  }
}
