@import './modal--slider';

.modal {
  .close {
    color: $c_slate;
    opacity: 1 !important;

    &:hover {
      color: inherit;
    }

    &:focus {
      outline: none;
    }

    .icon {
      pointer-events: none;
      fill: $c_slate;
    }

    .icon-close {
      width: 20px;
      height: 20px;
    }
  }
}

.modal--fullscreen {
  background-color: $c_white;
  padding: 0;

  // remove bootsrap: padding-right: 17px
  &-open,
  &.show {
    padding-right: 0 !important;
  }

  .modal-content {
    border: 0;
  }

  .modal-dialog {
    max-width: 100%;
    margin: auto;
  }

  &.modal--image {
    .swiper-container-horizontal .swiper-pagination-bullets {
      bottom: 0;
      margin-top: 10px;
    }
  }
}

// videoplayer inside modal box
.modal--video .close {
  z-index: 10;
  opacity: 1;

  .icon {
    @include media-breakpoint-only(xs) {
      fill: $c_white;
    }
  }
}

// modal triggered by add-to-cart-button
.modal--cart {
  .modal-dialog {
    display: flex;
    align-items: center;
    height: 100%;
    margin: auto;
  }

  .modal-content {
    box-shadow: 0 0 15px 0 rgba($c_black, 0.2);
  }

  .close {
    background-color: var(--primary);
    border: 5px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    background-clip: padding-box;
    width: 35px;
    height: 35px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      color: $c_white;
      width: 10px;
      height: 10px;
    }
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 30px;

    .modal-cart__processing-text {
      text-align: center;
      margin-bottom: 60px;
    }
  }

  .modal-cart {
    &__body {
      .btn {
        display: flex;
        width: auto;
        justify-content: center;
        margin: auto;
      }

      .btn--order {
        margin-bottom: 15px;

        &:focus {
          opacity: 0.75;
        }
      }
    }

    &__label {
      font-weight: $font-weight-medium;
    }
  }
}
