.stage-slider-mag {
  color: $c_white;

  // add missing bottom margin if
  // no pagination is available when
  // only one slide are used
  &__single-slide {
    margin-bottom: 25px;
  }

  .swiper-slide {
    overflow: hidden;
  }

  &__container {
    z-index: 0;
  }

  // image
  &__image {
    min-height: 810px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    @include media-breakpoint-only(xs) {
      min-height: 351px;
    }

    @include media-breakpoint-only(sm) {
      min-height: 461px;
    }

    @include media-breakpoint-only(md) {
      min-height: 576px;
    }

    @include media-breakpoint-up(md) {
      @include aspect-ratio(21, 9);
    }
  }

  // content
  &__wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .container {
      height: 100%;
      position: relative;
    }
  }

  &__slide-content {
    margin-top: 414px;
    font-weight: 500;

    @include media-breakpoint-only(xs) {
      margin-top: 70px;
    }

    @include media-breakpoint-only(sm) {
      margin-top: 80px;
    }

    @include media-breakpoint-only(md) {
      margin-top: 205px;
    }

    .hdl {
      color: inherit;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 67px;
      line-height: 80px;
      font-size: 110px;

      @include media-breakpoint-only(xs) {
        font-size: 40px;
        text-align: left;
        line-height: 34px;
        margin-bottom: 29px;
      }

      @include media-breakpoint-only(sm) {
        font-size: 80px;
        line-height: 66px;
        margin-bottom: 42px;
      }

      @include media-breakpoint-only(md) {
        font-size: 90px;
        line-height: 77px;
        margin-bottom: 50px;
      }
    }

    &__intro {
      display: block;
      font-size: 60px;
      letter-spacing: 1.9px;
      margin-bottom: 20px;

      @include media-breakpoint-only(xs) {
        font-size: 20px;
      }

      @include media-breakpoint-only(sm) {
        font-size: 39px;
      }

      @include media-breakpoint-only(md) {
        font-size: 50px;
      }
    }

    &__wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
      }

      p {
        font-size: 31px;
        margin-bottom: 0;
        font-weight: 300;
        letter-spacing: 1.7px;

        @include media-breakpoint-only(xs) {
          font-size: 20px;
          text-align: left;
        }

        @include media-breakpoint-only(sm) {
          font-size: 28px;
        }

        @include media-breakpoint-only(md) {
          font-size: 28px;
        }
      }
    }
  }

  .btn-primary,
  .btn-primary:active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:hover {
    background-color: $c_white !important;
    border-color: $c_white !important;
    color: $c_slate !important;
    max-width: 200px;

    @include media-breakpoint-only(xs) {
      margin-top: 20px;
    }

    @include media-breakpoint-only(sm) {
      margin-top: 26px;
    }

    @include media-breakpoint-down(sm) {
      /*
      align-self: center;
      margin-left: auto;
      margin-right: auto;
      */
    }

    @include media-breakpoint-up(md) {
      max-width: 210px;
    }
  }

  // Swiper styles
  .pagination-wrapper {
    .pause-icon {
      margin: 0 15px;

      .icon-pause {
        width: 15px;
      }

      &:hover {
        &::before {
          content: '';
          max-width: 45px;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.2);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 10px;
          z-index: -1;
        }
      }

      &:focus-visible {
        // disable the custom focus blue border
        outline: none;

        &::before {
          content: '';
          max-width: 48px;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.2);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 2px solid var(--hover);
          border-radius: 10px;
          z-index: -1;
        }
      }
    }

    .play-icon {
      margin: 0 15px;

      .icon-play {
        width: 18px;
      }

      &:hover {
        &::before {
          content: '';
          max-width: 48px;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.2);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 10px;
          z-index: -1;
        }
      }

      &:focus-visible {
        // disable the custom focus blue border
        outline: none;

        &::before {
          content: '';
          max-width: 48px;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.2);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 2px solid var(--hover);
          border-radius: 10px;
          z-index: -1;
        }
      }
    }

    .swiper-pagination-bullet {
      border-color: $c_white;
      background-color: transparent;
    }

    .swiper-pagination-bullet-active {
      background-color: $c_white;
      border-color: $c_white;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;

    display: none;
  }

  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      display: block;

      .icon {
        position: absolute;
        fill: $c_white;
        color: $c_white;
        filter: drop-shadow(0 0 3px rgba($c_black, 0.7));

        @include media-breakpoint-up(md) {
          height: 50px;
          width: 50px;
        }
      }
    }

    .swiper-button-disabled {
      display: none;
    }
  }

  .swiper-button-next {
    right: 10px;
    left: auto;
  }

  .swiper-button-prev {
    left: 10px;
    right: auto;
  }

  .content--video {
    position: relative;
    background-color: $c_black;

    @include media-breakpoint-down(sm) {
      min-height: 300px;
    }

    @include media-breakpoint-up(md) {
      @include aspect-ratio(21, 9);
    }
  }

  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    margin: 0;

    @include media-breakpoint-down(sm) {
      min-height: 100%;
    }
  }
}
