.tab-navigation {
  overflow-x: auto;
  overflow-y: hidden;
  .nav-tabs {
    flex-wrap: nowrap;
    min-width: max-content;
    border: 0;

    &.center {
      justify-content: center;
    }

    .nav-item {
      text-align: center;
      flex: 0 0 auto;
    }

    .nav-link {
      background-color: transparent;
      font-weight: $font-weight-normal;
      color: var(--primary_alt);
      border: 0;

      &.active {
        font-weight: $font-weight-demi;
        border-bottom-color: var(--primary);
        border-bottom-width: 5px;
        border-bottom-style: solid;
      }

      &.tabDisabled {
        pointer-events: none;
        color: var(--disabled);
        font-weight: $font-weight-normal;
        border: none;
      }
    }
  }
}
