@import './animation';

.productfilter {
  p {
    margin: 0;
  }

  &__bar {
    display: none;
    flex-direction: column;
    background-color: $c_white;
    margin-top: auto;
    padding: 24px 0;
    z-index: $zindex-fixed;

    @include media-breakpoint-down(sm) {
      position: sticky;
      top: -1px;
      z-index: calc(#{$zindex-fixed} - 1);
    }

    .btn-clean {
      color: var(--primary_alt);

      .icon {
        color: $c_slate;
        margin-right: 15px;
      }
    }

    &--sticky {
      position: sticky;
      top: 0;
      transition: 0.1s;
    }

    .container {
      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }
  }

  &__content {
    flex-direction: column;
    background: $c_white;
    z-index: $zindex-fixed;
    transform: translateY(0);

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    &.open {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: auto;
      transform: translateY(-100%);
      z-index: 10001;
    }

    &.opening {
      animation: content_down 200ms ease-in-out normal;
    }

    &.closing {
      animation: content_down 200ms ease-in-out reverse;
    }

    &.open {
      transform: translateY(0);

      .productfilter__bar {
        display: flex;
      }
    }

    .row {
      justify-content: flex-end;

      @include media-breakpoint-up(lg) {
        justify-content: center;
      }
    }

    .card {
      @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: center;
      }

      &--filter {
        background: transparent;
        border-radius: 0;
        border: 0;
        min-height: 70px;
      }

      &__header {
        padding: 15px 0;

        @include media-breakpoint-up(md) {
          flex: 1 0 20%;
        }
      }

      &__body {
        padding-right: 15px;
        padding-left: 25px;

        @include media-breakpoint-up(md) {
          flex: 1 0 80%;
          padding: 15px;
        }

        &--center {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 15px;

          @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: center;
          }
        }

        &--slider {
          padding-bottom: 40px;

          @include media-breakpoint-up(xl) {
            padding-bottom: 0;
            flex: 1 0 40%;
          }
        }
      }

      &--slider {
        flex-direction: column;
        justify-content: flex-start;

        @include media-breakpoint-up(md) {
          margin-bottom: 50px;
        }

        @include media-breakpoint-up(xl) {
          flex-direction: row;
        }
      }

      &__slider {
        display: flex;
        width: 50%;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          width: 100%;
        }

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }
      }

      p {
        font-size: 15px;
      }
    }

    .list {
      &--filter li {
        @include media-breakpoint-up(md) {
          display: inline-block;
        }
      }
    }

    .link {
      padding: 10px;

      @include media-breakpoint-up(md) {
        &--filter {
          display: block;
          text-align: right;
          pointer-events: none;

          .icon {
            display: none;
          }
        }
      }

      &--filter {
        color: $c_slate;
        font-size: 14px;
        width: 100%;
        padding: 0;
        font-weight: $font-weight-demi;

        .icon {
          fill: $c_slate;
          width: 14px;
          height: 14px;
          margin-right: 11px;
          transform: rotate(0);
          transition: transform 250ms ease-in-out;
        }

        &.collapsed .icon {
          transform: rotate(-90deg);
        }
      }
    }

    .filter--slider {
      width: 100%;
      padding-left: 7px;
      padding-right: 7px;
    }

    .collapse {
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }

  &__item {
    display: flex;
  }

  .filter__name {
    margin-right: 0.5em;
  }

  &__action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .full {
    height: 100%;

    .container:last-child {
      margin-top: auto;
    }
  }
}
