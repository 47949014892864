.sticky-nav {
  background-color: #fff;
  overflow-x: hidden;
  z-index: 5000;
  border: 1px solid var(--primary_alt);
  border-left: none;
  border-right: none;
  top: 0 !important;

  .navbar {
    justify-content: flex-start;
  }

  .sticky-nav__navbar {
    .sticky-nav__toggler {
      color: inherit;

      .icon {
        width: 12px;
        height: 12px;
      }
    }

    .nav-link {
      font-weight: 400;
      margin-left: 43px;
    }
  }

  &--active {
    @include media-breakpoint-between(xs, sm) {
      display: none;
    }
  }

  &__toggler {
    height: 50px;
    text-align: left;

    &__label {
      font-size: 14px;
      font-weight: 400;
      margin-left: 5px;
      color: var(--primary_alt);

      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }

      @include media-breakpoint-up(md) {
        display: none;
      }

      &:hover {
        color: var(--primary_alt);
        text-decoration: none;
      }
    }
  }
}
