.form {
  &-group {
    margin-top: $form-group-margin-bottom;
  }

  &-group-dark {
    .form-control {
      background-color: $c_concrete;
      border: 2px solid $c_concrete;
    }

    .btn-select,
    .dropdown-menu li {
      background-color: $c_concrete;
    }

    .bootstrap-select .dropdown-toggle {
      border: 2px solid $c_concrete;
    }
  }

  &-group-light {
    label {
      font-weight: 600;
    }

    .form-control {
      background-color: $c_white;
      border: 2px solid $c_white;

    }
  }

  span.required {
    color: $c_danger;
  }

  &-control {
    font-size: 15px;
    font-weight: $font-weight-normal;
    color: $c_slate;
    line-height: normal;
    height: auto;
    padding: 14px 20px;
    border-radius: 0;
    border: 2px solid $c_white;

    @include media-breakpoint-up(lg) {
      padding: 16px 20px;
    }

    &:focus {
      border-color: var(--primary);
      box-shadow: none;
    }

    &::placeholder {
      font-weight: $font-weight-normal;
      color: $c_slate;
      font-size: 15px;
      opacity: 0.5;
    }

    &.form-control--error {
      border: 1px solid $c_danger;
    }
  }

  &-check,
  &-check-inline {
    padding: 0;
  }

  &-check &-check-input,
  &-check-inline &-check-input {
    position: absolute;
    top: 0;
    width: 18px;
    height: 18px;
    outline: 0;
    margin: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;

    ~ .form-check-label {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 0 0 28px;
      font-weight: $font-weight-demi;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
      }

      &::before {
        width: 18px;
        height: 18px;
        background: transparent;
        border-style: solid;
        border-width: 2px;
        border-color: var(--primary);
      }

      &::after {
        opacity: 0;
        transition: opacity 0.3s;
      }
    }

    &:disabled ~ .form-check-label::before {
      opacity: 0.4;
    }

    &[type='radio'] ~ .form-check-label::before {
      border-radius: 50%;
    }

    &:checked ~ .form-check-label::after {
      position: absolute;
      opacity: 1;
      transition: opacity 0.3s;
      transform: rotate(-45deg);
      top: 6px;
      left: 6px;
      width: 7px;
      height: 4px;
      border-width: 2px;
      border-style: solid;
      border-color: var(--primary_alt);
      border-top-style: none;
      border-right-style: none;
    }

    &[type='radio']:checked ~ .form-check-label::after {
      position: absolute;
      opacity: 1;
      transition: opacity 0.3s;
      top: 6px;
      left: 6px;
      width: 6px;
      height: 6px;
      border: 0;
      border-radius: 50%;
      background-color: var(--primary_alt);
    }
  }

  label {
    margin-bottom: 0;
  }
}
