.list {
  $component: &;
  @include list-unstyled();

  &-group-item {
    padding: 0;
  }

  &.list--ordered.list--icon {
    > .list__item {
      list-style-type: decimal;
      margin-left: 20px;
    }
  }

  &__item {
    list-style-type: disc;
    list-style-position: outside;
    line-height: 27px;
    padding-left: 10px;
    margin-left: 7px;

    &--large {
      font-size: 18px;
      line-height: 30px;
    }

    &__wrapper {
      overflow: hidden;
    }

    #{$component}--ordered & {
      list-style-type: decimal;
    }

    #{$component}--icon & {
      list-style-type: none;

      .icon {
        width: 7px;
        height: 7px;
        margin-right: 7px;
        margin-top: 10px;
        color: var(--primary);
        float: left;
      }
    }
  }

  &--icon > .list__item {
    margin-left: 0;
    padding-left: 0;

    .sublist {
      padding-left: 0;
    }
  }
  &--iconsmall > .list__item > .icon {
    margin-top: 7px;
    margin-right: 10px;
    width: 12px;
    height: 12px;
  }

  &__item &__item {
    list-style-type: disc;

    #{$component}--ordered & {
      list-style-type: disc;
    }
  }

  &--icon &__item &__item {
    list-style-type: none;
  }
}
