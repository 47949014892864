.reference-object-snippet {
  &__container {
    background-color: $c_concrete;
  }

  &__row {
    margin: 0;
  }

  &-campaign-included {
    .reference-object-snippet__info {
      position: relative;

      &-highlight {
        cursor: pointer;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../images/eyecatcher.svg');
        background-position: 0;
        background-size: 100%;
        background-repeat: no-repeat;
      }

      &-details {
        padding-left: 30px;

        @include media-breakpoint-up(sm) {
          padding-left: 20px;
        }
      }
    }
  }

  &__info {
    padding: 10px;

    @include media-breakpoint-up(sm) {
      padding: 15px;
    }

    &-details {
      margin-bottom: 15px;
      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 20px;
        margin-top: 5px;
      }

      .reference-object-snippet__details-link {
        @include media-breakpoint-down(xs) {
          margin-bottom: 15px;
        }

        span {
          margin: 0;
        }
      }

      .hdl {
        display: flex;
      }

      .downloads__link {
        padding: 0;
      }
    }

    table {
      width: 100%;
    }

    tbody {
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }

    tr {
      &.odd {
        background-color: $c_iron;
      }

      @include media-breakpoint-down(lg) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-down(sm) {
        display: grid;
        grid-template-columns: 1fr;
      }
    }

    td {
      width: 25%;
      font-weight: $font-weight-demi;
      padding: 10px 14px;
      vertical-align: top;

      @include media-breakpoint-down(lg) {
        width: 100%;

        &:last-child {
          padding-bottom: 10px;
        }
      }

      @include media-breakpoint-down(sm) {
        padding-top: 5px;

        &:first-child {
          padding-top: 10px;
        }
      }

      label {
        display: block;
        font-weight: $font-weight-demi;
        opacity: 0.75;
        font-size: 13px;
        margin-bottom: 0;
      }
    }
  }

  &__details {
    border-top: 1px solid $c_iron;
    padding: 10px;

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding: 15px;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      justify-content: start;
      border-top: 0;
      border-left: 1px solid $c_iron;
    }

    &-variants {
      display: flex;
      margin-bottom: 6px;

      &-title {
        flex: 0 0 80px;
        font-weight: 600;
      }

      &-list {
        &-tag {
          justify-content: center;
          color: $c_slate;
          margin-right: 5px;
          opacity: .5;

          &:hover {
            opacity: 1;
            background-color: $c_slate;
            color: $c_white;
            margin-right: 5px;
          }
        }
      }
    }

    .btn {
      display: flex;
      align-items: center;
      height: fit-content;
      padding-top: 0px;
      padding-bottom: 0px;

      > span {
        margin-right: 15px;
        text-align: left;
      }

      label {
        display: block;
        text-align: left;
        font-weight: $font-weight-demi;
        opacity: 0.75;
        font-size: 13px;
        margin-bottom: 0;
      }

      .icon {
        margin-right: 15px;
        color: inherit;
      }
    }

    &-headline {
      margin-bottom: 0;

      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;
      }
    }
  }

  &__picture-toggle {
    position: relative;
    margin-top: 24px;
    display: block;
    max-width: 114px;

    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 24px;
    }
  }

  &__icon {
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: var(--primary_contrast);
    background-color: var(--primary);

    .icon {
      color: inherit;
      width: 17px;
      height: 17px;
    }
  }

  &__details-link {
    display: inline-flex;

    .icon {
      width: 22px;
      height: 22px;
      margin-left: 10px;
    }
  }

  .downloads__link {
    margin-bottom: 0;
  }

  & + & {
    margin-top: 24px;
  }

  .modal {
    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--primary);
      color: var(--primary_contrast);
      border: 5px solid $c_iron;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 0;
      margin-top: -5px;
      margin-right: 0;

      .icon {
        color: inherit;
        flex-shrink: 0;
        width: 10px;
        height: 10px;
      }
    }
  }

  .modal-dialog {
    max-width: 650px;
  }

  .modal-content {
    box-shadow: 0 0 15px 0 rgba($c_black, 0.2);
  }
}

.tooltip-inner {
  border-radius: 0;
}
