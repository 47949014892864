.section {
  padding: 30px 10px;

  @include media-breakpoint-up(md) {
    padding: 40px 0;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  // WGWEB-807 - Hash links with sticky navigation
  &:target:before {
    content: '';
    display: block;
    height: 110px; // fixed header height
    margin: -110px 0 0; // negative fixed header height
  }

  &--offgrid {
    margin: 0 auto;
    max-width: $grid-offgrid-size;
    width: 100%;
  }

  &--bglight {
    background-color: $c_white;

    + .section--bglight {
      padding-top: 0;
    }
  }

  &--bgdark {
    background-color: $c_concrete;

    + .section--bgdark {
      padding-top: 0;
    }
  }

  &--bgblack {
    background-color: $c_black;
  }

  &--pto {
    padding-bottom: 0;
  }
}
