.search-result-product-filters {
  padding-top: 0;

  &__form {
    display: flex;
    margin-bottom: 20px;

    .select {
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }

      .dropdown-toggle {
        background-color: #f3f3f3;
        border: 2px solid #f3f3f3;
      }
    }
  }
}
