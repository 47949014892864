// Remove some Google Styles
$infobubble_fg: $c_white;
$infobubble_bg: $c_chathamsblue;

.gm-style {
  font-family: $font-family-base !important;

  .gm-style-iw {
    border-radius: 0;
    padding: 0;
    min-width: 220px !important;
    max-height: none !important;
  }

  .gm-style-iw-c {
    @include media-breakpoint-up(sm) {
      max-width: 100% !important;
      // max-height: 100% !important;
    }
  }

  .gm-style-iw-d {
    max-width: 100vw !important;
    overflow: visible !important;
    max-height: none !important;
  }

  // .gm-style-iw-t::after {
  //   // background: $infobubble_bg;
  //   width: 0px;
  //   height: 0px;
  //   left: -7.5px;
  //   transform: rotate(360deg);
  //   border-style: solid;
  //   border-width: 11px 11px 0 11px;
  //   border-color: $c_white transparent transparent transparent;
  //   background-color: transparent;
  //   box-shadow: none;
  // }

  .gm-ui-hover-effect {
    top: 20px !important;
    right: 10px !important;
    width: 40px !important;
    height: 40px !important;
    opacity: 1;

    @include media-breakpoint-up(sm) {
      right: 20px !important;
    }
  }
}

.infobubble {
  min-width: 220px;
  //
  // @include media-breakpoint-up(md) {
  //   min-width: 580px;
  // }
  //
  // @include media-breakpoint-up(lg) {
  //   min-width: 750px;
  // }

  &__head {
    display: flex;
    min-height: 80px;
    align-items: center;
    padding: 10px 60px 10px 10px;
    font-size: 15px;
    font-weight: bold;
    color: $c_slate;
    border-bottom: 1px solid $c_iron;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
      padding: 10px 80px 10px 20px;
    }
  }

  .container {
    padding: 10px;

    @include media-breakpoint-up(sm) {
      padding: 20px;
    }
  }

  &__contacts {
    display: table;
    margin-bottom: 20px;
  }

  // &__image,
  // &__contact {
  //   padding-top: 20px;
  //   padding-bottom: 20px;
  // }
  //
  // &__brands {
  //   @include media-breakpoint-up(sm) {
  //     border-left: 1px solid $c_concrete;
  //     padding: 10px 20px;
  //   }
  // }
  //
  // &__image {
  //   border-right: 1px solid $c_iron;
  //
  //   img {
  //     max-width: 100%;
  //   }
  // }

  // &__contact {
  //   @include media-breakpoint-up(sm) {
  //     border-right: 1px solid $c_iron;
  //   }
  // }

  &__ico {
    display: table-cell;
  }

  &__txt {
    display: table-cell;
    padding-left: 10px;
  }

  &__logo {
    display: block;
    padding: 10px 0;

    &.johndeere {
      border-top: 1px solid $c_iron;
      padding-top: 20px;
      margin-top: 10px;
    }
  }
}
