.teaser-gallery {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;

  &__container {
    position: relative;
  }

  &__image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .container {
    z-index: 1000;
  }

  .content-body {
    padding: 21px 10px;

    @include media-breakpoint-up(md) {
      padding: 30px 10px;
    }

    @include media-breakpoint-up(lg) {
      width: 470px;
      padding: 40px 50px;
    }

    @include media-breakpoint-up(lg) {
      padding: 40px 60px;
    }

    .link,
    .btn {
      margin-top: 10px;
    }
  }

  .swiper-counter {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 57px;
    height: 34px;
    background-color: var(--primary);
    color: var(--primary_contrast);
    z-index: 1000;

    @include media-breakpoint-up(lg) {
      height: 70px;
      width: 132px;
      font-size: 28px;
    }

    span {
      padding: 0 5px;

      @include media-breakpoint-up(lg) {
        padding: 0 10px;
      }
    }
  }

  .swiper:not(.teaser-gallery__text) .swiper-slide {
    background-color: $c_white;
  }

  .swiper-button-next,
  .swiper-button-prev,
  .swiper-button-disabled {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;

    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;

    .icon {
      color: $c_white;
    }
  }

  .swiper-button-next {
    right: 10px;
    left: auto;
  }

  .swiper-button-prev {
    left: 10px;
    right: auto;
  }

  .gradient--right,
  .gradient--left {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
  }

  &:hover {
    .swiper-button-next:not(.swiper-button-disabled),
    .swiper-button-prev:not(.swiper-button-disabled) {
      @include media-breakpoint-up(lg) {
        visibility: visible;
        opacity: 1;
      }
    }

    .gradient {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 220px;
      }
    }

    .gradient--left {
      left: 0;
      background: linear-gradient(90deg, rgba($c_black, 0.5) 0%, rgba($c_black, 0) 100%);
      visibility: visible;
      opacity: 1;
      z-index: 100;
    }

    .gradient--right {
      right: 0;
      background: linear-gradient(270deg, rgba($c_black, 0.5) 0%, rgba($c_black, 0) 100%);
      visibility: visible;
      opacity: 1;
      z-index: 100;
    }
  }

  .teaser-gallery__thumbs {
    .swiper-slide-thumb-active {
      opacity: 1;

      .hidden {
        visibility: visible;
        height: 8px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: var(--primary);
        z-index: 1000;
      }
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
