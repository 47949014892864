.download-snippet {
  &__items {
    display: inherit;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $c_graychateau;
  }

  &__item {
    padding-top: 15px;

    &--intended {
      padding-left: 30px;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 20px;
    }
  }

  .btn-primary {
    margin-bottom: 5px;
  }

  .link {
    display: inline;
  }
}
