.brand-selector {
  $popoverWidth: 240px;
  $arrowHeight: 35px;
  $arrowWidth: 120px;

  &__item {
    flex: 1;
    min-width: 50%;

    &:hover {
      text-decoration: none;
    }

    img {
      display: block;
      margin: auto;
    }
  }

  &__title {
    text-transform: uppercase;
    text-align: center;
    margin-top: 5px;
    font-weight: $font-weight-demi;
  }

  .popover {
    width: $popoverWidth;
    background: var(--primary);
    border: none;
    // box-shadow: 0 0 10px 0 rgba($c_black, 0.5);
    // filter: drop-shadow(0 0 10px 0 rgba($c_black, 0.5);

    a {
      color: var(--primary_contrast);

      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }

    ul {
      padding: 0;
      list-style: none;
    }

    li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .arrow {
      width: 100%;
      left: 0 !important;
      margin: 0 !important;
      height: $arrowHeight !important;

      &::before {
        display: none !important;
        box-shadow: 0 0 10px 0 rgba($c_black, 0.5);
      }
    }
  }

  .popover-body {
    padding: 0;
  }

  &--6 {
    @include media-breakpoint-up(lg) {
      .brand-selector__item {
        min-width: inherit;
      }
    }
  }

  &--7 {
    @include media-breakpoint-up(md) {
      .brand-selector__item {
        min-width: 25%;
      }
    }
  }

  // light primary color needs dark text
  // .popover.john-deere a {
  //   color: $c_black;
  // }

  .bs-popover-top {
    margin-bottom: $arrowHeight;
    padding: 20px 25px 15px 25px;

    > .arrow {
      bottom: -#{$arrowHeight + 1px};
    }
  }

  .bs-popover-bottom {
    margin-top: $arrowHeight;
    padding: 15px 25px 20px 25px;

    > .arrow {
      top: -#{$arrowHeight + 1px};
    }
  }

  .bs-popover-top > .arrow::after {
    border-top-color: var(--primary);
    border-width: $arrowHeight $arrowWidth 0;
  }

  .bs-popover-bottom > .arrow::after {
    border-bottom-color: var(--primary);
    border-width: 0 $arrowWidth $arrowHeight $arrowWidth;
  }
}
