.profile-tiles {
  .card-header .btn-toggle {
    // deviation from accordion paddings in mobile
    @include media-breakpoint-down(sm) {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px;
      padding-right: 40px;
    }
  }

  &__col {
    display: flex;

    @include media-breakpoint-down(xs) {
      background-color: $c_white;
      flex-direction: row;
      border-bottom: 1px solid rgba($c_black, 0.125);
      max-width: 300px;
    }
  }

  .hdl-group {
    flex: 1 1 auto;
    margin-right: 15px;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: baseline;
    }

    .hdl {
      text-align: left;

      // deviation from hdl--4 size in mobile
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }
  }

  .accordion__label {
    margin-bottom: 0;
    text-align: right;

    // deviation from p.intro size and weight in mobile
    @include media-breakpoint-down(sm) {
      font-size: 13px;
      font-weight: $font-weight-medium;
    }

    @include media-breakpoint-up(sm) {
      padding-right: 28px;
    }

    @include media-breakpoint-up(xl) {
      padding-right: 50px;
    }
  }

  .card-header .icon {
    @include media-breakpoint-down(sm) {
      position: absolute;
      top: calc(50% - (25px / 2));
      right: 15px;
    }
  }

  .card-header.card-header--noAccordion {
    padding: 23px 30px;
    border: none;
  }

  .card-header.card-header--noAccordion .hdl.hdl--4 {
    font-weight: 600;
  }
}
