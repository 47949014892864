.navbar {
  position: relative;
  color: $c_white;
  padding: 0;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    .animate {
      &--fadeIn {
        opacity: 0;
        animation: fadeInRight $transitionSpeed;

        &.show {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  .navbar-navigation {
    display: flex;
    align-items: center;
    width: max-content;

    .nav-item:not(.nav-item--service),
    .nav-link:not(.nav-link--back) {
      display: flex;
    }

    .nav-link {
      margin: 0;
    }

    > .nav-item {
      margin: 0 0 0 20px;

      @include media-breakpoint-up(lg) {
        margin: 0 0 0 50px;
      }

      .contact-text {
        font-weight: 400;
        line-height: 24px;
        color: $c_white;
        font-size: 15px;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }

    > .nav-item:first-child {
      margin: 0;
    }

    > .nav-item--service {
      margin: 0 20px 0 0;

      @include media-breakpoint-up(lg) {
        margin: 0 50px 0 0;
      }
    }

    > .nav-item:last-child {
      @include media-breakpoint-up(lg) {
        margin: 0 50px 0 0;
      }
    }
  }

  .navbar-brand {
    position: absolute;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 50px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    margin-right: 0;
    font-size: initial;
    line-height: inherit;
    white-space: nowrap;
    z-index: $zindex-modal-backdrop;

    @include media-breakpoint-up(lg) {
      width: auto;
      min-height: 80px;
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
      z-index: 10000;
    }

    &:only-child {
      position: relative;
      right: 0;
    }
  }

  // Neue Bootstrap Version (4.4) setzt alle .container innerhalb .navbar (.navbar .container) auf display: flex (bug oder feature???)
  // bei der vorherigen Version waren es nur die direct children .navbar > .container
  // um keine Änderungen am Markup vorzunehmen wird display mit block überschrieben.
  // https://github.com/twbs/bootstrap/pull/29118
  .container {
    display: block;

    &-fluid {
      flex-wrap: nowrap;
    }
  }

  p {
    color: $c_white;
  }

  &--offgrid {
    margin: 0 auto;
    max-width: $grid-offgrid-size;
    width: 100%;

    > .container-fluid {
      padding: 0;
    }
  }

  &-active {
    overflow: visible;
  }

  ul {
    padding: 0;
    margin: 0;
    height: auto;
    color: transparent;
  }

  img {
    border-radius: 0;
  }

  .icon {
    color: $c_white;

    &.show {
      display: block !important;
    }

    &.hidden {
      display: none;
    }
  }

  span.hidden {
    display: none;
  }

  .navbar-bar {
    position: static;
    background-color: $c_slate;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    min-height: 50px;
    padding-right: $grid-gutter-width;
    z-index: $zindex-modal-backdrop;

    @include media-breakpoint-up(lg) {
      width: auto;
      min-height: 80px;
      padding-left: 0;
      padding-right: 0;
      z-index: 2000;
    }

    &:only-child {
      position: relative;
      right: 0;
    }
  }

  &-nav {
    padding: 0;
    width: 100%;
    flex-wrap: nowrap;
    display: block;

    @include media-breakpoint-up(lg) {
      display: flex;
      width: auto;
      flex-wrap: wrap;
    }

    &--main > li:last-child {
      margin-top: 50px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    &--small {
      width: 100%;
    }
  }

  &-nav--teaser {
    .nav-item {
      margin: 0;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }

  .navigation {
    background-color: $c_oxfordblue;
    color: $c_white;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    bottom: auto;
    width: 100%;

    z-index: $zindex-fixed;

    @include media-breakpoint-up(lg) {
      background-color: $c_slate;
      flex-direction: row;
      justify-content: flex-end;
      top: 0;
      // opacity: 1;
      min-height: map-get($navbarheight, md);
      overflow: hidden;

      // transform: none;

      z-index: $zindex-fixed;

      @include make-col-ready($grid-gutter-width);
    }

    &.show {
      opacity: 1;
      transform: translateY(0);
      overflow: visible;
      z-index: 1050;
      padding-top: 15px;
      padding-bottom: 15px;

      @include media-breakpoint-down(md) {
        height: auto;
        top: 50px;

        > .navbar-nav.show,
        .nav-item--service.show {
          height: 100%;
          display: block;
        }
      }

      @include media-breakpoint-up(lg) {
        padding-top: 0;
        padding-bottom: 0;
      }

      > .navbar-nav {
        @include media-breakpoint-up(lg) {
          position: static;

          > .nav-item.show > .flyout {
            display: flex;
            opacity: 1;
            // transform: translateY(0);
            // transition: transform ease-out 550ms, opacity ease-in-out 250ms;
            animation: fadeIn ease-in-out 250ms;
            // animation: slideDown ease-in-out 550ms;
          }
        }
      }
    }

    > .navbar-nav {
      &.show {
        padding-top: 0;
      }

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
        align-items: center;
        height: 80px;
        padding-top: 0;

        > .nav-item > .flyout {
          z-index: 1;
          opacity: 0;
          // transform: translateY(-150%);
        }
      }

      .nav-item {
        .nav-links {
          .teaser {
            @include media-breakpoint-up(lg) {
              display: none;
            }
          }
        }
      }
    }
  }

  .brand {
    color: $c_white;
    padding: 0;
    opacity: 1;
    animation: fadeIn $transitionSpeed;
    margin: 0 auto 0 0;
    width: 150px;

    @include media-breakpoint-up(xl) {
      width: 200px;
    }

    @include media-breakpoint-down(md) {
      &.hidden {
        display: none;
        opacity: 0;
      }
    }
  }

  .navbar-toggler {
    display: flex;
    outline: none;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .nav-teaser {
    display: none;
    margin-bottom: 50px;

    @include media-breakpoint-up(lg) {
      display: flex;
      margin-bottom: 0;
      max-width: 770px;
      flex: 0 0 100%;
    }

    .card {
      display: block;
      background: transparent;
      border: 0;
      border-radius: 0;
      padding-left: 30px;

      .card-img-top {
        display: block;
        width: 100%;
        height: auto;
      }

      &:first-child {
        padding-left: 0;
      }
    }

    .card-text {
      font-size: 15px;
    }

    .card-body {
      width: 370px;
      padding: 15px 0 15px 0;
    }

    .link {
      color: inherit;
      font-size: 15px;
      margin-top: 15px;
    }
  }

  .nav-links {
    display: flex;
    justify-content: space-between;
    margin: 10px 40px;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }

    .nav-link {
      margin: 0;
    }
  }

  .nav-link {
    display: none;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-size: 17px;
    font-weight: $font-weight-medium;
    color: $c_white;
    margin: 10px 40px;
    padding: 0;
    background: transparent;
    border: 0;
    border-radius: 0;
    outline: none;

    &.nav-link--main.active {
      .icon-arrow {
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      display: block;
      margin: 0 30px 0 0;
      width: 100%;
    }

    &::after {
      content: none;
    }

    .icon-arrow {
      width: 16px;
      height: 16px;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &--main {
      white-space: nowrap;
    }

    &--back {
      display: none;
      margin: 0;

      &.show {
        display: block;
        margin-right: auto;
      }

      .icon-arrow {
        transform: rotate(180deg);

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    &.hidden {
      display: none;
    }

    &.active {
      display: flex;
    }

    &--external {
      @include media-breakpoint-up(lg) {
        padding: 0 0 20px 0;
        font-weight: $font-weight-normal;
        font-size: 15px;
        line-height: 24px;
        margin: 0;
      }
    }

    &--search,
    &--language,
    &--login,
    &--contact,
    &--cart {
      .icon {
        fill: #f3f3f3;
        width: 22px;
        height: 22px;
        margin: auto;

        @include media-breakpoint-up(lg) {
          width: 32px;
          height: 32px;
        }

        + .icon {
          display: none;
        }
      }

      span {
        font-size: 12px;
        margin-left: 5px;
      }

      &.active .icon {
        display: none;

        + .icon {
          display: flex;
        }
      }
    }

    &--language {
      @include media-breakpoint-down(md) {
        margin-left: auto;
        align-items: center;
      }
    }

    &--service {
      margin: 0;
    }

    &--login,
    &--contact {
      @include media-breakpoint-down(md) {
        margin: 10px 15px 10px 0;
        margin-left: auto;
      }
    }

    &.show {
      display: flex;
    }
  }

  .form-search {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    margin-left: -15px;
    margin-right: -15px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  .form-inline {
    flex-wrap: nowrap;
    display: grid;
    grid-template-columns: 1fr min-content;
  }

  .btn-search {
    min-width: auto;
    padding: 0 5px;
  }

  .tags {
    &--lang {
      display: flex;
      flex-flow: row wrap;
    }

    .tag {
      color: $c_slate;
      opacity: 0.65;

      &.disabled {
        opacity: 1;
      }
    }
  }

  .hidden-down-sm {
    @include media-breakpoint-down(sm) {
      display: none !important;
    }
  }

  .hidden-down-md {
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }

  .hidden-down-lg {
    @include media-breakpoint-down(lg) {
      display: none !important;
    }
  }

  .hidden-up-lg {
    @include media-breakpoint-up(lg) {
      display: none !important;
    }
  }

  ::placeholder {
    color: $c_white;
    font-size: 17px;
  }

  &-active {
    .navbar-bar {
      justify-content: flex-end;
    }
  }
}
