.text-image {
  &__content-container {
    @include media-breakpoint-only(xs) {
      order: 2;
    }
  }

  &__content {
    padding: 16px 0 24px 0;
    // margin-left: -18px;

    @include media-breakpoint-only(xs) {
      padding: 25px 0 30px 0;
    }

    .hdl {
      font-weight: normal;
      margin-bottom: 20px;
    }

    .intro {
      font-weight: bold;
      line-height: 25px;
      font-size: 14.3px;
    }
  }

  &__image {
    padding: 25px 0 0 30px;

    @include media-breakpoint-only(xs) {
      order: 1;
      padding: 0;
    }
    @include media-breakpoint-only(sm) {
      padding: 30px 0 0 13px;
    }
    @include media-breakpoint-only(md) {
      padding: 20px 0 0 5px;
    }

    img {
      @include media-breakpoint-only(xs) {
        margin: 0 auto;
      }
    }
  }
}
