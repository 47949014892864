.teaser-gallery-overlay {
  &__headline {
    padding: 30px;
    text-align: center;

    .hdl {
      margin: 0 auto;
    }
  }

  &__video {
    width: 100%;

    video {
      width: 100%;
      height: 100%;
      display: flex;
    }

    .play-pause-wrapper {
      display: flex;
      justify-content: flex-end;
      z-index: 1001 !important;

      @include media-breakpoint-down(md) {
        display: none;
      }

      .play-icon, .pause-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        border: 2px solid rgba(0,0,0, 0.2);
        height: 50px;
        width: 50px;
        margin-right: -1.25%; // cancel out the bootstrap padding on the parent element

        &:hover {
          border: 4px solid rgba(0,0,0, 0.2);
          cursor: pointer;
        }

        &:focus-visible {
          border: 4px solid rgba(0,0,0, 0.2);
          outline: none;
        }

        .icon {
          height: 12px;
          width: 9.17px;
        }
        }
    }

    &:has(~.teaser-gallery-overlay__textbox--right) {
      .play-pause-wrapper {
        justify-content: flex-start;
      }

    }
  }

  .container {
    @include media-breakpoint-up(lg) {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 74px;
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }

    &.teaser-gallery-overlay__textbox--right {
      justify-content: flex-end;
    }
  }

  .content-body {
    background: $c_white;
  }

  .pagination-wrapper {
    @include media-breakpoint-down(md) {
      background-color: transparent;
      position: initial;
      transform: none;
      margin: auto;
    }
  }

  .swiper-pagination {

    &-bullet {
      @include media-breakpoint-up(lg) {
        border-color: $c_white !important;
      }

      &-active {
        @include media-breakpoint-up(lg) {
          background-color: $c_white !important;
        }
      }
    }
  }
}
