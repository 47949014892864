$overlapX: 22px;

.search-slot {
  &--container {
    display: flex;
    margin-bottom: 60px;
  }

  &--input,
  &--button {
    outline: none !important;
  }

  &--input {
    width: 80%;
    border: 2px solid var(--primary);
    border-top-left-radius: 55px;
    padding: 11px calc(#{$overlapX} + 8px) 10px 27px;
    border-bottom-left-radius: 55px;
    margin-right: -#{$overlapX};
    font-weight: 500;

    @include media-breakpoint-only(xs) {
      padding: 9px calc(#{$overlapX} + 8px) 9px 23px;
    }

    &::placeholder {
      font-weight: 500;
    }
  }

  &--button {
    width: 205px;
    border: none;
    background-color: var(--primary);
    color: #fff;
    //margin-right: -#{$overlapX};
    border-radius: 25px;

    @include media-breakpoint-only(xs) {
      width: 120px;
      //margin-right: 20px;
    }

    @include media-breakpoint-only(sm) {
      width: 130px;
      //margin-right: 38px;
    }

    @include media-breakpoint-only(md) {
      width: 185px;
      //margin-right: 0;
    }

    .icon {
      color: #fff;

      @include media-breakpoint-only(xs) {
        width: 21px;
        height: 21px;
      }
    }
  }
}
