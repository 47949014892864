.nav-item {
  position: relative;
  display: none;
  flex-direction: column;
  z-index: $zindex-sticky;

  @include media-breakpoint-up(lg) {
    position: static;
    display: flex;
  }

  p {
    font-size: 14px;

    @include media-breakpoint-up(lg) {
      font-size: 15px;
    }
  }

  &.show .flyout {
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .active + .flyout:not(.flyout--small) {
    position: absolute;
    left: 0;

    @include media-breakpoint-down(md) {
      right: 0;
      padding-bottom: 15px;
    }
  }

  .active + .flyout:not(.flyout--small, .flyout--spareparts, .flyout--parts-guide) {
    @include media-breakpoint-down(md) {
      padding-top: 15px;
    }
  }

  &.show {
    display: flex;
    z-index: $zindex-fixed;

    .nav-link--main {
      @include media-breakpoint-up(lg) {
        font-weight: $font-weight-demi;
      }

      &:not(.nav-link--iconRight) .icon:first-of-type:not(:only-child) {
        display: none;
      }

      span {
        display: none;
      }

      &:not(.nav-link--iconRight) .icon:last-child:not(:only-child) {
        display: block;
      }

      @include media-breakpoint-down(sm) {
        &:not(.nav-link--iconRight) .icon:last-child:not(:only-child) {
          display: none;
        }
      }

      @include media-breakpoint-down(sm) {
        &:not(.nav-link--iconRight) .icon:last-child:not(:only-child) {
          display: block;
        }
      }
    }

    .nav-link--language {
      .icon:last-child:not(:only-child) {
        @include media-breakpoint-up(lg) {
          margin: auto;
        }
      }
    }
  }

  &--col {
    @include media-breakpoint-up(lg) {
      border-right: solid 1px $c_slate;
      margin-bottom: 50px;
      padding-left: 30px;
      padding-right: 30px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: 0;
    }

    &:nth-child(4) {
      @include media-breakpoint-between(md, lg) {
        padding-left: 0;
      }

      @include media-breakpoint-up(xl) {
        border-right: 0;
      }
    }

    &:nth-child(5) {
      @include media-breakpoint-up(xl) {
        padding-left: 0;
      }
    }

    &:nth-child(3n) {
      @include media-breakpoint-between(md, lg) {
        border-right: 0;
      }
    }
  }

  &--search {
    position: static;
    flex-direction: row-reverse;
    max-height: 64px;
    align-items: center;
    color: $c_white;
    margin-top: 0;
    height: auto;
    background-color: transparent;

    .nav-link {
      display: flex;
    }

    .form-control {
      background: transparent;
      border: 0;
      color: $c_white;
      width: 100%;
      padding: 14px 12px;
      font-size: 16px;

      @include media-breakpoint-up(sm) {
        min-width: 330px;
        flex: 1 0 auto;
      }
    }

    .nav-item {
      background-color: $c_oxfordblue;

      @include media-breakpoint-down(md) {
        padding: 8px;
      }
    }

    .navbar-nav {
      padding: 6px 20px 6px 13px;
    }

    @include media-breakpoint-down(md) {
      .flyout .d-flex {
        margin-left: -$grid-gutter-width/2;
        margin-right: -$grid-gutter-width/2;
      }
    }

    .flyout .navbar-nav {
      width: 100%;

      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }
  }

  &--language {
    position: static;
    flex-direction: row-reverse;
    max-height: 64px;
    align-items: center;
    color: $c_white;
    margin-top: 0;
    height: auto;
    background-color: transparent;

    .nav-link--language {
      display: flex;
      padding-bottom: 0 !important;

      div {
        display: none;
      }
    }

    .nav-link--language {
      margin-left: 12px;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      color: inherit;

      &.show.active {
        color: inherit;

        .icon:first-of-type:not(:only-of-type) {
          display: flex;
        }
      }

      .icon {
        margin: 0;
      }
      span {
        font-size: 13px;
      }
      span + .icon {
        display: none;
      }
    }

    &.show {
      padding-top: 0;
      background-color: $c_slate;
      color: var(--primary_contrast);
      @include media-breakpoint-down(md) {
        margin-top: -47px;
        padding-left: 20px;
        padding-right: 20px;
      }

      .nav-link--language {
        padding-left: 0;
        padding-right: 0;

        @include media-breakpoint-down(md) {
          margin: 0;
        }

        .icon-close {
          display: block;
        }
      }
    }
  }

  &--cart {
    position: static;

    @include media-breakpoint-up(lg) {
      flex-direction: row-reverse;
      align-items: center;
      color: $c_white;
      margin-top: 0;
      height: 100%;
      background-color: transparent;
    }

    &.show {
      @include media-breakpoint-down(md) {
        margin: -47px 0 90px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .nav-link--cart {
      margin-left: 10px;

      @include media-breakpoint-up(lg) {
        margin-left: 30px;
      }
    }
  }

  &--service {
    flex: 1 0 auto;
    justify-content: center;
    order: 4;

    .icon {
      @include media-breakpoint-down(md) {
        fill: $c_slate;
      }
    }

    .flyout {
      height: 100%;

      @include media-breakpoint-up(lg) {
        height: auto;
      }
    }

    .hamburger {
      outline: none;
      pointer-events: none;

      &-box {
        height: 28px;
        vertical-align: middle;
        margin-top: 8px;
      }
    }

    &.show .nav-link,
    &.show .nav-item {
      display: flex;
    }
  }

  &--login,
  &--contact {
    @include media-breakpoint-up(lg) {
      order: 3;
      display: flex;
      position: relative;
      flex-direction: row-reverse;
      // flex: 1 0 64px;
      max-height: 64px;
      align-items: center;
      color: $c_white;
      margin-top: 0;
      height: auto;
      background-color: transparent;
    }
  }
}
