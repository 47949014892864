// preview before modal box is opened
.video-thumbnail {
  display: block;
  width: 100%;
  height: 100%;

  // background image settings
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &[role='link'] {
    cursor: pointer;
  }

  .video__poster {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // background image settings
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .video__big-play-button {
    position: absolute;
    bottom: calc(50% - 40px);
    left: calc(50% - 40px);
    padding: 0;
    background: transparent;
    border: 0;
  }

  .icon-videoicon {
    width: 80px;
    height: 80px;
  }

  &--small {
    .icon-videoicon {
      width: 40px;
      height: 40px;
    }

    .video__big-play-button {
      bottom: calc(50% - 20px);
      left: calc(50% - 20px);
    }
  }
}
