@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > .aspect-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  > picture > img,
  > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    object-fit: contain;
  }
}

@mixin breakword {
  word-wrap: break-word; /* IE */
  overflow-wrap: break-word; /* CSS3 */
}

@mixin center-element {
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@mixin brandColors($properties, $brand, $color) {
  @each $name, $value in map-deep-get($brand) {
    .#{$name} & {
      @each $property in $properties {
        #{$property}: map-deep-get($value, $color);
      }
    }
  }
}
