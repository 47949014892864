.search-result-text {
  &__box {
    border-bottom: 1px solid $c_iron;

    .link {
      align-items: baseline;
      margin-bottom: 15px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 19px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }

    .icon {
      position: relative;
      top: 2px;
      width: 15px;
      height: 15px;
      margin-right: 3px;
      @include media-breakpoint-up(md) {
        top: 1px;
      }
      @include media-breakpoint-up(lg) {
        top: 0;
      }
    }
  }
}
