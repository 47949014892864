.usage-table-snippet {
  &.section--bglight &__container {
    background-color: $c_concrete;
  }

  &__row {
    margin: 0;
  }

  &__info {
    padding: 10px;

    @include media-breakpoint-up(sm) {
      padding: 15px;
    }

    table {
      width: 100%;
    }

    tbody {
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }

    tr {
      &.odd {
        background-color: $c_iron;
      }

      @include media-breakpoint-down(lg) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-down(sm) {
        display: grid;
        grid-template-columns: 1fr;
      }
    }

    td {
      font-weight: $font-weight-demi;
      padding: 10px 14px;
      vertical-align: top;

      @include media-breakpoint-down(lg) {
        padding-bottom: 0;

        &:last-child {
          padding-bottom: 10px;
        }
      }

      @include media-breakpoint-down(sm) {
        padding-top: 5px;

        &:first-child {
          padding-top: 10px;
        }
      }

      label {
        display: block;
        font-weight: $font-weight-demi;
        opacity: 0.75;
        font-size: 13px;
        margin-bottom: 0;
      }
    }
  }

  &__details {
    border-top: 1px solid $c_iron;
    padding: 10px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      padding: 15px;
      flex-direction: row;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      border-top: 0;
      border-left: 1px solid $c_iron;
    }

    .btn {
      display: flex;
      align-items: center;
      width: fit-content;
      padding-top: 5px;
      padding-bottom: 5px;

      > span {
        margin-right: 15px;
      }
      label {
        display: block;
        text-align: left;
        font-weight: $font-weight-demi;
        opacity: 0.75;
        font-size: 13px;
        margin-bottom: 0;
      }

      .icon {
        margin-right: 15px;
      }
    }
  }
}
