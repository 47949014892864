.downloads {
  &.section--bglight &__container {
    background-color: $c_concrete;
    padding: 30px 25px;

    @include media-breakpoint-up(md) {
      padding: 40px 80px;
    }

    @include media-breakpoint-up(lg) {
      padding: 50px 80px;
    }

    @include media-breakpoint-up(xl) {
      padding: 50px 80px;
    }
  }

  .downloads__link {
    color: $c_slate;

    .icon-wrapper > .icon {
      color: var(--primary_alt);
    }
  }
}
