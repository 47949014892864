.icon {
  width: 25px;
  height: 25px;
  color: $c_slate;
  pointer-events: none;

  &--r180 {
    transform: rotate(180deg);
  }
}
