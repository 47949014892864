:root {
  --linkedImageTilesTileWidth: 270px;
  --linkedImageTilesGap: 30px;
}

@include media-breakpoint-only(xs) {
  :root {
    --linkedImageTilesTileWidth: 100%;
    --linkedImageTilesGap: 16px;
  }
}

@include media-breakpoint-only(sm) {
  :root {
    --linkedImageTilesTileWidth: 50%;
    --linkedImageTilesGap: 18px;
  }
}

@include media-breakpoint-only(md) {
  :root {
    --linkedImageTilesTileWidth: 216px;
    --linkedImageTilesGap: 18px;
  }
}

@include media-breakpoint-only(lg) {
  :root {
    --linkedImageTilesTileWidth: 216px;
    --linkedImageTilesGap: 24px;
  }
}

.linked-image-tiles {
  &__grid {
    display: grid;
    gap: var(--linkedImageTilesGap);
    grid-template-columns: repeat(4, 1fr);

    @include media-breakpoint-only(xs) {
      grid-template-columns: 1fr;
    }

    @include media-breakpoint-only(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__tile {
    position: relative;
    display: flex;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-size: cover;

    &[data-active] {
      .linked-image-tiles__tile-plus {
        opacity: 0;
      }

      .linked-image-tiles__tile-content {
        opacity: 1;
      }
    }

    @include media-breakpoint-down(sm) {
      &:before {
        content: '';
        padding-bottom: 100%;
        display: block;
      }
    }

    a {
      display: flex;
      position: absolute;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-decoration: none;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: 0;
        transition: opacity 100ms ease-in;
        z-index: 10;
      }

      span,
      span:hover {
        color: #fff;
      }

      &:hover {
        .linked-image-tiles__tile-plus {
          opacity: 0;
        }

        .linked-image-tiles__tile-content {
          opacity: 1;
        }

        &:before {
          opacity: 0.25;
        }
      }
    }

    &-plus {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background: #d9dddf;
      bottom: 0;
      left: 0;
      opacity: 1;
      transition: opacity 100ms ease-in;

      .icon {
        color: var(--primary);
      }
    }

    &-content {
      position: relative;
      margin: 0 0 15px 20px;
      opacity: 0;
      transition: opacity 200ms ease-in;
      pointer-events: none;
      z-index: 100;

      @include media-breakpoint-only(sm) {
        margin-right: 140px;
      }

      .hdl,
      span {
        pointer-events: none;
      }

      .hdl {
        margin: 0;
        font-weight: 500;
        color: #fff;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 29px;
      }

      a {
        color: #fff;

        .icon {
          color: #fff;
        }
      }
    }

    &.tile-1-1 {
      height: var(--linkedImageTilesTileWidth);

      @include media-breakpoint-only(xs) {
        grid-column: span 1;
        grid-row: span 1;
        height: auto;
      }

      @include media-breakpoint-only(sm) {
        grid-column: span 1;
        grid-row: span 1;
        height: auto;
      }
    }

    &.tile-2-2 {
      grid-column: span 2;
      grid-row: span 2;
      width: 2fr;
      height: calc(2 * var(--linkedImageTilesTileWidth) + var(--linkedImageTilesGap));

      @include media-breakpoint-only(xs) {
        grid-column: span 1;
        grid-row: span 1;
        height: auto;
      }

      @include media-breakpoint-only(sm) {
        grid-column: span 2;
        grid-row: span 2;
        height: auto;
      }
    }
  }

  &__load-more {
    margin: 60px 0;
    text-align: center;
  }

  &__page:not(&__page:first-child) {
    margin-top: var(--linkedImageTilesGap);
    display: none;
  }
}
