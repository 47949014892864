.services-tiles-scroll {
  &_container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    width: fit-content;
    padding-left: 1.25%;
    padding-right: 1.25%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 1025px) {
      max-width: 1440px;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }

    &_tile {
      width: 100%;
      max-width: 370px;
      position: relative;
      min-height: 370px;
      height: auto;
      background: $c_concrete;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &:hover {
        .services-tiles-scroll_container_tile-cover {
          transform: translateY(0);
          transition: transform 0.3s ease-out;
        }
      }

      &-text {
        min-height: 35%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        margin: 0 20px;
        font-weight: $font-weight-bold;
        font-size: 24px;
        line-height: 34px;
        text-align: center;

        p {
          margin: 0;
        }
      }

      &-image {
        max-width: 100%;
        min-height: 65%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        &_icon {
          svg {
            width: 128px;
            height: 128px;
            color: var(--primary);
          }
        }

        .picture--16-9 {
          width: 100%;
          height: 100%;

          img {
            object-fit: fill;
            width: 100%;
            height: 100%;
          }
        }
      }

      &-cornerIcon {
        position: absolute;
        right: 5px;
        bottom: 5px;
        padding: 5px;

        svg {
          width: 32px;
          height: 32px;
        }
      }

      &-cover {
        width: 100%;
        height: 100%;
        padding: 25px;
        position: absolute;
        background: var(--primary);
        transform: translateY(100%);
        transition: none;

        ul {
          height: 100%;
          padding-bottom: 6%;
          padding-left: 1.25rem;
          max-height: 100%;
          overflow-y: auto;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }

          li {
            font-size: 12px;

            a {
              color: var(--primary_contrast);
            }
          }
        }

        h6, li {
          color: var(--primary_contrast);
        }
      }
    }
  }
}
