.contentfilter {
  @include media-breakpoint-up(sm) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__title {
    font-weight: $font-weight-demi;
    margin-bottom: 9px;
  }

  &__items {
    display: flex;
    flex: 0 0 30%;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    @include media-breakpoint-only(xs) {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
    }

    &-item {
      width: 33%;

      @include media-breakpoint-only(xs) {
        width: 100%;
      }
    }
  }

  &__body {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
  }

  @include media-breakpoint-down(xs) {
    .form-group:first-child {
      margin-top: $form-group-margin-bottom;
      margin-bottom: 0;
    }
    .form-group {
      margin-top: 0;
      margin-bottom: 0;
    }
    .form-group:last-child {
      margin-top: 0;
      margin-bottom: $form-group-margin-bottom;
    }
  }
}
