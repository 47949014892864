body {
  @include media-breakpoint-up(sm) {
    font-size: $font-size-sm;
    line-height: $line-height-base;
  }
}

ol,
ul,
dl {
  margin: 0;
}

th,
strong,
.font-weight-demi {
  font-weight: $font-weight-demi;
}

.page {
  background-color: $c_white;
}

.list-group-item {
  background: transparent;
}

.overflow--hidden {
  overflow: hidden;
}

// Classes to overwrite theme colors
:root {
  --primary: #{$c_slate};
  --primary_alt: #{$c_slate};
  --primary_contrast: #{$c_white}; // if background is primary
  --hover: #{$c_bluebayoux};
  --hover_alt: #{$c_bluebayoux};
  --focus: #{$c_outerspace};
  --focus_alt: #{$c_outerspace};
  --disabled: #{$c_silversand};
  --error: #{$c_yellow};
}

.wirtgen-group {
  --primary: #{$c_slate};
  --primary_alt: #{$c_slate};
  --primary_contrast: #{$c_white};
  --hover: #{$c_bluebayoux};
  --hover_alt: #{$c_bluebayoux};
  --focus: #{$c_outerspace};
  --focus_alt: #{$c_outerspace};
  --disabled: #{$c_silversand};
  --error: #{$c_yellow};
}

.wirtgen {
  --primary: #{$c_internationalorange};
  --primary_alt: #{$c_internationalorange};
  --primary_contrast: #{$c_white};
  --hover: #{$c_crusta};
  --hover_alt: #{$c_crusta};
  --focus: #{$c_trinidad};
  --focus_alt: #{$c_trinidad};
  --disabled: #{$c_waxflower};
  --error: #{$c_yellow};
}

.hamm {
  --primary: #{$c_christine};
  --primary_alt: #{$c_christine};
  --primary_contrast: #{$c_white};
  --hover: #{$c_blazeorange};
  --hover_alt: #{$c_blazeorange};
  --focus: #{$c_pomegranate};
  --focus_alt: #{$c_pomegranate};
  --disabled: #{$c_corvette};
  --error: #{$c_yellow};
}

.benninghoven {
  --primary: #{$c_endeavour};
  --primary_alt: #{$c_endeavour};
  --primary_contrast: #{$c_white};
  --hover: #{$c_curiousblue};
  --hover_alt: #{$c_curiousblue};
  --focus: #{$c_toryblue};
  --focus_alt: #{$c_toryblue};
  --disabled: #{$c_regentstblue};
  --error: #{$c_yellow};
}

.voegele {
  --primary: #{$c_deepsea};
  --primary_alt: #{$c_deepsea};
  --primary_contrast: #{$c_white};
  --hover: #{$c_jade};
  --hover_alt: #{$c_jade};
  --focus: #{$c_watercourse};
  --focus_alt: #{$c_watercourse};
  --disabled: #{$c_sinbad};
  --error: #{$c_yellow};
}

.kleemann {
  --primary: #{$c_funblue};
  --primary_alt: #{$c_funblue};
  --primary_contrast: #{$c_white};
  --hover: #{$c_mariner};
  --hover_alt: #{$c_mariner};
  --focus: #{$c_chathamsblue};
  --focus_alt: #{$c_chathamsblue};
  --disabled: #{$c_pigeonpost};
  --error: #{$c_yellow};
}

.ciber {
  --primary: #{$c_internationalorange};
  --primary_alt: #{$c_internationalorange};
  --primary_contrast: #{$c_white};
  --hover: #{$c_crusta};
  --hover_alt: #{$c_crusta};
  --focus: #{$c_trinidad};
  --focus_alt: #{$c_trinidad};
  --disabled: #{$c_waxflower};
  --error: #{$c_yellow};
}

.john-deere {
  --primary: #{$c_schoolbusyellow};
  --primary_alt: #{$c_outerspace};
  --primary_contrast: #{$c_outerspace};
  --hover: #{$c_brightsun};
  --hover_alt: #{$c_outerspace};
  --focus: #{$c_corn};
  --focus_alt: #{$c_outerspace};
  --disabled: #{$c_visvis};
  --error: #{$c_yellow};
}
