.benefits {
  %defaultFlex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-wrapper {
    @extend %defaultFlex;
    flex-direction: column;
    gap: 40px;

    &__heading {
      .heading-headline {
        color: $c_slate;
      }

      .heading-text {
        color: $c_slate;
        font-size: 17px;
        font-weight: 400;
        line-height: 29px;
      }
    }

    &__icons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      row-gap: 30px;

      @include media-breakpoint-down(xs) {
        justify-content: center;
      }

      &-icon {
        @extend %defaultFlex;
        flex-direction: column;
        align-self: flex-start;
        gap: 18px;
        width: 125px;

        img {
          width: 80px;
          height: 80px;
          color: var(--primary);
        }

        .icon-text {
          font-size: 15px;
          line-height: 19px;
          font-weight: 600;
          text-align: center;
        }
      }
    }
  }
}
