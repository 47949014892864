.search-result {
  /* MKE - ODSUPPORT-4931 - START
  .headline-text__container {
    margin-bottom: 36px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
    }
  }
   MKE - ODSUPPORT-4931 - END */

  &--noresult {
    .headline-text__container {
      margin-bottom: 0;
    }

    .intro {
      padding-top: 10px;
    }
  }

  /* MKE - ODSUPPORT-4931 - START */
  .tab-navigation {
    margin-bottom: 40px;
  }

  .pager {
    margin-top: 40px;
  }

  .headline-text__intro {
    &--left {
      p.intro {
        margin: 0 2.5% 0;
      }
    }
  }
  /* MKE - ODSUPPORT-4931 - END */
}
