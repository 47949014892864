/* stylelint-disable selector-no-qualifying-type */
img.lazyload:not([src]) {
  visibility: hidden;
}
/* stylelint-enable */

// ### REQUIRED FOR LAZYSIZES sizes=auto *IF* no aspect-ratio container used.
// https://github.com/aFarkas/lazysizes#automatically-setting-the-sizes-attribute
img[data-sizes='auto'] {
  display: block;
  width: 100%;
}

.picture--16-9 {
  @include aspect-ratio(16, 9);
}

.picture--21-9 {
  @include aspect-ratio(21, 9);
}

.picture--1-1 {
  @include aspect-ratio(1, 1);
}

.picture__title {
  position: absolute;
  top: 20px;
  left: 40px;
  color: $c_white;

  .hdl {
    color: inherit;
    font-weight: normal;
  }
}

.picture__shaded {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba($c_black, 0.4) 0,
    rgba($c_black, 0) 33%,
    rgba($c_black, 0) 78%,
    rgba($c_black, 0.4) 100%
  );
}
