.scrolltable {
  position: relative;
  box-sizing: border-box;

  &__wrap {
    /* Make this scrollable when needed */
    overflow-x: auto;
    /* We don't want vertical scrolling */
    overflow-y: hidden;
    /* For WebKit implementations, provide inertia scrolling */
    -webkit-overflow-scrolling: touch;

    /* If JS present, let's hide the default scrollbar */
    .js & {
      scrollbar-width: none; /* Firefox 64+ */

      /* Remove the default scrollbar for WebKit implementations */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .table {
    float: left;
    transition: transform 0.2s ease-in-out;
    position: relative;

    &--no-transition {
      transition: none;
    }
  }

  &__nav {
    position: absolute;
    /* Reset the button */
    appearance: none;
    background: transparent;
    padding: 0;
    border: 0;
    /* Now style it as needed */
    top: 0;
    bottom: 0;
    width: 50px;
    /* Set the buttons invisible by default */
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;

    &:focus {
      outline: 0;
    }

    &:hover {
      cursor: pointer;
    }

    .icon {
      width: 30px;
      height: 30px;
    }

    &--left {
      left: 0;
      background-image: linear-gradient(to right, rgba($c_white, 1), rgba($c_white, 0.8) 55%, rgba($c_white, 0) 100%);

      [data-overflowing='both'] &,
      [data-overflowing='left'] & {
        opacity: 1;
        pointer-events: auto;
      }
    }

    &--right {
      right: 0;
      background-image: linear-gradient(to left, rgba($c_white, 1), rgba($c_white, 0.8) 55%, rgba($c_white, 0));

      [data-overflowing='both'] &,
      [data-overflowing='right'] & {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
