.filter-result {
  & + & {
    margin-top: 30px;
  }

  /* WGWEB-1464
  &:not(.category-hidden):last-child {
    margin-bottom: 30px;
    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 60px;
    }
  }*/

  &__more-btn {
    display: flex;
    margin-top: 32px;
    justify-content: center;
  }
}

/* WGWEB-1464 */
.pam .pam-filters__categories + div.container .intro {
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 50px;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 60px;
  }
}
