.tabs {
  display: flex;
  text-align: center;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  &__content-container {
    width: 100%;
    min-height: 60px;
    height: auto;
    background-color: $c_concrete;
    // content after tabs in mobile
    order: 2;

    @include media-breakpoint-up(md) {
      min-height: 180px;
      // content before tabs in desktop
      order: 1;
    }
  }

  &__container {
    width: 100%;
    height: 60px;
    margin: auto;
    order: 1;
    z-index: 100;

    @include media-breakpoint-up(md) {
      height: 170px;
      order: 2;
    }

    // if there is no tabs
    .intro {
      padding-top: 20px;
    }

    .tabs__label-container {
      overflow-x: auto;
      overflow-y: hidden;
      // hide scrollbar for moz
      overflow: -moz-scrollbars-none;
      scrollbar-width: none;
      // hide scrollbar for ie
      -ms-overflow-style: none;

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        overflow-x: visible;
        overflow-y: visible;
      }

      // hide scrollbar for webkit
      &::-webkit-scrollbar {
        width: 0 !important;
      }

      .nav.nav-tabs {
        //override bootstrap properties for mobile
        display: inline-flex;
        flex-wrap: nowrap;
        border: 0;

        @include media-breakpoint-up(md) {
          border: 0;
          flex-grow: 1;
          display: flex;
          flex-wrap: nowrap;
        }

        // remove margin between content and tabs
        .nav-item {
          margin-bottom: 0;
        }
      }

      .tabs__label {
        display: flex;
        align-items: center;
        width: 200px;
        height: 60px;
        padding-right: 10px;
        border: 0;
        border-top: 2px solid $c_concrete;
        border-radius: 0;
        color: inherit;
        background-color: $c_white;
        text-decoration: none;
        overflow: hidden;
        cursor: pointer;

        @include media-breakpoint-up(md) {
          display: block;
          flex-grow: 1;
          flex-shrink: 1;
          min-width: auto;
          height: calc(100% + 50px);
          // to make tab overlap content
          margin-top: -50px;
          padding: 40px 0;
          border: 0;
        }

        .icon-wrapper {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          height: 60px;
          padding: 10px;

          @include media-breakpoint-up(md) {
            width: auto;
            height: auto;
          }
        }

        .icon {
          width: 30px;
          height: 30px;
          padding: 0;
          color: var(--primary);
          fill: $c_slate;

          @include media-breakpoint-up(md) {
            position: inherit;
            top: 0;
            left: 0;
            width: 40px;
            height: 40px;
            margin: auto;
            margin-bottom: 25px;
          }
        }

        .text-wrapper {
          height: auto;
          max-height: 60px;
          padding: 10px 0;
          line-height: 22px;
          white-space: normal;
          text-align: left;
          overflow: hidden;

          @include media-breakpoint-up(md) {
            display: block;
            height: auto;
            padding: 10px;
            white-space: normal;
            text-align: center;
          }
        }

        &.swiper-slide-thumb-active {
          color: var(--primary_contrast);
          background-color: var(--primary);

          @include media-breakpoint-up(md) {
            flex-grow: 1;
            // to make tab overlap content
            height: calc(100% + 80px);
            margin-top: -80px;
            padding: 70px 0;
          }

          .icon {
            width: 30px;
            height: 30px;
            padding: 0;
            color: var(--primary_contrast);
            fill: var(--primary_contrast);

            @include media-breakpoint-up(md) {
              position: inherit;
              width: 50px;
              height: 50px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

.page.john-deere {
  .tabs__container .tabs__label-container .tabs__label .icon {
    fill: $c_black;
  }
}
