.background-image-text {
  &__container {
    position: relative;
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 602px;

    @include media-breakpoint-down(sm) {
      min-height: auto;
    }

    @include media-breakpoint-only(xs) {
      height: 180px;
    }

    @include media-breakpoint-only(sm) {
      height: 432px;
    }

    @include media-breakpoint-only(md) {
      min-height: 552px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__content {
    margin-top: 119px;

    @include media-breakpoint-down(sm) {
      background: #fff;
    }

    @include media-breakpoint-only(xs) {
      margin: 21px 0;
      padding: 0 10px 33px;
    }

    @include media-breakpoint-only(sm) {
      padding: 0 23px 45px;
      margin-top: 29px
    }

    @include media-breakpoint-only(md) {
      margin-top: 100px;
    }

    .hdl,
    .headline-text,
    p,
    ul,
    .link {
      color: #fff;

      @include media-breakpoint-down(sm) {
        color: $c_slate;
      }
    }

    .hdl,
    p {
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

      @include media-breakpoint-down(sm) {
        text-shadow: none;
      }
    }

    .hdl {
      max-width: 320px;

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }

    p {
      max-width: 560px;

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }

      @include media-breakpoint-only(sm) {
        margin-bottom: 10px;
      }
    }

    .link {
      margin-bottom: 41px;

      @include media-breakpoint-only(sm) {
        margin-bottom: 47px;
      }

      @include media-breakpoint-only(md) {
        margin-bottom: 45px;
      }
    }

    .btn {
      @include media-breakpoint-down(sm) {
        width: 200px;
        margin: -3px auto;
        display: block;
      }

      @include media-breakpoint-up(md) {
        background-color: rgba(255, 255, 255, 0.8);
        color: var(--primary);
        border: none;
      }
    }
  }

  &.theme--light {
    .background-image-text__overlay {
      background-color: rgba(255, 255, 255, 0.5);
    }

    .background-image-text__content {
      .hdl,
      .headline-text,
      p,
      ul,
      .link {
        color: $c_slate;
      }

      .hdl,
      p,
      ul {
        text-shadow: 0 0 20px rgba(65, 83, 93, 0.5);
      }

      .btn {
        background-color: rgba(65, 83, 93, 0.8);
        color: $c_white;
      }
    }
  }

  &.theme--dark {
    .background-image-text__overlay {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .headline-text {
      color: $c_white;
    }
  }
}
